/* eslint-disable @typescript-eslint/no-unused-vars */
import { config } from '../api';
import axios from 'axios';
import LogRocket from 'logrocket';

class ImageLoader {
  list = {};

  load(images, success, fail, fromLocalDomain = false) {
    const frontAxios = axios.create();

    Promise.all(
      images.map(([key, file]) => {
        return new Promise((resolveInside, rejectInside) => {
          const localConfig = {
            ...config,
            url: file,
          };

          frontAxios
            .request(localConfig)
            .then((res) => {
              const blobUrl = URL.createObjectURL(res.request.response);

              const image = new Image();

              image.onload = () => {
                this.list[key] = image;

                URL.revokeObjectURL(blobUrl);

                resolveInside();
              };

              image.onerror = (e) => {
                LogRocket.captureException(e, {
                  extra: {
                    key,
                    file,
                  },
                });

                rejectInside();
              };

              image.src = blobUrl;
            })
            .catch(() => {
              rejectInside();
            });
        });
      })
    )
      .then(() => {
        success();
      })
      .catch(() => {
        fail();
      });
  }

  getAll() {
    return this.list;
  }

  get(url) {
    return this.list[url];
  }

  release() {
    for (const k in this.list) {
      this.list[k] = null;
    }
  }
}

export default ImageLoader;
