/* eslint-disable react-hooks/exhaustive-deps */
import preventDefault from '../../helpers/preventDefault';
import { AUTO_HIDING_NAME } from '../../settings';
import { loadingCompleted } from '../../store/loading/actions';
import Box from './Box';
import styles from './LoadingBox.module.scss';
import list from './texts';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const CLOSE_DELAY_MS = 300;

const keys = [
  'david',
  'rubens',
  'lievens',
  'kauffmann',
  'batoni',
  'potocki',
  'lastJudgment',
  'ladyErmine',
  'gameChess',
  'pourville',
];

const Loading = () => {
  const [auto, setAuto] = useState(localStorage.getItem(AUTO_HIDING_NAME) === '1');
  const { i18n } = useTranslation();
  const { visible, startTime, howManyStages, currentStage } = useSelector((s) => s.loading);
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState(0);
  const [readyToClose, setReadyToClose] = useState(false);
  const { t } = useTranslation();

  const lang = i18n.language || 'en';

  const item = useMemo(() => {
    return Math.floor(Math.random() * keys.length);
  }, [startTime]);

  const changeAuto = () => {
    const newValue = !auto;

    localStorage.setItem(AUTO_HIDING_NAME, newValue ? '1' : '0');

    setAuto(newValue);
  };

  useEffect(() => {
    const goTo = currentStage + 1;
    let value;

    if (goTo < howManyStages) {
      value = Math.floor((goTo / (howManyStages || 1)) * 99);
    } else {
      value = 99;
    }

    setPercentage(value);
  }, [startTime, howManyStages, currentStage]);

  useEffect(() => {
    if (currentStage === howManyStages) {
      if (auto) {
        setTimeout(() => {
          dispatch(loadingCompleted());
        }, CLOSE_DELAY_MS);
      } else {
        setTimeout(() => {
          setReadyToClose(true);
        }, CLOSE_DELAY_MS);
      }
    }
  }, [howManyStages, currentStage]);

  const data = list[lang][item];

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.loading}>
      <div className={styles.content}>
        <Box data={data} name={keys[item]} />

        <div className={styles.progress}>
          <div
            className={cn(styles.indicator, currentStage >= howManyStages && styles.completed)}
            style={{ width: `${currentStage >= howManyStages ? 100 : percentage}%` }}
          />
        </div>
      </div>

      <div className={styles.continue}>
        <div>
          <button
            onTouchStart={(e) => {
              preventDefault(e);

              if (readyToClose) {
                dispatch(loadingCompleted());
              }
            }}
            onClick={() => {
              if (readyToClose) {
                dispatch(loadingCompleted());
              }
            }}
            disabled={!readyToClose}
          >
            {t('Continue')}
          </button>
        </div>
        <div
          className={styles.auto}
          onTouchStart={(e) => {
            preventDefault(e);

            changeAuto();
          }}
          onClick={() => {
            changeAuto();
          }}
        >
          <input type="checkbox" checked={auto} />
          <span>{t('Close automatically when loaded')}</span>
        </div>
      </div>
    </div>
  );
};

const LoadingWrapper = () => {
  const { startTime } = useSelector((s) => s.loading);

  return <Loading key={startTime} />;
};

export default LoadingWrapper;
