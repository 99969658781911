export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';
export const errorNotFound = (details) => ({ type: ERROR_NOT_FOUND, payload: details });

export const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED';
export const errorUnauthorized = (details) => ({ type: ERROR_UNAUTHORIZED, payload: details });

export const ERROR_UNSUPPORTED_DEVICE = 'ERROR_UNSUPPORTED_DEVICE';
export const errorUnsupportedDevice = () => ({ type: ERROR_UNSUPPORTED_DEVICE });

export const ERROR_CANNOT_LOAD_DATA = 'ERROR_CANNOT_LOAD_DATA';
export const errorCannotLoadData = () => ({ type: ERROR_CANNOT_LOAD_DATA });
