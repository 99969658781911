const order = [
  'C41',
  'E119',
  'B172',
  'BW',
  'D9',
  'B229',
  'C298',
  'A352',
  'A355',
  'B124',
  'B127',
  'A69',
  'MA',
  'FA',
  'A36',
  'PG',
];

const cutColors = (colors) => {
  const newColors = [];

  order.forEach((id) => {
    const find = colors.find((color) => color.id === id);

    if (find) {
      newColors.push(find);
    }
  });

  return newColors;
};

export default cutColors;
