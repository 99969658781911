import preventDefault from '../../helpers/preventDefault';
import { BUILD, VERSION } from '../../settings';
import styles from './About.module.scss';
import logo from './assets/logo.svg';
import { useTranslation } from 'react-i18next';

const About = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.container}
      onTouchStart={(e) => {
        preventDefault(e);

        handleClose();
      }}
      onClick={() => {
        handleClose();
      }}
    >
      <div className={styles.center}>
        <div className={styles.logo}>
          <img src={logo} alt="Glaze" />
        </div>
        <div className={styles.about}>
          <div>
            {t('Version')} {VERSION} beta
          </div>
          <div>
            {t('Build')} {BUILD}
          </div>
        </div>

        <div className={styles.copy}>
          <div>
            <a
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              href="https://wellofart.com"
            >
              wellofart.com
            </a>
          </div>
          <div>© 2022 Painters Sp. z o.o. {t('All rights reserved.')}</div>
          <div>
            {t('Created in Poland')} <span className={styles.heart}>❤</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
