/* eslint-disable @typescript-eslint/default-param-last */
import { PAINTING_SET_MEDIUM } from './actions';

const initialState = {
  medium: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAINTING_SET_MEDIUM:
      return {
        ...state,
        medium: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
