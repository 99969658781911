import { errorUnauthorized } from '../../store/error/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const ThrowError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      errorUnauthorized({
        backTo: 'main',
      })
    );
  }, [dispatch]);

  return null;
};

export default ThrowError;
