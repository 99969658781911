/* eslint-disable @typescript-eslint/default-param-last */
import { LOADING_NEXT_STAGE, LOADING_COMPLETED, LOADING_INITIALIZE } from './actions';

const initialState = {
  howManyStages: 1,
  startTime: Date.now(),
  currentStage: 0,
  visible: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_INITIALIZE:
      return {
        howManyStages: action.payload,
        startTime: Date.now(),
        currentStage: 0,
        visible: true,
      };

    case LOADING_NEXT_STAGE:
      return {
        ...state,
        currentStage: state.currentStage + 1,
      };

    case LOADING_COMPLETED:
      return {
        ...state,
        visible: false,
      };

    default:
      return state;
  }
};

export default reducer;
