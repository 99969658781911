import styles from './UnsupportedDevice.module.scss';
import { useTranslation } from 'react-i18next';

const UnsupportedDevice = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        {t('Your device is not supported.')}
        <br />
        {t('Keep an eye on our site for updates.')}
      </div>
    </div>
  );
};

export default UnsupportedDevice;
