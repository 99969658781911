const descriptionTool = {
  activeColors: ['Aktywne Kolory', 'Wybieraj kolory; przytrzymaj, aby przesunąć Narzędziownik'],
  dilutionIndicator: ['Wskaźnik Rozcieńczenia', 'Kliknij i przeciągnij, aby zmienić poziom'],
  brushColor: ['Kolor Pędzla', 'Kliknij i przeciągnij, aby zmienić poziom'],
  helper: ['Pomocnik'],
};

const descriptionMenu = {
  chalks: ['Kreda', '2 rozmiary kredy do szkicowania'],
  hogs: ['Hogi', '3 rozmiary pędzli Hog'],
  sobols: ['Sobole', '3 rozmiary pędzli Sobol'],
  rugs: ['Ściereczka', '2 rozmiary ściereczek do wycierania mokrej farby i kredy'],
  colorsPalette: ['Paleta Kolorów', 'Wybieraj 3 aktywne kolory do Narzędziownika'],
  dryingTool: ['Wysuszanie', 'Wysusz ostatnią mokrą warstwę'],
  menu: ['Menu'],
};

const pl = {
  descriptionTool,
  descriptionMenu,
};

export default pl;
