/* eslint-disable @typescript-eslint/default-param-last */
import { PRESETS_SET } from './actions';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRESETS_SET:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
