import assertRectangle from './helpers/assertRectangle';
import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

class Brdf {
  constructor(gl, shaders, textureManager) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.brdfProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.brdfFrag,
      ['a_position'],
      [
        'uColorTexture',
        'uStructureTexture',
        'u_F0',
        'u_roughness',
        'u_diffuseScale',
        'u_specularScale',
        'u_lightDirection',
        'u_eyeDirection',
        'u_normalScale',
        'u_paintingResolution',
      ]
    );
  }

  calculateProperties() {
    this.properties = {};

    this.properties.roughness = this.settings.roughness;
    this.properties.F0 = this.settings.F0;
    this.properties.diffuseScale = this.settings.diffuseScale;
    this.properties.specularScale = this.settings.specularScale;

    this.properties.lightDirection = [
      this.settings.lightDirectionX,
      this.settings.lightDirectionY,
      this.settings.lightDirectionZ,
    ];

    this.properties.eyeDirection = [
      this.settings.eyeDirectionX,
      this.settings.eyeDirectionY,
      this.settings.eyeDirectionZ,
    ];

    this.properties.normalScale = this.settings.minimalNormalScale;

    console.log('BRDF properties calculated');
  }

  render(rectangle) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.enable(this.gl.BLEND);
    this.gl.blendEquation(this.gl.FUNC_ADD);
    this.gl.blendFunc(this.gl.ONE, this.gl.ZERO);

    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    assertRectangle(rectangle);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

    this.gl.useProgram(this.brdfProgram);

    this.gl.uniform2f(this.brdfProgram.u_paintingResolution, this.textureManager.width, this.textureManager.height);
    this.gl.uniform1f(this.brdfProgram.u_roughness, this.properties.roughness);
    this.gl.uniform1f(this.brdfProgram.u_F0, this.properties.F0);
    this.gl.uniform1f(this.brdfProgram.u_diffuseScale, this.properties.diffuseScale);
    this.gl.uniform1f(this.brdfProgram.u_specularScale, this.properties.specularScale);
    this.gl.uniform3fv(this.brdfProgram.u_lightDirection, this.properties.lightDirection);
    this.gl.uniform3fv(this.brdfProgram.u_eyeDirection, this.properties.eyeDirection);
    this.gl.uniform1f(this.brdfProgram.u_normalScale, this.properties.normalScale);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.colorTexture);
    this.gl.uniform1i(this.brdfProgram.uColorTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.surfaceTempTexture);
    this.gl.uniform1i(this.brdfProgram.uStructureTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.brdfProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.brdfProgram.a_position);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brdfTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  update(settings) {
    this.settings = settings;

    this.calculateProperties();
  }
}

export default Brdf;
