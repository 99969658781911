const SHOW_LOGS = true;

// eslint-disable-next-line
const __version__ = 'webgl';

class Context {
  constructor(canvas, width, height) {
    this.width = width;
    this.height = height;

    this.gl = canvas.getContext(__version__, {
      powerPreference: 'high-performance',
      alpha: true,
      antialias: true,
    });
    this.loadExtensions();

    this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, true);
    this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
  }

  loadExtensions() {
    if (SHOW_LOGS) {
      console.group('Loading extensions');
    }

    this.log('OES_texture_float', this.gl.getExtension('OES_texture_float'));
    this.log('OES_texture_half_float', this.gl.getExtension('OES_texture_half_float'));
    this.log('OES_texture_float_linear', this.gl.getExtension('OES_texture_float_linear'));
    this.log('OES_texture_half_float_linear', this.gl.getExtension('OES_texture_half_float_linear'));
    this.log('EXT_color_buffer_float', this.gl.getExtension('EXT_color_buffer_float'));
    this.log('WEBGL_color_buffer_float', this.gl.getExtension('WEBGL_color_buffer_float'));
    this.log('EXT_color_buffer_half_float', this.gl.getExtension('EXT_color_buffer_half_float'));
    this.log('getSupportedExtensions', this.gl.getSupportedExtensions());
    this.log('MAX_TEXTURE_IMAGE_UNITS', this.gl.MAX_TEXTURE_IMAGE_UNITS);

    if (SHOW_LOGS) {
      console.groupEnd();
    }
  }

  log(name, extension) {
    if (SHOW_LOGS) {
      console.log(name, extension);
    }
  }

  get() {
    return this.gl;
  }
}

export default Context;
