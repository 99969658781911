import { savePreview } from '../api';
import createFormDataFromCanvas from './helpers/createFormDataFromCanvas';
import flipYAxis from './helpers/flipYAxis';

const TARGET_SIZE = 400;

const PADDING = 20;
const RADIUS = 50;

class PreviewImage {
  constructor(textureManager) {
    this.textureManager = textureManager;

    this.createCanvas();

    this.saveContext = this.canvas.getContext('2d');
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');

    this.canvas.width = this.textureManager.width;
    this.canvas.height = this.textureManager.height;
  }

  save(id) {
    this.id = id;
    const sourceTextureName = 'brdfTexture';

    return new Promise((resolve, reject) => {
      const savePixels = this.textureManager.readPixels(sourceTextureName);
      const imageData = flipYAxis(this.saveContext, savePixels, this.textureManager.width, this.textureManager.height);
      this.saveContext.putImageData(imageData, 0, 0);

      const [resultCanvas, ctx] = this.steppedScale(TARGET_SIZE, 0.5);

      this.addGradient(ctx);
      this.drawCircle(ctx);

      this.addLogo(
        ctx,
        () => {
          this.saveCanvas(resultCanvas, resolve, reject);
        },
        () => {
          reject();
        }
      );
    });
  }

  saveCanvas(resultCanvas, resolve, reject) {
    const targetImageName = 'glaze';

    createFormDataFromCanvas(resultCanvas)
      .then((formData) => savePreview(this.id, targetImageName, formData))
      .then((response) => {
        if (response.status === 200) {
          resolve();
        } else {
          reject();
        }
      });
  }

  steppedScale(width, step) {
    const canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d'),
      oc = document.createElement('canvas'),
      octx = oc.getContext('2d');

    canvas.width = width; // destination canvas size
    canvas.height = Math.floor((canvas.width * this.textureManager.height) / this.textureManager.width);

    this.newWidth = canvas.width;
    this.newHeight = canvas.height;

    if (this.textureManager.width * step > width) {
      // For performance avoid unnecessary drawing
      const mul = 1 / step;

      let cur = {
        width: Math.floor(this.textureManager.width * step),
        height: Math.floor(this.textureManager.height * step),
      };

      oc.width = cur.width;
      oc.height = cur.height;

      octx.drawImage(this.canvas, 0, 0, cur.width, cur.height);

      while (cur.width * step > width) {
        cur = {
          width: Math.floor(cur.width * step),
          height: Math.floor(cur.height * step),
        };
        octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
      }

      ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(this.canvas, 0, 0, canvas.width, canvas.height);
    }

    return [canvas, ctx];
  }

  addGradient(ctx) {
    const height = 100;
    const gradient = ctx.createLinearGradient(0, this.newHeight - height, 0, this.newHeight);

    gradient.addColorStop(0, 'rgba(0,0,0,0)');
    gradient.addColorStop(1, 'rgba(0,0,0,0.6)');

    ctx.fillStyle = gradient;
    ctx.fillRect(0, this.newHeight - height, this.newWidth, height);
  }

  drawCircle(ctx) {
    ctx.fillStyle = '#262626';

    const size = RADIUS;
    const padding = PADDING;

    ctx.beginPath();
    ctx.arc(size + padding, this.newHeight - size - padding, size, 0, 2 * Math.PI);
    ctx.fill();
  }

  addLogo(ctx, success, fail) {
    const logoWidth = 70;

    const img = new Image();
    //img.crossOrigin = "Anonymous";
    img.onload = () => {
      const w = logoWidth;
      const h = Math.floor((logoWidth * img.height) / img.width);

      const dx = PADDING + RADIUS - w / 2;
      const dy = this.newHeight - (PADDING + RADIUS + h / 2);

      console.log(dx, dy, w, h);

      ctx.drawImage(img, 0, 0, img.width, img.height, dx, dy, w, h);

      success();
    };

    img.onerror = () => {
      fail();
    };

    img.src = '/images/cover-logo.png';
  }

  clean() {
    this.canvas = null;
  }
}

export default PreviewImage;
