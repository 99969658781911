const flipYAxis = (saveContext, savePixels, width, height) => {
  const imageData = saveContext.createImageData(width, height);

  for (let i = 0; i < height; ++i) {
    for (let j = 0; j < width; ++j) {
      const currentStartPosition = (i * width + j) * 4;
      const targetStartPosition = ((height - 1 - i) * width + j) * 4;

      imageData.data[targetStartPosition + 0] = savePixels[currentStartPosition + 0];
      imageData.data[targetStartPosition + 1] = savePixels[currentStartPosition + 1];
      imageData.data[targetStartPosition + 2] = savePixels[currentStartPosition + 2];
      imageData.data[targetStartPosition + 3] = savePixels[currentStartPosition + 3];
    }
  }

  return imageData;
};

export default flipYAxis;
