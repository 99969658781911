import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';
import swap from './helpers/swap';

const HOW_MANY_REFLECTANCE_TEXTURES = 13;

class TextureManager {
  /* List all used textures */

  // BRUSH
  brushPositionsTexture = undefined;

  brushPreviousPositionsTexture = undefined;

  brushPreviousVelocitiesTexture = undefined;

  brushProjectedPositionsTexture = undefined;

  brushProjectedPositionsTempTexture = undefined;

  brushVelocitiesTexture = undefined;

  brushRandomsTexture = undefined;

  // REFLECTANCES
  reflectance0Texture = undefined;

  // reflectance0TempTexture = undefined;

  reflectance1Texture = undefined;

  // reflectance1TempTexture = undefined;

  reflectance2Texture = undefined;

  // reflectance2TempTexture = undefined;

  reflectance3Texture = undefined;

  // reflectance3TempTexture = undefined;

  reflectance4Texture = undefined;

  // reflectance4TempTexture = undefined;

  reflectance5Texture = undefined;

  // reflectance5TempTexture = undefined;

  reflectance6Texture = undefined;

  // reflectance6TempTexture = undefined;

  reflectance7Texture = undefined;

  // reflectance7TempTexture = undefined;

  reflectance8Texture = undefined;

  // reflectance8TempTexture = undefined;

  reflectance9Texture = undefined;

  // reflectance9TempTexture = undefined;

  reflectance10Texture = undefined;

  // reflectance10TempTexture = undefined;

  reflectance11Texture = undefined;

  // reflectance11TempTexture = undefined;

  reflectance12Texture = undefined;

  // reflectance12TempTexture = undefined;

  reflectanceTempTexture = undefined;

  // SPLAT BRUSH
  splatBrushDivergenceTexture = undefined;

  splatBrushPaintTexture = undefined;

  splatBrushPaintTempTexture = undefined;

  splatBrushPressureTexture = undefined;

  splatBrushPressureTempTexture = undefined;

  splatBrushVelocityTexture = undefined;

  splatBrushVelocityTempTexture = undefined;

  // SPLAT PENCIL
  splatPencilDivergenceTexture = undefined;

  splatPencilPaintTexture = undefined;

  splatPencilPaintTempTexture = undefined;

  splatPencilPressureTexture = undefined;

  splatPencilPressureTempTexture = undefined;

  splatPencilVelocityTexture = undefined;

  splatPencilVelocityTempTexture = undefined;

  splatPencilHelperTexture = undefined;

  ///////////// TODO: check it

  // splatDryTexture = undefined;

  // splatDryTempTexture = undefined;

  // SAMPLE
  samplePencilTexture = undefined;

  // SURFACE
  surfaceTexture = undefined;

  surfaceTempTexture = undefined;

  // OTHER
  colorTexture = undefined;

  brdfTexture = undefined;

  /* End of list */

  testingState = {};

  constructor(gl, shaders, imageLoader, width, height) {
    this.gl = gl;
    this.imageLoader = imageLoader;
    this.shaders = shaders;
    this.width = width;
    this.height = height;

    this.createBuffers();
    this.createPrograms();

    this.createTextures();

    this.copyImagesToTextures();
  }

  getFloatLikeType() {
    // if (IS_BROWSER) {
    //   return this.gl.FLOAT;
    // } else {
    const halfFloatExt = this.gl.getExtension('OES_texture_half_float');

    if (halfFloatExt) {
      return halfFloatExt.HALF_FLOAT_OES;
    }
    // }

    return this.gl.FLOAT;
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.resizeProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.outputFrag,
      ['a_position'],
      ['u_input']
    );

    this.restoreAlphaProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.manageAlphaFrag,
      ['a_position'],
      ['u_input']
    );

    this.removeAlphaProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      '#define SAVE \n' + this.shaders.manageAlphaFrag,
      ['a_position'],
      ['u_input']
    );
  }

  createTextures() {
    this.createReflectanceTextures();
    this.createSplatTextures();
    this.createOtherTextures();
  }

  createBrushTextures(width, height) {
    const filter = this.gl.LINEAR;
    const type = this.gl.FLOAT;

    const names = [
      'brushPositionsTexture',
      'brushPreviousPositionsTexture',
      'brushVelocitiesTexture',
      'brushPreviousVelocitiesTexture',
      'brushProjectedPositionsTexture',
      'brushProjectedPositionsTempTexture',
    ];

    for (let i = 0; i < names.length; i++) {
      this[names[i]] = this.createTexture(width, height, this.gl.RGBA, type, filter, filter);
    }

    console.log('Brush textures created');
  }

  createReflectanceTextures() {
    const filter = this.gl.LINEAR;
    const type = this.gl.UNSIGNED_BYTE;

    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; i++) {
      const name = `reflectance${i}Texture`;
      // const nameTemp = `reflectance${i}TempTexture`;

      // TODO: change it to RGB, not RGBA
      this[name] = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
      // this[nameTemp] = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
    }

    this.reflectanceTempTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);

    console.log('Reflectance textures created');
  }

  createOtherTextures() {
    const filter = this.gl.LINEAR;
    const type = this.gl.UNSIGNED_BYTE;

    this.colorTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
    this.brdfTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
    this.surfaceTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
    this.surfaceTempTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);
    this.samplePencilTexture = this.createTexture(this.width, this.height, this.gl.RGBA, type, filter, filter);

    console.log('Other textures created');
  }

  createSplatTextures() {
    const type = this.getFloatLikeType();

    const linearNames = [
      'splatBrushPaintTexture',
      'splatBrushPaintTempTexture',
      'splatBrushVelocityTexture',
      'splatBrushVelocityTempTexture',

      'splatPencilPaintTexture',
      'splatPencilPaintTempTexture',
      'splatPencilVelocityTexture',
      'splatPencilVelocityTempTexture',
      'splatPencilHelperTexture',
    ];

    const nearestNames = [
      'splatBrushDivergenceTexture',
      'splatBrushPressureTexture',
      'splatBrushPressureTempTexture',

      'splatPencilDivergenceTexture',
      'splatPencilPressureTexture',
      'splatPencilPressureTempTexture',
    ];

    for (let i = 0; i < linearNames.length; i++) {
      this[linearNames[i]] = this.createTexture(
        this.width,
        this.height,
        this.gl.RGBA,
        type,
        this.gl.LINEAR,
        this.gl.LINEAR
      );
    }

    for (let i = 0; i < nearestNames.length; i++) {
      this[nearestNames[i]] = this.createTexture(this.width, this.height, this.gl.RGBA, type);
    }

    this.clearByNames(linearNames);
    this.clearByNames(nearestNames);

    console.log('Splat textures created');
  }

  copyImagesToTextures() {
    const images = this.imageLoader.getAll();

    this.makeTextureFromImage(images.pencilSplat, 'splatPencilPaintTexture', true, true);

    this.makeTextureFromImage(images.surface, 'surfaceTexture', false);
    this.makeTextureFromImage(images.pencil, 'samplePencilTexture', false);

    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; i++) {
      this.makeTextureFromImage(images[`reflectance${i}`], `reflectance${i}Texture`, false);
    }

    this.imageLoader.release();

    console.log('Images copied to textures');
  }

  createTexture(width, height, internalFormat, srcType, filterMin, filterMag, image = null) {
    const gl = this.gl;

    const level = 0;
    const border = 0;
    const srcFormat = gl.RGBA;

    const wrapS = gl.CLAMP_TO_EDGE;
    const wrapT = gl.CLAMP_TO_EDGE;
    filterMin = filterMin || gl.NEAREST;
    filterMag = filterMag || gl.NEAREST;

    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    if (image) {
      if (width && height) {
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, image);
      } else {
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
      }
    } else {
      gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, null);
    }

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, wrapS);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, wrapT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filterMin);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filterMag);

    gl.bindTexture(gl.TEXTURE_2D, null);

    return texture;
  }

  deleteTexture(name) {
    this.gl.deleteTexture(this[name]);
  }

  clearByNames(names) {
    const obj = this;

    this.clear(names.map((name) => obj[name]));
  }

  clear(textures) {
    const gl = this.gl;

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.simulationFramebuffer);

    for (let i = 0; i < textures.length; ++i) {
      this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, textures[i], 0);

      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }
  }

  createRandomTexture(width, height, name) {
    const randoms = [];

    const howManyNumbers = width * height * 4;

    for (let i = 0; i < howManyNumbers; ++i) {
      randoms.push(Math.random());
    }

    if (this[name]) {
      this.deleteTexture(name);
    }

    this[name] = this.createTexture(
      width,
      height,
      this.gl.RGBA,
      this.gl.FLOAT,
      this.gl.LINEAR,
      this.gl.LINEAR,
      new Float32Array(randoms)
    );
  }

  makeTextureFromImage(image, name, asSurface = false, restoreAlpha = false) {
    const filter = this.gl.LINEAR;
    let tmpTexture;

    const type = asSurface ? this.getFloatLikeType() : this.gl.UNSIGNED_BYTE;

    tmpTexture = this.createTexture(undefined, undefined, this.gl.RGBA, type, filter, filter, image);

    if (restoreAlpha) {
      this.runRestoreAlphaProgram(tmpTexture, name, this.width, this.height);
    } else {
      this.runResizeProgram(tmpTexture, name, this.width, this.height);
    }

    this.gl.deleteTexture(tmpTexture);
  }

  runResizeProgram(texture, targetTextureName, width, height) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this[targetTextureName],
      0
    );

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    this.gl.viewport(0, 0, width, height);

    this.gl.useProgram(this.resizeProgram);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.uniform1i(this.resizeProgram.u_input, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.resizeProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.resizeProgram.a_position);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  runRestoreAlphaProgram(texture, targetTextureName, width, height) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this[targetTextureName],
      0
    );

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    this.gl.viewport(0, 0, width, height);

    this.gl.useProgram(this.restoreAlphaProgram);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.uniform1i(this.restoreAlphaProgram.u_input, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.restoreAlphaProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.restoreAlphaProgram.a_position);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  runRemoveAlphaProgram(sourceTextureName, targetTextureName, width, height) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this[targetTextureName],
      0
    );

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    this.gl.viewport(0, 0, width, height);

    this.gl.useProgram(this.removeAlphaProgram);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this[sourceTextureName]);
    this.gl.uniform1i(this.removeAlphaProgram.u_input, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.removeAlphaProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.removeAlphaProgram.a_position);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  removeAlpha(sourceTextureName, targetTextureName) {
    this.runRemoveAlphaProgram(sourceTextureName, targetTextureName, this.width, this.height);
  }

  swap(textureNameA, textureNameB) {
    swap(this, textureNameA, textureNameB);
  }

  copy(fromName, toName) {
    this.runResizeProgram(this[fromName], toName, this.width, this.height);
  }

  readPixelsFromResult() {
    return this.readPixels(this.testingState.testedTexture || 'brdfTexture');
  }

  readPixels(textureName) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this[textureName],
      0
    );

    const savePixels = new Uint8Array(this.width * this.height * 4);

    this.gl.readPixels(0, 0, this.width, this.height, this.gl.RGBA, this.gl.UNSIGNED_BYTE, savePixels);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

    return savePixels;
  }

  setTestingState(state) {
    this.testingState = state;
  }
}

export default TextureManager;
