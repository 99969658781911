const POSITION_KEY = 'tool_position';

export const getMemoizedPosition = () => {
  const item = localStorage.getItem(POSITION_KEY);

  let position = [(window.innerWidth * 2) / 3, window.innerHeight / 4];

  try {
    if (item) {
      const numbers = JSON.parse(item);

      if (Array.isArray(numbers) && numbers.length === 2) {
        const [x, y] = numbers;

        if (typeof x === 'number' && typeof y === 'number') {
          position = [x, y];
        }
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return position;
};

export const setPosition = (numbers) => {
  localStorage.setItem(POSITION_KEY, JSON.stringify(numbers));
};
