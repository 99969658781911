const calculatePropertiesForPencil = (settings) => {
  const properties = {};

  properties.subtraction = settings.useAsRag;

  properties.textures = {
    paintTexture: 'splatPencilPaintTexture',
    paintTempTexture: 'splatPencilPaintTempTexture',
    divergenceTexture: 'splatPencilDivergenceTexture',
    pressureTexture: 'splatPencilPressureTexture',
    pressureTempTexture: 'splatPencilPressureTempTexture',
    velocityTexture: 'splatPencilVelocityTexture',
    velocityTempTexture: 'splatPencilVelocityTempTexture',
  };

  properties.splatRadius = settings.splatRadius * settings.brushScale;

  properties.zThreshold = settings.brushZThreshold * settings.brushScale;

  properties.fluidity = settings.fluidity;

  let medium, height;

  if (properties.subtraction) {
    height = 0.0;
    medium = 1.0;
  } else {
    height = settings.medium;
    medium = 1.0 - settings.medium;
  }

  const minNS = settings.minimalNormalScale;

  const mediumCoeff = Math.pow(height, 0.3);
  const normalScale = settings.normalScale / 100;
  let normals = 1 - (minNS + (255 - minNS) * mediumCoeff) / 255;
  normals = normalScale * normals;

  properties.color = [0.0, normals, medium, undefined];

  properties.deltaTime = 1.0 / settings.deltaTimeDivider;

  if (!(settings.useAsPencil || settings.useAsRag)) {
    // use as brush
    properties.fluidity = 0.2;
    properties.deltaTime = 1.0 / 40.0;
  }

  properties.removeBrushByPencil = false;
  properties.sampleToGetMedium = false;
  properties.samplePencilTexture = false;

  if (settings.useAsPencil) {
    properties.sampleToGetMedium = true;
    properties.samplePencilTexture = true;
  }

  return properties;
};

export default calculatePropertiesForPencil;
