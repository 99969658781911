const descriptionTool = {
  activeColors: ['Active Colors', 'Pick the color or hold and drag to move Toolmaker'],
  dilutionIndicator: ['Dilution Indicator', 'Click and drag to change dilution level'],
  brushColor: ['Brush Color', 'Click and drag to change dilution level'],
  helper: ['Helper'],
};

const descriptionMenu = {
  chalks: ['Chalks', '2 sizes of chalk for sketching'],
  hogs: ['Hogs', '3 sizes of Hog brushes'],
  sobols: ['Sobols', '3 sizes of Sobol brushes'],
  rugs: ['Rugs', '2 sizes of rugs for cleaning wet paint and chalk'],
  colorsPalette: ['Colors Palette', 'Drag and drop 3 active colors to Toolmaker'],
  dryingTool: ['Drying Tool', 'Dry the wet paint layer'],
  menu: ['Menu'],
};

const en = {
  descriptionTool,
  descriptionMenu,
};

export default en;
