import BackToHome from '../BackToHome';
import Share from '../Share';

const Company = ({ engine }) => {
  return (
    <>
      <BackToHome />
      <Share engine={engine} />
    </>
  );
};

export default Company;
