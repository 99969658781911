import { controllingChangeBuckets } from '../../store/controlling/actions';
import Drawer from '../Drawer';
import Upgrade from '../Upgrade';
import DragDrop from './DragDrop';
import styles from './Palette.module.scss';
import cn from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Palette = () => {
  const divRef = useRef();
  const dragDropRef = useRef();
  const isPremium = useSelector((s) => s.details.isPremium);
  const colors = useSelector((s) => s.measures.colors.slice(0, isPremium ? 16 : 4));
  const dispatch = useDispatch();
  const state = useSelector((s) => s.controlling);

  const changeBuckets = useCallback(
    (index, position) => {
      dispatch(controllingChangeBuckets(index, position));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!dragDropRef.current) {
      dragDropRef.current = new DragDrop(state, divRef.current, colors, changeBuckets);
    }

    if (dragDropRef.current) {
      dragDropRef.current.update(state);
    }
  }, [state, changeBuckets, colors]);

  return (
    <Drawer>
      <div className={styles.palette}>
        <div className={cn(styles.list, isPremium ? styles.premium : styles.basic)}>
          <div className={styles.dragDrop} ref={divRef} />
        </div>

        {!isPremium && <Upgrade />}
      </div>
    </Drawer>
  );
};

const PaletteWrapper = () => {
  const isPremium = useSelector((s) => s.details.isPremium);

  if (isPremium) {
    return <Palette key="premium" />;
  }

  return <Palette key="basic" />;
};

export default PaletteWrapper;
