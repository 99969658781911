/* eslint-disable class-methods-use-this */
import getPosition, { isTouchScreenDevice } from '../helpers/getPosition';
import pointsOnLine from '../helpers/pointsOnLine';
import settings from '../settings';
import styles from './Stretch.module.scss';

const MAX = 300;
const Z_INDEX = 99999;

class Stretch {
  tx = 0;

  ty = 0;

  x = 0;

  y = 0;

  inertia = settings.inertia;

  stopGravity = true;

  value = -1;

  closing = false;

  visible = false;

  isDirty = false;

  active = false;

  constructor(parent, handler) {
    this.parent = parent;
    this.handler = handler;

    this.start = this.start.bind(this);
    this.move = this.move.bind(this);
    this.stop = this.stop.bind(this);
    this.checkIfItIsMoveOrClick = this.checkIfItIsMoveOrClick.bind(this);

    this.createDOM();

    if (isTouchScreenDevice) {
      this.handler.addEventListener('touchstart', this.start, false);
    } else {
      this.handler.addEventListener('mousedown', this.start, false);
    }
  }

  reset() {
    this.updateChildPosition([0, 0]);
    this.updateChildVisibility(false);
  }

  setPosition(x, y) {
    this.container.style.left = `${x}px`;
    this.container.style.top = `${y}px`;
  }

  setCenter(x, y) {
    this.centerX = x;
    this.centerY = y;
  }

  createDOM() {
    const centerOfElement = settings.colorSize / 2;

    this.container = document.createElement('div');
    this.container.className = styles.container;

    // build canvas
    this.canvas = document.createElement('canvas');
    this.canvas.setAttribute('width', String(4 * MAX));
    this.canvas.setAttribute('height', String(4 * MAX));
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = `${centerOfElement}px`;
    this.canvas.style.left = `${centerOfElement}px`;
    this.canvas.style.width = `${2 * MAX}px`;
    this.canvas.style.height = `${2 * MAX}px`;
    this.canvas.style.opacity = '0.3';
    this.container.appendChild(this.canvas);

    this.preview = document.createElement('div');
    this.preview.className = styles.preview;
    this.container.appendChild(this.preview);

    this.parent.appendChild(this.container);

    this.ctx = this.canvas.getContext('2d');
  }

  updateChildPosition(position) {
    this.preview.style.left = `${position[0]}px`;
    this.preview.style.top = `${position[1]}px`;
  }

  updateChildVisibility(isVisible) {
    if (isVisible) {
      clearTimeout(this.timer);

      this.preview.style.opacity = String(1);
      this.container.style.zIndex = String(Z_INDEX);
    } else {
      this.preview.style.opacity = String(0);

      this.timer = setTimeout(() => {
        this.container.style.zIndex = 'auto';
      }, 250);
    }
  }

  start(e) {
    e.preventDefault();
    e.stopPropagation();

    const clickPosition = getPosition(e);

    if (!clickPosition[2]) {
      return;
    }

    // initial position for inertia system (set current and target as center)
    this.x = 0;
    this.y = 0;
    this.tx = 0;
    this.ty = 0;

    this.isAdding = this.centerY - clickPosition[1] > 0;

    this.stretchPreview.markDirection(this.isAdding);

    this.pointerPosition = clickPosition;
    this.isDirty = false;

    if (isTouchScreenDevice) {
      this.handler.addEventListener('touchmove', this.move, false);
      this.handler.addEventListener('touchend', this.stop, false);
    } else {
      window.addEventListener('mousemove', this.move, true);
      window.addEventListener('mouseup', this.stop, true);
    }

    this.movingRaf = requestAnimationFrame(this.checkIfItIsMoveOrClick);
  }

  checkIfItIsMoveOrClick(timestamp) {
    if (!this.movingStartTime) {
      this.movingStartTime = timestamp;
    }

    const delta = this.selected ? 1000 : timestamp - this.movingStartTime;

    if (delta < 250) {
      this.movingRaf = requestAnimationFrame(this.checkIfItIsMoveOrClick);
    } else {
      if (this.isOutsideElement(this.pointerPosition)) {
        this.internalStop();

        return;
      }

      this.onStart();

      if (!this.visible) {
        this.updateChildVisibility(true);

        this.visible = true;
      }

      this.active = true;
    }
  }

  setTargetPosition(position) {
    this.tx = position[0];
    this.ty = position[1];

    if (this.stopGravity) {
      this.stopGravity = false;

      this.updateInertia();
    }
  }

  inertiaTo(current, target) {
    if (this.inertia === 1) {
      return [target, true];
    }

    const distToGo = target - current;

    if (Math.abs(distToGo) < 0.01) {
      return [target, true];
    }

    const newPosition = current + distToGo * this.inertia;

    return [newPosition, false];
  }

  updateInertia() {
    const [x, stopGravityX] = this.inertiaTo(this.x, this.tx);
    const [y, stopGravityY] = this.inertiaTo(this.y, this.ty);

    this.x = x;
    this.y = y;

    this.drawChild();

    this.stopGravity = stopGravityX && stopGravityY;

    if (!this.stopGravity) {
      requestAnimationFrame(() => {
        this.updateInertia();
      });
    } else if (this.closing) {
      this.closed();
    }
  }

  closed() {
    this.closing = false;
    this.stopGravity = true;
    this.visible = false;

    this.updateChildVisibility(false);
  }

  drawChild() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    /*
     * [MAX, MAX] -> center of canvas
     */
    this.drawDots([MAX, MAX], [MAX + this.x, MAX + this.y]);

    this.updateChildPosition([this.x, this.y]);
  }

  drawDots(from, to) {
    const ctx = this.ctx;

    const sizes = {
      shadow: 1.5,
      bigDot: 4.2,
      smallDot: 1.5,
    };

    const SCALE = 2;

    pointsOnLine(
      from,
      to,
      6,
      (x, y) => {
        // shadow
        ctx.beginPath();
        ctx.fillStyle = 'rgba(0, 0, 0, 0.45)';
        ctx.arc(x + 1, y + 1, SCALE * sizes.shadow, 0, 2 * Math.PI);
        ctx.fill();

        ctx.beginPath();
        ctx.fillStyle = '#fff';

        ctx.arc(x, y, SCALE * sizes.smallDot, 0, 2 * Math.PI);

        ctx.fill();
      },
      4
    );
  }

  isOutsideElement(position) {
    //  calculate delta of clicked element and click
    const bbox = this.handler.getBoundingClientRect();
    this.center = [bbox.left + bbox.width / 2, bbox.top + bbox.height / 2];
    this.deltaOfElementAndClick = [position[0] - this.center[0], position[1] - this.center[1]];

    const x = this.deltaOfElementAndClick[0];
    const y = this.deltaOfElementAndClick[1];
    const radius = Math.sqrt(x * x + y * y);

    if (radius > settings.colorSize / 2) {
      return true;
    }

    return false;
  }

  move(e) {
    e.preventDefault();

    const mouse = getPosition(e);

    if (!this.active) {
      this.pointerPosition = mouse;

      return;
    }

    if (!this.isDirty) {
      this.pointerPosition = mouse;

      //  calculate delta of clicked element and click
      const bbox = this.handler.getBoundingClientRect();
      this.center = [bbox.left + bbox.width / 2, bbox.top + bbox.height / 2];
      this.deltaOfElementAndClick = [
        this.pointerPosition[0] - this.center[0],
        this.pointerPosition[1] - this.center[1],
      ];
    }

    this.isDirty = true;

    const enhancedMouse = [mouse[0] - this.deltaOfElementAndClick[0], mouse[1] - this.deltaOfElementAndClick[1]];

    const centerPosition = this.center;
    const dX = enhancedMouse[0] - centerPosition[0];
    const dY = enhancedMouse[1] - centerPosition[1];
    const d = Math.sqrt(dX * dX + dY * dY);

    let translation;

    if (d > MAX) {
      const v = [dX / d, dY / d];

      translation = [v[0] * MAX, v[1] * MAX];
    } else {
      translation = [dX, dY];
    }

    const scaleValue = Math.min(1, d / MAX);

    const value = this.calculateValue(scaleValue);

    if (value !== this.value) {
      this.updateValue(value);

      this.value = value;
    }

    this.setTargetPosition(translation);
  }

  calculateValue(scale) {
    if (this.isAdding) {
      return this.referenceValue - this.referenceValue * scale;
    } else {
      return this.referenceValue + (1 - this.referenceValue) * scale;
    }
  }

  setCurrentValue(value) {
    this.referenceValue = value;
  }

  internalStop() {
    cancelAnimationFrame(this.movingRaf);

    this.handler.removeEventListener('touchmove', this.move, false);
    this.handler.removeEventListener('touchend', this.stop, false);

    window.removeEventListener('mousemove', this.move, true);
    window.removeEventListener('mouseup', this.stop, true);

    this.closing = true;

    this.setTargetPosition([0, 0]);

    this.movingStartTime = undefined;
    this.active = false;
  }

  stop(e) {
    e.stopPropagation();

    this.internalStop();

    if (this.isDirty) {
      this.onStop();
    } else if (!this.isOutsideElement(this.pointerPosition)) {
      this.onClick();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addToPreview(parent) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateValue(value) {}

  onStart() {}

  onStop() {}

  onClick() {}
}

export default Stretch;
