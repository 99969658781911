import Inertia from '../Inertia';
import { selectBucket } from './actions';
import getPosition from './helpers/getPosition';
import { setPosition, getMemoizedPosition } from './helpers/toolPositionStorage';
import settings from './settings';
import { debounce } from 'debounce';

const DEBOUNCE_MS = 300;
const CLICK_RADIUS = 20;

class Move extends Inertia {
  moving = false;

  constructor(elem, updatePosition) {
    super();

    this.elem = elem;
    this.updatePosition = updatePosition;

    this.addEventListeners();

    this.clamp();

    this.inertiaStart(settings.inertia);

    setTimeout(() => {
      this.updatePosition(getMemoizedPosition());
    }, 0);
  }

  clamp() {
    this.inertiaSetClamps([20, window.innerWidth - 210], [20, window.innerHeight - 150]);
  }

  addEventListeners() {
    this.resize = this.resize.bind(this);
    this.memoizePosition = this.memoizePosition.bind(this);

    window.addEventListener('resize', debounce(this.resize, DEBOUNCE_MS), false);
    window.addEventListener('unload', this.memoizePosition);
  }

  resize() {
    this.clamp();
    this.inertiaValidatePosition();
  }

  start(position, currentIndex) {
    this.currentIndex = currentIndex;

    this.oldPosition = this.position;
    this.startPosition = [position[0], position[1]];

    this.moving = false;
  }

  move(e) {
    const mouse = getPosition(e);
    const delta = [mouse[0] - this.startPosition[0], mouse[1] - this.startPosition[1]];

    if (this.moving) {
      const newPosition = [this.oldPosition[0] + delta[0], this.oldPosition[1] + delta[1]];

      this.inertiaSetTargetPosition(newPosition[0], newPosition[1]);
    } else {
      const length = Math.sqrt(delta[0] * delta[0] + delta[1] * delta[1]);

      if (length > CLICK_RADIUS) {
        this.startPosition = [mouse[0], mouse[1]];
        this.moving = true;
      }
    }
  }

  inertiaApplyCurrentPosition(x, y) {
    this.updatePosition([x, y]);
  }

  stop() {
    this.inertiaValidatePosition();

    if (!this.moving) {
      this.oneClick();
    }
  }

  oneClick() {
    selectBucket(this.currentIndex);
  }

  setInitialPosition(position) {
    this.inertiaSetCurrentPosition(position[0], position[1]);
    this.inertiaInitialTargetPosition(position[0], position[1]);
  }

  setPosition(position) {
    if (!this.position) {
      this.setInitialPosition(position);
    }

    this.position = position;

    this.elem.style.left = `${position[0]}px`;
    this.elem.style.top = `${position[1]}px`;
  }

  memoizePosition() {
    setPosition(this.position);
  }
}

export default Move;
