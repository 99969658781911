import { HIDE_STATUS_JS } from '../settings';
import Stats from 'stats.js';

const stats = new Stats();
stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
stats.dom.style.left = '0';
stats.dom.style.bottom = '0';
stats.dom.style.top = 'unset';

if (HIDE_STATUS_JS) {
  stats.dom.style.display = 'none';
}

document.body.appendChild(stats.dom);

export default stats;
