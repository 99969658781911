import { getPremiumStatus, getTrialEndDate } from '../../api';
import { detailsSetPremium } from '../../store/details/actions';
import styles from './Upgrade.module.scss';
import cn from 'classnames';
import { differenceInHours, parseISO } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const UpgradeUser = () => {
  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedNegative, setVerifiedNegative] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const verify = () => {
    const makeRequest = async () => {
      setLoading(true);

      try {
        const premiumResponse = await getPremiumStatus();
        let isPremium = false;

        if (premiumResponse.data) {
          isPremium = true;
        } else {
          const trialResponse = await getTrialEndDate();

          if (trialResponse.data) {
            isPremium = isPremium || differenceInHours(new Date(), parseISO(trialResponse.data)) < 0;
          }
        }

        await new Promise((r) => setTimeout(r, 500));

        if (isPremium) {
          dispatch(detailsSetPremium(true));
        } else {
          setLoading(false);
          setVerifiedNegative(true);
        }
      } catch {
        setLoading(false);
      }
    };

    makeRequest();
  };

  return (
    <div className={styles.container}>
      <div className={styles.upgrade}>
        <div className={styles.limited}>
          {!showVerificationScreen && (
            <>
              {t('Your tools are')} <span>{t('limited')}</span>
            </>
          )}
          {showVerificationScreen && (
            <>
              {t('Pro Plan')} <span>{t('verification')}</span>
            </>
          )}
        </div>
        <div className={styles.text}>
          <div>
            {!showVerificationScreen && (
              <>
                {t('You are on a')} <strong className={styles.basic}>{t('Basic')}</strong> {t('plan.')}
                <br />
                <br />
              </>
            )}

            {!showVerificationScreen && (
              <>
                {t('Please start 14 days trial version or change the plan to')} <strong>{t('pro')}</strong>
                {t(' to get full access.')}
              </>
            )}
            {showVerificationScreen && (
              <>
                {t('Please click the button below')}
                <br />
                {t('to verify your')} <strong>{t('subscription')}</strong>
                <br />
                {t('is activated.')}
              </>
            )}
          </div>
        </div>

        {!showVerificationScreen && (
          <div className={styles.buttons}>
            <a
              href="https://user.wellofart.com/user"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.premium}
              onClick={() => {
                setShowVerificationScreen(true);
              }}
            >
              {t('Start 14 days trial')}
            </a>

            <a
              href="https://user.wellofart.com/paymentplan"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.premium}
              onClick={() => {
                setShowVerificationScreen(true);
              }}
            >
              {t('Change plan to pro')}
            </a>
          </div>
        )}

        {showVerificationScreen && (
          <div className={cn(styles.buttons, styles.oneLine)}>
            <button
              className={styles.premium}
              onClick={() => {
                verify();
              }}
              disabled={verifiedNegative || loading}
            >
              {loading ? t('Verifying...') : verifiedNegative ? t('No Pro Plan') : t('Verify')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpgradeUser;
