import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

class Output {
  constructor(gl, shaders, viewport) {
    this.gl = gl;
    this.shaders = shaders;
    this.viewport = viewport;

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.outputProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.outputFrag,
      ['a_position'],
      ['u_input']
    );
  }

  print(texture) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

    this.gl.useProgram(this.outputProgram);

    const finalViewport = this.viewport.viewport;

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

    this.gl.viewport(finalViewport.left, finalViewport.top, finalViewport.width, finalViewport.height);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.uniform1i(this.outputProgram.u_input, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.outputProgram.a_position);
    this.gl.vertexAttribPointer(this.outputProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }
}

export default Output;
