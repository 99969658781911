const calculatePropertiesForBrush = (settings) => {
  const properties = {};

  properties.subtraction = settings.useAsRag || settings.useAsPencil;

  properties.textures = {
    paintTexture: 'splatBrushPaintTexture',
    paintTempTexture: 'splatBrushPaintTempTexture',
    divergenceTexture: 'splatBrushDivergenceTexture',
    pressureTexture: 'splatBrushPressureTexture',
    pressureTempTexture: 'splatBrushPressureTempTexture',
    velocityTexture: 'splatBrushVelocityTexture',
    velocityTempTexture: 'splatBrushVelocityTempTexture',
  };

  properties.removeBrushByPencil = false;
  properties.sampleToGetMedium = false;

  properties.splatRadius = settings.splatRadius * settings.brushScale;

  if (settings.useAsPencil) {
    properties.splatRadius = Math.min(2.0 * properties.splatRadius, 4.0);

    properties.removeBrushByPencil = true;
  }

  properties.zThreshold = settings.brushZThreshold * settings.brushScale;

  properties.fluidity = settings.fluidity;

  let medium, height;

  if (properties.subtraction) {
    height = 0.0;
    medium = 1.0;
  } else {
    height = settings.medium;
    medium = 1.0 - settings.medium;
  }

  const minNS = settings.minimalNormalScale;

  const mediumCoeff = Math.pow(height, 0.3);
  const normalScale = settings.normalScale / 100;
  let normals = 1 - (minNS + (255 - minNS) * mediumCoeff) / 255;
  normals = normalScale * normals;

  properties.color = [0.0, normals, medium, undefined];

  properties.samplePencilTexture = false;

  // should be the same as framesToSimulate
  properties.deltaTime = 1.0 / settings.deltaTimeDivider;

  return properties;
};

export default calculatePropertiesForBrush;
