import styles from './Medium.module.scss';

const MEDIUM_COLOR = '#fbf9eb';

class Element {
  constructor(parent) {
    this.parent = parent;

    this.createDOM();
  }

  createDOM() {
    const size = 60;

    this.element = document.createElement('div');
    this.element.style.width = `${size}px`;
    this.element.style.height = `${size}px`;
    this.element.className = styles.stretchPreview;
    this.parent.appendChild(this.element);

    this.currentColor = document.createElement('div');
    this.currentColor.className = styles.stretchCurrentColor;
    this.element.appendChild(this.currentColor);

    this.historyCircle = document.createElement('div');
    this.historyCircle.className = styles.historyCircle;
    this.element.appendChild(this.historyCircle);

    this.targetCircle = document.createElement('div');
    this.targetCircle.className = styles.targetCircle;
    this.element.appendChild(this.targetCircle);

    this.saveCircle = document.createElement('div');
    this.saveCircle.className = styles.saveCircle;
    this.element.appendChild(this.saveCircle);
  }

  markDirection(isAdding) {
    this.isAdding = isAdding;

    this.targetCircle.style.background = this.isAdding ? this.targetColor : MEDIUM_COLOR;
  }

  takeColor(color, forced = false) {
    if (forced) {
      this.historyColor = color;
    }

    this.currentColor.style.background = color;
    this.historyCircle.style.background = this.historyColor;
    this.saveCircle.style.background = this.historyColor;

    this.targetCircle.style.background = this.isAdding ? this.targetColor : MEDIUM_COLOR;
  }

  showHistory() {
    this.targetCircle.style.transform = 'scale(1)';
  }

  hideHistory() {
    this.targetCircle.style.transform = 'scale(0)';
  }

  setTargetColor(color) {
    this.targetColor = color;
  }
}

export default Element;
