import Inertia from '../../Inertia';
import classes from './DragDrop.module.scss';
import getPosition from './helpers/getPosition';
import isTouchScreenDevice from './helpers/isTouchScreenDevice';

const DRAG_INIT_TIME = 10;

const ICON_SIZE = 48;
const ICON_HALF_SIZE = 24;
const TARGET_RADIUS = ICON_HALF_SIZE + 2;

const FINISHING_TO_HOME = 1;
const FINISHING_TO_TARGET = 2;
const FINISHING_DISABLED = 0;

class DragDrop extends Inertia {
  draggedElemIndex = -1;

  active = false;

  draggingActive = false;

  finishing = FINISHING_DISABLED;

  selectedTarget = undefined;

  centerOfTargets = [];

  centerOfElement = [0, 0];

  constructor(parent, elements, targets, selected, handleChange) {
    super();

    this.parent = parent;
    this.elements = elements;
    this.targets = targets;
    this.selected = selected;
    this.handleChange = handleChange;

    this.checkDelay = this.checkDelay.bind(this);
    this.move = this.move.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.stopMove = this.stopMove.bind(this);
    this.selectTarget = this.selectTarget.bind(this);
    this.followPosition = this.followPosition.bind(this);
    this.beforeStart = this.beforeStart.bind(this);

    this.targetParent = this.targets[0].parentNode;

    this.addEventsToElements();
    this.getItemClassName();

    this.inertiaStart(0.3);

    this.inertiaClamps = undefined;

    this.selected.forEach((index) => {
      this.elements[index].classList.add(classes.dragged);
    });
  }

  addEventsToElements() {
    const handlerActionName = isTouchScreenDevice ? 'touchstart' : 'mousedown';

    this.elements.forEach((elem, index) => {
      elem.addEventListener(
        handlerActionName,
        (e) => {
          this.beforeStart(e, index);
        },
        false
      );
    });
  }

  getItemClassName() {
    if (this.targets[0] && this.targets[0].firstElementChild) {
      const className = this.targets[0].firstElementChild.className;

      if (className) {
        this.itemClassName = className;
      }
    }
  }

  beforeStart(e, index) {
    e.stopPropagation();

    if (this.active || this.finishing !== FINISHING_DISABLED) {
      return;
    }

    if (this.selected.includes(index)) {
      return;
    }

    this.start(e, index);
  }

  start(e, index) {
    const position = getPosition(e);

    if (!position[2]) {
      return;
    }

    this.draggedElemIndex = index;

    this.elem = this.elements[this.draggedElemIndex];
    this.getCurrentPosition(e);

    if (isTouchScreenDevice) {
      this.elem.addEventListener('touchend', this.stop, false);
      this.elem.addEventListener('touchmove', this.followPosition, false);
    } else {
      window.addEventListener('mouseup', this.stop, false);
      window.addEventListener('mousemove', this.followPosition, false);
    }

    this.active = true;

    requestAnimationFrame(this.checkDelay);
  }

  checkDelay(timestamp) {
    if (!this.active) {
      return;
    }

    if (!this.dragStartTime) {
      this.dragStartTime = timestamp;
    }

    const delta = timestamp - this.dragStartTime;

    if (delta >= DRAG_INIT_TIME) {
      this.addActions();
    } else {
      requestAnimationFrame(this.checkDelay);
    }
  }

  followPosition(e) {
    const pos = getPosition(e);
    const delta = Math.sqrt((pos[0] - this.centerOfElement[0]) ** 2 + (pos[1] - this.centerOfElement[1]) ** 2);

    if (delta > TARGET_RADIUS) {
      this.syntheticStop();
    }
  }

  stopTouching() {
    this.elem.removeEventListener('touchmove', this.followPosition, false);
    window.removeEventListener('mousemove', this.followPosition, false);

    this.active = false;
    this.dragStartTime = undefined;
  }

  stop(e) {
    e.stopPropagation();

    this.elem.removeEventListener('touchend', this.stop, false);
    window.removeEventListener('mouseup', this.stop, false);

    this.syntheticStop();
  }

  syntheticStop() {
    this.stopTouching();
    this.unlockParent();
  }

  getCurrentPosition(e) {
    const bbox = this.elem.getBoundingClientRect();
    const center = [bbox.left + bbox.width / 2, bbox.top + bbox.height / 2];

    const position = getPosition(e);

    this.clickPosition = [position[0], position[1]];
    this.deltaOfElementAndClick = [this.clickPosition[0] - center[0], this.clickPosition[1] - center[1]];
    this.centerOfElement = center;
  }

  addActions() {
    this.lockParent();
    this.stopTouching();

    this.draggingActive = true;

    if (isTouchScreenDevice) {
      this.elem.addEventListener('touchmove', this.move, false);
      this.elem.addEventListener('touchend', this.stopMove, false);
    } else {
      window.addEventListener('mousemove', this.move, false);
      window.addEventListener('mouseup', this.stopMove, true);
    }

    this.createDragHandler();
    this.getCenterOfTargets();

    this.inertiaSetCurrentPosition(this.clickPosition[0], this.clickPosition[1]);
    this.inertiaInitialTargetPosition(this.clickPosition[0], this.clickPosition[1]);
    this.inertiaApplyCurrentPosition(this.clickPosition[0], this.clickPosition[1]);

    this.elem.classList.remove(classes.withShowUpAnimation);
    this.elem.classList.add(classes.dragged);

    this.targetParent.classList.add(classes.active);
  }

  getCenterOfTargets() {
    this.centerOfTargets = [];

    this.targets.forEach((target) => {
      const rect = target.getBoundingClientRect();

      this.centerOfTargets.push([rect.left + rect.width / 2, rect.top + rect.height / 2]);
    });
  }

  createDragHandler() {
    this.dragHandler = this.elem.cloneNode(true);

    this.dragHandler.classList.remove(classes.dragged, classes.withShowUpAnimation);
    this.dragHandler.classList.add(classes.dragHandler, isTouchScreenDevice ? classes.touch : classes.click);

    document.body.appendChild(this.dragHandler);
  }

  inertiaApplyCurrentPosition(x, y) {
    const left = x - this.deltaOfElementAndClick[0] - ICON_HALF_SIZE;
    const top = y - this.deltaOfElementAndClick[1] - ICON_HALF_SIZE;

    this.dragHandler.style.left = `${left}px`;
    this.dragHandler.style.top = `${top}px`;
  }

  lockParent() {
    // this.parent.style.overflowY = 'hidden';
  }

  unlockParent() {
    // this.parent.style.overflowY = 'scroll';
  }

  move(e) {
    e.preventDefault();

    const currentPosition = getPosition(e);

    this.checkIfSomeTargetIsSelected([currentPosition[0], currentPosition[1]]);

    this.inertiaSetTargetPosition(currentPosition[0], currentPosition[1]);
  }

  stopMove(e) {
    e.preventDefault();
    e.stopPropagation();

    this.elem.removeEventListener('touchmove', this.move, false);
    this.elem.removeEventListener('touchend', this.stopMove, false);
    window.removeEventListener('mousemove', this.move, false);
    window.removeEventListener('mouseup', this.stopMove, true);

    this.draggingActive = false;

    this.targetParent.classList.remove(classes.active);

    if (this.selectedTarget !== undefined) {
      this.finishing = FINISHING_TO_TARGET;
      this.goToTarget();
    } else {
      this.finishing = FINISHING_TO_HOME;
      this.goToHome();
    }
  }

  checkIfSomeTargetIsSelected(position) {
    let selectedIndex;

    for (let i = 0; i < this.centerOfTargets.length; i++) {
      const dist = Math.sqrt(
        (position[0] - this.centerOfTargets[i][0]) ** 2 + (position[1] - this.centerOfTargets[i][1]) ** 2
      );

      if (dist < TARGET_RADIUS) {
        selectedIndex = i;
        break;
      }
    }

    this.selectTarget(selectedIndex);
  }

  selectTarget(index) {
    if (index !== undefined) {
      this.dragHandler.classList.add(classes.normal);
    } else {
      this.dragHandler.classList.remove(classes.normal);
    }

    this.selectedTarget = index;
  }

  goToTarget() {
    const target = this.targets[this.selectedTarget || 0];
    const firstChild = target.firstElementChild;
    let bbox;

    if (firstChild) {
      bbox = firstChild.getBoundingClientRect();
    } else {
      bbox = target.getBoundingClientRect();
    }

    const left = bbox.left + bbox.width / 2 + this.deltaOfElementAndClick[0];
    const top = bbox.top + bbox.height / 2 + this.deltaOfElementAndClick[1];

    this.inertiaSetTargetPosition(left, top);
  }

  goToHome() {
    this.inertiaSetTargetPosition(this.clickPosition[0], this.clickPosition[1]);
  }

  inertiaStopped() {
    const selectedTarget = this.selectedTarget || 0;

    if (this.finishing === FINISHING_TO_TARGET) {
      const parent = this.targets[selectedTarget];

      while (parent.lastChild) {
        parent.lastChild.remove();
      }

      const index = parseInt(this.elem.getAttribute('data-index') || '0', 10);

      const item = document.createElement('div');
      item.className = this.itemClassName;
      item.style.backgroundPositionY = `-${index * ICON_SIZE}px`;
      parent.appendChild(item);

      document.body.removeChild(this.dragHandler);
      this.swipe(selectedTarget, this.draggedElemIndex);
    } else if (this.finishing === FINISHING_TO_HOME) {
      this.elem.classList.replace(classes.dragged, classes.withShowUpAnimation);

      document.body.removeChild(this.dragHandler);
    }

    if (this.finishing !== FINISHING_DISABLED) {
      this.finishing = FINISHING_DISABLED;
      this.selectedTarget = undefined;

      this.unlockParent();
    }
  }

  swipe(targetIndex, elemIndex) {
    this.handleChange(elemIndex, targetIndex);
  }

  /*
   * We assume that diff between current selected indices and new ones always contains only one change.
   */
  updateSelected(newSelected) {
    let indexToShow = -1;

    for (let i = 0; i < this.selected.length; i++) {
      if (!newSelected.includes(this.selected[i])) {
        indexToShow = this.selected[i];
        break;
      }
    }

    if (indexToShow >= 0) {
      this.elements[indexToShow].classList.replace(classes.dragged, classes.withShowUpAnimation);
    }

    this.selected = newSelected;
  }
}

export default DragDrop;
