import { makeOrthographicMatrix } from './helpers/Matrix';
import createProgram from './helpers/createProgram';

const CAMERA_DISTANCE = 5000.0;

class BrushViewer {
  constructor(gl, shaders, viewport, textureManager, brush) {
    this.gl = gl;
    this.shaders = shaders;
    this.viewport = viewport;
    this.textureManager = textureManager;
    this.brush = brush;

    this.createPrograms();
    this.createMatrices();
  }

  createPrograms() {
    this.brushProgram = createProgram(
      this.gl,
      this.shaders.brushViewerVert,
      this.shaders.brushViewerFrag,
      ['a_textureCoordinates'],
      ['u_color', 'u_projectionViewMatrix', 'u_positionsTexture']
    );
  }

  createMatrices() {
    this.mainProjectionMatrix = makeOrthographicMatrix(
      new Float32Array(16),
      0.0,
      this.textureManager.width,
      0.0,
      this.textureManager.height,
      -CAMERA_DISTANCE,
      CAMERA_DISTANCE
    );
  }

  print() {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.enable(this.gl.BLEND);
    this.gl.blendFunc(this.gl.DST_COLOR, this.gl.ZERO);

    this.gl.useProgram(this.brushProgram);

    const viewport = this.viewport.viewport;

    this.gl.viewport(viewport.left, viewport.top, viewport.width, viewport.height);

    this.gl.uniform4f(this.brushProgram.u_color, 0.6, 0.6, 0.6, 1.0);
    this.gl.uniformMatrix4fv(this.brushProgram.u_projectionViewMatrix, false, this.mainProjectionMatrix);

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPositionsTexture);
    this.gl.uniform1i(this.brushProgram.u_positionsTexture, 0);

    // IBO
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.brush.brushIndexBuffer);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.brush.brushTextureCoordinatesBuffer);
    this.gl.vertexAttribPointer(this.brushProgram.a_textureCoordinates, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.brushProgram.a_textureCoordinates);

    this.gl.drawElements(this.gl.LINES, this.brush.indexCount, this.gl.UNSIGNED_SHORT, 0);
  }
}

export default BrushViewer;
