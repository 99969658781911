import isTouchScreenDevice from './isTouchScreenDevice';

export const COORD_X = 0;
export const COORD_Y = 1;
export const IS_CORRECT = 2;

const getPosition = (e) => {
  let position;

  if (isTouchScreenDevice) {
    const touch = e.touches[0] || e.changedTouches[0];

    position = [touch.clientX, touch.clientY, e.touches.length === 1];
  } else {
    position = [e.clientX, e.clientY, true];
  }

  return position;
};

export default getPosition;
