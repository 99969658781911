import styles from './Confirm.module.scss';
import { useTranslation } from 'react-i18next';

const Confirm = ({ text, handleYes, handleNo }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.text}>{text}</div>
        <div className={styles.answers}>
          <button className={styles.yes} onClick={handleYes}>
            {t('Yes')}
          </button>
          <button className={styles.no} onClick={handleNo}>
            {t('No')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
