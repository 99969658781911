import getPosition, { isTouchScreenDevice } from '../helpers/getPosition';
import settings from '../settings';
import styles from './Medium.module.scss';

const PI_HALF = Math.PI / 2;
const DISTANCE_FROM_CENTER = 46;
const INERTIA = 0.1;
const NO_INERTIA = 1.0;

class Range {
  inertiaTx = 0;

  inertiaX = 0;

  inertia = INERTIA;

  inertiaStop = true;

  value = 0;

  constructor(parent, previewValue, confirmValue) {
    this.parent = parent;
    this.previewValue = previewValue;
    this.confirmValue = confirmValue;

    this.createDOM();

    this.addEventListeners();

    this.drawPointer();
  }

  inertiaTo(current, target) {
    if (this.inertia === NO_INERTIA) {
      this.inertiaStop = true;

      return target;
    }

    const distToGo = target - current;
    let newPosition = current + distToGo * this.inertia;

    if (Math.abs(distToGo) < 0.001) {
      newPosition = target;

      this.inertiaStop = true;
    }

    return newPosition;
  }

  inertiaUpdate() {
    this.inertiaX = this.inertiaTo(this.inertiaX, this.inertiaTx);

    this.drawPointer();

    if (!this.inertiaStop) {
      requestAnimationFrame(() => {
        this.inertiaUpdate();
      });
    }
  }

  inertiaSetTargetPosition(position) {
    this.inertiaTx = position;

    if (this.inertiaStop) {
      this.inertiaStop = false;

      this.inertiaUpdate();
    }
  }

  createDOM() {
    this.dotSlider = document.createElement('div');
    this.dotSlider.className = styles.range;
    this.dotSlider.style.zIndex = String(settings.colorZIndexDefault);

    this.parent.appendChild(this.dotSlider);
  }

  addEventListeners() {
    this.start = this.start.bind(this);
    this.move = this.move.bind(this);
    this.stop = this.stop.bind(this);

    if (isTouchScreenDevice) {
      this.dotSlider.addEventListener('touchstart', this.start, false);
    } else {
      this.dotSlider.addEventListener('mousedown', this.start, false);
    }
  }

  start(e) {
    e.preventDefault();
    e.stopPropagation();

    const clickPosition = getPosition(e);

    if (!clickPosition[2]) {
      return;
    }

    //  calculate delta of clicked element and click
    const bbox = this.dotSlider.getBoundingClientRect();
    this.center = [bbox.left + bbox.width / 2, bbox.top + bbox.height / 2];

    this.deltaOfElementAndClick = [clickPosition[0] - this.center[0], clickPosition[1] - this.center[1]];

    if (isTouchScreenDevice) {
      this.dotSlider.addEventListener('touchmove', this.move, false);
      this.dotSlider.addEventListener('touchend', this.stop, false);
    } else {
      window.addEventListener('mousemove', this.move, true);
      window.addEventListener('mouseup', this.stop, true);
    }

    this.dotSlider.classList.add(styles.active);
    this.dotSlider.classList.remove(styles.inactive);
  }

  stop() {
    this.dotSlider.classList.add(styles.inactive);
    this.dotSlider.classList.remove(styles.active);

    this.dotSlider.removeEventListener('touchmove', this.move, false);
    this.dotSlider.removeEventListener('touchend', this.stop, false);
    window.removeEventListener('mousemove', this.move, true);
    window.removeEventListener('mouseup', this.stop, true);

    this.confirmValue();
  }

  move(e) {
    e.preventDefault();

    const mouse = getPosition(e);
    const enhancedMouse = [mouse[0] - this.deltaOfElementAndClick[0], mouse[1] - this.deltaOfElementAndClick[1]];

    const v = [enhancedMouse[0] - this.centerOfParent[0], enhancedMouse[1] - this.centerOfParent[1]];

    const value = 0.5 + Math.min(Math.max(Math.atan2(v[1], v[0]), -PI_HALF), PI_HALF) / Math.PI;

    if (value !== this.value) {
      this.previewValue(value);

      this.value = value;
    }
  }

  drawPointer() {
    const canvasSize = settings.colorSize;

    const angle = (-0.5 + this.inertiaX) * Math.PI;

    const x = Math.cos(angle) * DISTANCE_FROM_CENTER;
    const y = Math.sin(angle) * DISTANCE_FROM_CENTER;

    const center = canvasSize / 2;
    const [u, v] = [center + x, center + y];

    this.dotSlider.style.left = `${u}px`;
    this.dotSlider.style.top = `${v}px`;
  }

  setCenterOfParent(position) {
    this.centerOfParent = position;
  }

  takeValue(value) {
    this.value = value;

    this.inertiaSetTargetPosition(value);
  }
}

export default Range;
