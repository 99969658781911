const NO_INERTIA = 1.0;

class Inertia {
  inertiaTx = 0;

  inertiaTy = 0;

  inertiaX = 0;

  inertiaY = 0;

  inertia = NO_INERTIA;

  inertiaStop = true;

  inertiaClamps = [];

  inertiaSetClamps(x, y) {
    this.inertiaClamps = [x, y];
  }

  /*
   * This method checks distance between current position and target position in one dimension.
   * We are not consciously using Euclidean metrics.
   */
  inertiaTo(index, current, target) {
    if (this.inertia === NO_INERTIA) {
      return {
        value: target,
        stop: true,
      };
    }

    const distToGo = target - current;
    let newPosition = current + distToGo * this.inertia;

    let stop = false;

    if (Math.abs(distToGo) < 0.001) {
      newPosition = target;

      if (this.inertiaClamps) {
        const clamp = this.inertiaClamps[index];

        stop = newPosition >= clamp[0] && newPosition <= clamp[1];
      } else {
        stop = true;
      }
    }

    return { value: newPosition, stop };
  }

  inertiaUpdate() {
    const inertiaResultX = this.inertiaTo(0, this.inertiaX, this.inertiaTx);
    const inertiaResultY = this.inertiaTo(1, this.inertiaY, this.inertiaTy);

    this.inertiaX = inertiaResultX.value;
    this.inertiaY = inertiaResultY.value;

    if (inertiaResultX.stop && inertiaResultY.stop) {
      this.inertiaStop = true;
    }

    this.inertiaApplyCurrentPosition(this.inertiaX, this.inertiaY);

    if (!this.inertiaStop) {
      requestAnimationFrame(() => {
        this.inertiaUpdate();
      });
    } else {
      this.inertiaStopped();
    }
  }

  inertiaSetTargetPosition(tx, ty) {
    this.inertiaTx = tx;
    this.inertiaTy = ty;

    if (this.inertiaStop) {
      this.inertiaStop = false;

      this.inertiaUpdate();
    }
  }

  inertiaSetCurrentPosition(x, y) {
    this.inertiaX = x;
    this.inertiaY = y;
  }

  inertiaInitialTargetPosition(x, y) {
    this.inertiaTx = x;
    this.inertiaTy = y;
  }

  inertiaStart(inertia = NO_INERTIA) {
    this.inertia = inertia;
  }

  inertiaValidatePosition() {
    if (!this.inertiaClamps) {
      return;
    }

    const x = this.inertiaClamps[0];
    const y = this.inertiaClamps[1];

    /*
     * Minimal workspace
     */
    if (x[1] - x[0] < 1 || y[1] - y[0] < 1) {
      return;
    }

    let newX;
    let newY;
    let shouldChange = false;

    if (this.inertiaTx < x[0]) {
      newX = x[0];
      shouldChange = true;
    } else if (this.inertiaTx > x[1]) {
      newX = x[1];
      shouldChange = true;
    } else {
      newX = this.inertiaTx;
    }

    if (this.inertiaTy < y[0]) {
      newY = y[0];
      shouldChange = true;
    } else if (this.inertiaTy > y[1]) {
      newY = y[1];
      shouldChange = true;
    } else {
      newY = this.inertiaTy;
    }

    if (shouldChange) {
      this.inertiaSetTargetPosition(newX, newY);
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  inertiaApplyCurrentPosition(x, y) {}

  // eslint-disable-next-line class-methods-use-this
  inertiaStopped() {}
}

export default Inertia;
