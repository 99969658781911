import { detailsSetSharedAs as detailsSetSharedAsPureAction } from '../store/details/actions';
import { errorCannotLoadData as errorCannotLoadDataPureAction } from '../store/error/actions';
import {
  loadingNextStage as loadingNextStagePureAction,
  loadingInitialize as loadingInitializePureAction,
  loadingCompleted as loadingCompletedPureAction,
} from '../store/loading/actions';
import store from '../store/store';
import i18n from 'i18next';
import toast from 'react-hot-toast';

function bind(actionCreator, dispatch) {
  return function (...args) {
    return dispatch(actionCreator(...args));
  };
}

export const errorCannotLoadData = bind(errorCannotLoadDataPureAction, store.dispatch);

export const loadingNextStage = bind(loadingNextStagePureAction, store.dispatch);

export const loadingInitialize = bind(loadingInitializePureAction, store.dispatch);

export const loadingCompleted = bind(loadingCompletedPureAction, store.dispatch);

export const detailsSetSharedAs = bind(detailsSetSharedAsPureAction, store.dispatch);

export const showToast = (txt) => {
  toast(i18n.t(txt));
};
