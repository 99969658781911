/* global appEnc */
import { IS_PRODUCTION } from '../../../settings';
import CryptoJS from 'crypto-js';

const obfuscate = (data) => {
  if (IS_PRODUCTION) {
    return appEnc(CryptoJS.AES.decrypt, CryptoJS.enc.Utf8, data);
  }

  return data;
};

export default obfuscate;
