/* eslint-disable @typescript-eslint/default-param-last */
import { ERROR_UNSUPPORTED_DEVICE, ERROR_NOT_FOUND, ERROR_UNAUTHORIZED, ERROR_CANNOT_LOAD_DATA } from './actions';

const initialState = {
  type: undefined,
  details: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_UNSUPPORTED_DEVICE:
    case ERROR_NOT_FOUND:
    case ERROR_UNAUTHORIZED:
    case ERROR_CANNOT_LOAD_DATA:
      return {
        type: action.type,
        details: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
