import { CONTROLLING_CHANGE_BUCKETS, CONTROLLING_SELECT_BUCKET } from './controlling/actions';
import {
  controllingDoDefaultPreset,
  controllingDoDefaultTools,
  controllingChangeMediumWhenBucketSelected,
  controllingChangeMediumWhenBucketsChanged,
} from './controlling/reactions';
import { PRESETS_SET } from './presets/actions';

const reactions = {
  [PRESETS_SET]: [controllingDoDefaultTools, controllingDoDefaultPreset],
  [CONTROLLING_SELECT_BUCKET]: [controllingChangeMediumWhenBucketSelected],
  [CONTROLLING_CHANGE_BUCKETS]: [controllingChangeMediumWhenBucketsChanged],
};

export default reactions;
