import styles from './Tool.module.scss';
import getPosition, { IS_CORRECT, isTouchScreenDevice } from './helpers/getPosition';

const BACKGROUND_SIZE = 38;

class Paint {
  constructor(parent, index, moveInstance) {
    this.index = index;
    this.moveInstance = moveInstance;

    this.paint = document.createElement('div');
    this.paint.classList.add(styles.paint);

    this.splat = document.createElement('div');
    this.splat.classList.add(styles.splat);
    this.paint.appendChild(this.splat);

    this.border = document.createElement('div');
    this.border.classList.add(styles.border);
    this.paint.appendChild(this.border);

    parent.appendChild(this.paint);

    this.addEventListeners();
  }

  addEventListeners() {
    this.start = this.start.bind(this);
    this.move = this.move.bind(this);
    this.stop = this.stop.bind(this);

    if (isTouchScreenDevice) {
      this.paint.addEventListener('touchstart', this.start, false);
    } else {
      this.paint.addEventListener('mousedown', this.start, false);
    }
  }

  start(e) {
    e.preventDefault();
    e.stopPropagation();

    const position = getPosition(e);

    if (!position[IS_CORRECT]) {
      return;
    }

    this.moveInstance.start(position, this.index);

    if (isTouchScreenDevice) {
      this.paint.addEventListener('touchmove', this.move, false);
      this.paint.addEventListener('touchend', this.stop, false);
    } else {
      window.addEventListener('mousemove', this.move, true);
      window.addEventListener('mouseup', this.stop, true);
    }
  }

  move(e) {
    e.preventDefault();

    this.moveInstance.move(e);
  }

  stop(e) {
    e.preventDefault();
    e.stopPropagation();

    this.paint.removeEventListener('touchmove', this.move, false);
    this.paint.removeEventListener('touchend', this.stop, false);
    window.removeEventListener('mousemove', this.move, true);
    window.removeEventListener('mouseup', this.stop, true);

    this.moveInstance.stop();
  }

  setState(state) {
    this.state = state;

    if (state.controlling.selectedBucket === this.index) {
      this.paint.classList.add(styles.selected);
    }

    this.applyProperColors(state);
  }

  update(state) {
    if (state.controlling.buckets[this.index] !== this.state.controlling.buckets[this.index]) {
      this.applyProperColors(state);
    }

    if (state.controlling.selectedBucket !== this.state.controlling.selectedBucket) {
      this.paint.classList.remove(styles.selected);

      if (state.controlling.selectedBucket === this.index) {
        this.paint.classList.add(styles.selected);
      }
    }

    this.state = state;
  }

  applyProperColors(state) {
    const paintIndex = state.controlling.buckets[this.index];

    this.splat.style.backgroundPosition = `0 -${BACKGROUND_SIZE * paintIndex}px`;

    const borderColor = state.measures.colors[paintIndex].color;
    this.border.style.borderColor = borderColor;
  }
}

export default Paint;
