/* eslint-disable react-hooks/exhaustive-deps */
import Tool from './Tool';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const ToolBox = () => {
  const toolRef = useRef();
  const state = useSelector((s) => s);
  const { openHelper } = useSelector((s) => s.controlling);

  useEffect(() => {
    toolRef.current = new Tool(state);

    return () => {
      toolRef.current.destructor();

      delete toolRef.current;
    };
  }, []);

  useEffect(() => {
    if (toolRef.current) {
      toolRef.current.update(state);
    }
  }, [state]);

  useEffect(() => {
    if (openHelper) {
      toolRef.current.goToBasicPosition();
    }
  }, [openHelper]);

  return null;
};

export default ToolBox;
