import Splat from './Splat';
import { SplatArea } from './SplatArea';
import calculatePropertiesForPencil from './splat/calculatePropertiesForPencil';
import calculateSplatForPencil from './splat/calculateSplatForPencil';

class SplatPencil extends Splat {
  startingCoefficient = 1.0;

  update(settings) {
    this.settings = settings;

    this.properties = calculatePropertiesForPencil(this.settings);

    console.log('Pencil splat properties calculated');
  }

  splat(_pressure, simulationArea) {
    const splatSettings = calculateSplatForPencil(_pressure, this.startingCoefficient, this.properties, this.settings);

    let runSplatVelocityProgram = false;

    if (!simulationArea) {
      this.splatAreas.splice(0, 0, new SplatArea(this.getCurrentBrushArea(), this.frameNumber));
      simulationArea = this.getSimulationArea();

      runSplatVelocityProgram = true;
    }

    this.cleanGl();

    this.runSplatProgram(simulationArea, splatSettings.splatRadius, splatSettings.color, splatSettings.zThreshold);

    // skip simulation
    if (runSplatVelocityProgram) {
      this.runSplatVelocityProgram(
        simulationArea,
        splatSettings.splatRadius,
        splatSettings.splatVelocityScale,
        splatSettings.zThreshold
      );
    }

    return simulationArea;
  }
}

export default SplatPencil;
