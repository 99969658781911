const getShader = (gl, content, type) => {
  const shader = gl.createShader(type);

  if (shader) {
    gl.shaderSource(shader, content);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(shader) || 'Shader compilation failed.');
    }
  } else {
    throw new Error('We cannot create shader for current gl context.');
  }

  return shader;
};

const setAttributeLocations = (gl, program, attributes) => {
  attributes.forEach((attribute) => {
    program[attribute] = gl.getAttribLocation(program, attribute);
  });
};

const setUniformLocations = (gl, program, uniforms) => {
  uniforms.forEach((uniform) => {
    program[uniform] = gl.getUniformLocation(program, uniform);
  });
};

const createProgram = (gl, vertexShaderText, fragmentShaderText, attributes, uniforms) => {
  const vertexShader = getShader(gl, vertexShaderText, gl.VERTEX_SHADER);
  const fragmentShader = getShader(gl, fragmentShaderText, gl.FRAGMENT_SHADER);

  const program = gl.createProgram();

  // Attach the shaders to this program
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    throw new Error('Could not initialize shaders');
  }

  if (attributes) {
    setAttributeLocations(gl, program, attributes);
  }

  if (uniforms) {
    setUniformLocations(gl, program, uniforms);
  }

  gl.useProgram(null);

  return program;
};

export default createProgram;
