import clamp from './helpers/clamp';
import distance from './helpers/distance';
import precision from './helpers/precision';

const noop = () => {};

function debounce(func, timeout = 300) {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const options = {
  MAX_CANVAS_SIZE: 4096,
  NOMINAL_CROP: 0.9,
  MOVE_DISTANCE: 10,
  WHEEL_STEP: 200,
  PADDING: 40,
  MIN_SCALE: 0.2,
  MAX_SCALE: 2.0,
  RESIZE_DEBOUNCE_TIME: 500,
};

const prevent = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

class Viewport {
  zoom = 0;

  oldZoom = 0;

  referenceZoom = 0;

  translation = [0, 0];

  transformationStart = [0, 0];

  rectangleCenter = [0, 0];

  wheelPosition = 0;

  baseDistance = 0;

  isPainting = false;

  isTransforming = false;

  isTouched = false;

  isTranslating = false;

  isPen = false;

  isFinger = false;

  isMouse = false;

  isBlocked = false;

  constructor(canvas, w, h) {
    this.canvas = canvas;

    this.textureWidth = w;
    this.textureHeight = h;

    this.setHandlers(noop, noop, noop, noop);

    this.calculateCanvasSizes();

    this.calculateViewportScale();
    this.calculateCenters();

    this.editCanvas();
    this.addListeners();

    this.changeViewport();

    this.fitToWindow();

    this.toolBox = document.getElementById('tool-box');
    this.root = document.getElementById('root');
  }

  enableUi() {
    this.toolBox.style.pointerEvents = 'unset';
    this.root.style.pointerEvents = 'unset';
  }

  disableUi() {
    this.toolBox.style.pointerEvents = 'none';
    this.root.style.pointerEvents = 'none';
  }

  setHandlers(startMoving, moving, stopMoving, changeViewport) {
    this.handleStartMoving = startMoving;
    this.handleStopMoving = stopMoving;
    this.handleMoving = moving;

    if (changeViewport) {
      this.handleChangeViewport = changeViewport;
    }
  }

  calculateCanvasSizes() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.canvasWidth = Math.min(width, options.MAX_CANVAS_SIZE);
    this.canvasHeight = Math.min(height, options.MAX_CANVAS_SIZE);
  }

  calculateViewportScale() {
    const nominalWidth = this.canvasWidth * options.NOMINAL_CROP;
    const nominalHeight = this.canvasHeight * options.NOMINAL_CROP;

    const scaleWidth = nominalWidth / this.textureWidth;
    const scaleHeight = nominalHeight / this.textureHeight;

    this.viewportScale = Math.min(scaleWidth, scaleHeight);

    this.maxZoom = 2 * Math.log2(options.MAX_SCALE / this.viewportScale);
    this.minZoom = 2 * Math.log2(Math.min(options.MIN_SCALE, this.viewportScale) / this.viewportScale);

    this.translation = [0, 0];
  }

  calculateCenters() {
    this.rectangleCenter = [this.canvasWidth / 2, this.canvasHeight / 2];
    this.transformationStart = [...this.rectangleCenter];
  }

  editCanvas() {
    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;
  }

  getCanvas() {
    return this.canvas;
  }

  addListeners() {
    this.onResize = this.onResize.bind(this);

    const resize = debounce(this.onResize, options.RESIZE_DEBOUNCE_TIME);

    this.onWheel = this.onWheel.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);

    window.addEventListener('resize', resize, false);

    this.canvas.addEventListener('wheel', this.onWheel, false);

    this.canvas.addEventListener('pointerdown', this.onPointerDown, false);
    this.canvas.addEventListener('pointerup', this.onPointerUp, false);

    this.canvas.addEventListener('touchstart', this.onTouchStart, false);
    this.canvas.addEventListener('touchend', this.onTouchEnd, false);

    this.canvas.addEventListener('mousemove', prevent);
    this.canvas.addEventListener('mousedown', prevent);
    document.addEventListener('mouseup', prevent);
    this.canvas.addEventListener('mouseover', prevent);
  }

  changeViewport(eventFromWheel = false) {
    this.calculateViewport();

    if (eventFromWheel) {
      this.normalizePosition();
    }

    this.handleChangeViewport();
  }

  onWheel(e) {
    if (this.isPainting || this.isTranslating) {
      return;
    }

    this.wheelPosition -= e.deltaY;
    this.transformationStart = [e.clientX, this.canvasHeight - e.clientY];

    this.changeZoomByWheel();
  }

  changeZoomByWheel() {
    const zoom = this.wheelPosition / options.WHEEL_STEP;

    if (zoom >= this.maxZoom && this.maxWheelPosition === undefined) {
      this.maxWheelPosition = this.wheelPosition;
    }

    if (zoom <= this.minZoom && this.minWheelPosition === undefined) {
      this.minWheelPosition = this.wheelPosition;
    }

    if (this.minWheelPosition !== undefined) {
      this.wheelPosition = Math.max(this.wheelPosition, this.minWheelPosition);
    }

    if (this.maxWheelPosition !== undefined) {
      this.wheelPosition = Math.min(this.wheelPosition, this.maxWheelPosition);
    }

    this.zoom = clamp(this.minZoom, this.maxZoom, zoom);

    this.changeViewport(true);
  }

  onPointerDown(e) {
    this.disableUi();

    if (e.pointerType === 'touch') {
      return;
    }

    e.preventDefault();

    this.isPen = true;
    this.isFinger = false;
    this.isMouse = true;

    if (e.shiftKey) {
      this.isTranslating = true;

      this.transformationStart = [e.clientX, this.canvasHeight - e.clientY];
      this.translation = [0, 0];
    } else {
      this.painting(e, true);

      this.isPainting = true;
    }

    this.canvas.addEventListener('pointermove', this.onPointerMove, false);
  }

  onPointerUp(e) {
    this.enableUi();

    e.preventDefault();

    if (this.isFinger) {
      return;
    }

    if (this.isPainting) {
      this.stopPainting(e, true);
    }

    if (this.isTranslating) {
      this.normalizePosition();
    }

    this.isTranslating = false;
    this.isPainting = false;

    this.canvas.removeEventListener('pointermove', this.onPointerMove, false);
  }

  onPointerMove(e) {
    e.preventDefault();

    if (this.isTranslating) {
      this.translation = [
        e.clientX - this.transformationStart[0],
        this.canvasHeight - e.clientY - this.transformationStart[1],
      ];

      this.changeViewport();
    } else {
      this.painting(e, true);
    }
  }

  onResize() {
    console.log('debounced');

    this.calculateCanvasSizes();

    this.canvas.width = this.canvasWidth;
    this.canvas.height = this.canvasHeight;

    this.calculateViewportScale();

    this.setDefaultZoom();
  }

  reset(zoom = 0) {
    this.zoom = zoom;

    this.calculateCenters();

    this.translation = [0, 0];
    this.maxWheelPosition = undefined;
    this.minWheelPosition = undefined;
    this.wheelPosition = precision(zoom, 2) * options.WHEEL_STEP;
  }

  setDefaultZoom() {
    this.reset();
    this.changeViewport();
  }

  fitToNaturalScale() {
    this.reset(2 * Math.log2(1.0 / this.viewportScale));
    this.changeViewport();
  }

  fitToWindow() {
    this.reset(0);
    this.changeViewport();
  }

  transforming(event1, event2) {
    if (!this.isTransforming) {
      this.baseDistance = distance(event1, event2);

      this.transformationStart = [
        (event1.clientX + event2.clientX) / 2,
        this.canvasHeight - (event1.clientY + event2.clientY) / 2,
      ];

      this.translation = [0, 0];

      this.referenceZoom = this.zoom;
    } else {
      const newDistance = distance(event1, event2);

      if (this.baseDistance > 0) {
        const step = (this.baseDistance - newDistance) / 100;

        const newZoom = this.referenceZoom - step;
        this.zoom = Math.max(Math.min(newZoom, this.maxZoom), this.minZoom);

        const currentCenter = [
          (event1.clientX + event2.clientX) / 2,
          this.canvasHeight - (event1.clientY + event2.clientY) / 2,
        ];

        this.translation = [
          currentCenter[0] - this.transformationStart[0],
          currentCenter[1] - this.transformationStart[1],
        ];

        this.changeViewport();
      }
    }
  }

  onTouchStart(e) {
    this.disableUi();

    e.preventDefault();

    const touch = e.targetTouches[0];
    const type = touch.touchType;

    this.isPen = false;
    this.isFinger = false;
    this.isMouse = false;

    if (type === 'direct' || type === undefined) {
      this.isFinger = true;

      this.canvas.addEventListener('touchmove', this.onTouchMove, false);
    }
  }

  onTouchMove(e) {
    e.preventDefault();

    if (this.isBlocked) {
      return;
    }

    const howManyFingers = e.targetTouches.length;

    if (!this.isTouched) {
      this.touches = e.targetTouches;

      this.isTouched = true;
    }

    if (howManyFingers === 1) {
      if (!this.isTransforming && this.touches) {
        const dist = distance(e.targetTouches[0], this.touches[0]);

        if (dist > options.MOVE_DISTANCE || this.isPainting) {
          this.painting(e.targetTouches[0]);

          this.isPainting = true;
        }
      }
    } else if (howManyFingers === 2) {
      if (!this.isPainting) {
        this.transforming(e.targetTouches[0], e.targetTouches[1]);

        this.isTransforming = true;
      } else {
        this.stopPainting(e.targetTouches[0]);

        this.isBlocked = true;
      }
    }
  }

  onTouchEnd(e) {
    this.enableUi();

    e.preventDefault();

    if (!this.isFinger) {
      return;
    }

    if (e.targetTouches.length === 0) {
      if (this.isPainting) {
        this.stopPainting(e.changedTouches[0]);
      }

      if (this.isTransforming) {
        this.normalizePosition();
      }

      this.isBlocked = false;
      this.isTouched = false;
      this.isPainting = false;
      this.isTransforming = false;

      this.canvas.removeEventListener('touchmove', this.onTouchMove, false);
    } else {
      this.isBlocked = true;
    }
  }

  calculateViewport() {
    const oldScale = this.viewportScale * Math.pow(2, this.oldZoom / 2);
    const scale = this.viewportScale * Math.pow(2, this.zoom / 2);

    const deltaScale = scale / oldScale;

    const w = this.textureWidth * scale;
    const h = this.textureHeight * scale;

    this.rectangleCenter[0] =
      (this.rectangleCenter[0] - this.transformationStart[0]) * deltaScale + this.transformationStart[0];
    this.rectangleCenter[1] =
      (this.rectangleCenter[1] - this.transformationStart[1]) * deltaScale + this.transformationStart[1];

    const t = this.rectangleCenter[1] - h / 2;
    const l = this.rectangleCenter[0] - w / 2;

    this.viewport = {
      width: w,
      height: h,
      top: t + this.translation[1],
      left: l + this.translation[0],
    };

    this.oldZoom = this.zoom;

    this.boundViewport();

    this.calculateXYScales();
  }

  calculateXYScales() {
    this.positionScaleX = this.textureWidth / this.viewport.width;
    this.positionScaleY = this.textureHeight / this.viewport.height;
  }

  boundViewport() {
    this.viewport.left = Math.max(
      Math.min(this.viewport.left, this.canvasWidth - options.PADDING),
      options.PADDING - this.viewport.width
    );

    this.viewport.top = Math.max(
      Math.min(this.viewport.top, this.canvasHeight - options.PADDING),
      options.PADDING - this.viewport.height
    );
  }

  normalizePosition() {
    this.translation = [0, 0];
    this.rectangleCenter[0] = this.viewport.left + this.viewport.width / 2;
    this.rectangleCenter[1] = this.viewport.top + this.viewport.height / 2;
  }

  ///////////////////// TODO

  painting(event, getPressureFromEvent = false) {
    const coords = this.getCoordinatesInTextureViewport(event.clientX, event.clientY);

    if (!this.isPainting) {
      this.handleStartMoving(coords, getPressureFromEvent ? event.pressure : 1.0);
    } else {
      this.handleMoving(coords, getPressureFromEvent ? event.pressure : 1.0);
    }
  }

  stopPainting(event, getPressureFromEvent = false) {
    const coords = this.getCoordinatesInTextureViewport(event.clientX, event.clientY);

    this.handleStopMoving(coords, getPressureFromEvent ? event.pressure : 1.0);
  }

  getCoordinatesInTextureViewport(x, y) {
    const localX = (x - this.viewport.left) * this.positionScaleX;
    const localY = (this.canvasHeight - y - this.viewport.top) * this.positionScaleY;

    return [localX, localY];
  }
}

export default Viewport;
