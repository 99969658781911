import { controllingSetDefaultPreset } from '../../store/controlling/actions';
import Drawer from '../Drawer';
import icons from '../Menu/icons';
import styles from './Tools.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const sizes = {
  chalk: ['S', 'L'],
  hog: ['S', 'M', 'L'],
  sobol: ['S', 'M', 'L'],
  rag: ['S', 'L'],
};

const Tools = ({ type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const presetNames = useSelector((s) =>
    Object.keys(s.presets || {})
      .filter((n) => n.indexOf(type) >= 0)
      .sort()
  );
  const selectedPreset = useSelector((s) =>
    s.controlling.defaultPresets && s.controlling.presetType
      ? s.controlling.defaultPresets[s.controlling.presetType]
      : undefined
  );

  return (
    <Drawer>
      <div className={styles.list}>
        <ul>
          {presetNames.map((preset, index) => {
            const name = `${t(type)} ${sizes[type][index]}`;

            return (
              <li
                key={index}
                className={cn(styles.item, selectedPreset === preset && styles.selected)}
                onClick={() => {
                  dispatch(controllingSetDefaultPreset(type, preset));
                }}
              >
                <div className={styles.icon}>
                  <img className={styles[type]} src={icons[preset]} alt={name} />
                </div>

                <div className={styles.about}>
                  <div className={styles.title}>{name}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Drawer>
  );
};

export default Tools;
