const calculateSettings = (state) => {
  const presetKey = state.controlling.defaultPresets[state.controlling.presetType];
  const preset = state.presets[presetKey];

  const paintIndex = state.controlling.buckets[state.controlling.selectedBucket];

  console.log('...calculating settings...');

  return {
    ...preset,
    medium: state.painting.medium,
    paintIndex,
    ...state.properties.paint,
    ...state.properties.fluidity,
    ...state.properties.light,
  };
};

export default calculateSettings;
