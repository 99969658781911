import Rectangle from './Rectangle';
import { SplatArea, SPEED_PADDING, SPLAT_PADDING } from './SplatArea';
import assertRectangle from './helpers/assertRectangle';
import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';
import calculatePropertiesForBrush from './splat/calculatePropertiesForBrush';
import calculateSplatForBrush from './splat/calculateSplatForBrush';

/*
 * TODO: make splatAreas = [] when simulation is off.
 */
class Splat {
  startingCoefficient = 1.0;

  constructor(gl, shaders, textureManager, brush) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;
    this.brush = brush;

    this.frameNumber = 0;
    this.splatAreas = [];

    this.canvasRectangle = new Rectangle(0, 0, this.textureManager.width, this.textureManager.height);

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.splatProgram = createProgram(
      this.gl,
      this.shaders.splatVert,
      this.shaders.splatFrag,
      ['a_splatCoordinates'],
      [
        'u_paintingDimensions',
        'u_paintingPosition',
        'u_positionsTexture',
        'u_previousPositionsTexture',
        'u_pencilTexture',
        'u_backgroundTexture',
        'u_splatRadius',
        'u_zThreshold',
        'u_splatColor',

        'u_removeBrushByPencil',
        'u_sampleToGetMedium',
        'u_samplePencilTexture',
        'u_paintingResolution',
      ]
    );

    this.velocitySplatProgram = createProgram(
      this.gl,
      '#define VELOCITY \n' + this.shaders.splatVert,
      '#define VELOCITY \n' + this.shaders.splatFrag,
      ['a_splatCoordinates'],
      [
        'u_paintingDimensions',
        'u_paintingPosition',
        'u_positionsTexture',
        'u_previousPositionsTexture',
        'u_splatRadius',
        'u_zThreshold',

        'u_previousVelocitiesTexture',
        'u_velocitiesTexture',
        'u_velocityScale',
      ]
    );

    this.addVectorsProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.addVectorsFrag,
      ['a_position'],
      ['u_dissipation', 'u_resolution', 'u_velocityTexture', 'u_inputTexture', 'u_min', 'u_max', 'u_deltaTime']
    );

    this.divergenceProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.divergenceFrag,
      ['a_position'],
      ['u_resolution', 'u_velocityTexture']
    );

    this.jacobiProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.jacobiFrag,
      ['a_position'],
      ['u_resolution', 'u_divergenceTexture', 'u_pressureTexture']
    );

    this.subtractProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.subtractFrag,
      ['a_position'],
      ['u_resolution', 'u_pressureTexture', 'u_velocityTexture']
    );
  }

  getSimulationArea() {
    const simulationArea = this.splatAreas[0].rectangle.clone(); // start with the first rectangle

    for (let i = 1; i < this.splatAreas.length; ++i) {
      const area = this.splatAreas[i].rectangle.clone();

      simulationArea.includeRectangle(area);
    }

    simulationArea.round();
    simulationArea.intersectRectangle(this.canvasRectangle);

    return simulationArea;
  }

  update(settings) {
    this.settings = settings;

    this.properties = calculatePropertiesForBrush(this.settings);

    console.log('Brush splat properties calculated');
  }

  cleanGl() {
    // Clean
    this.gl.disable(this.gl.SCISSOR_TEST);
    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
  }

  getCurrentBrushArea() {
    let brushPadding = Math.ceil(this.settings.brushScale * SPLAT_PADDING);
    brushPadding += Math.ceil(this.brush.getFilteredSpeed() * SPEED_PADDING);

    const size = 2 * brushPadding;

    const area = new Rectangle(this.brush.positionX - brushPadding, this.brush.positionY - brushPadding, size, size);
    area.round();

    return area;
  }

  splat(_pressure) {
    const splatSettings = calculateSplatForBrush(_pressure, this.startingCoefficient, this.properties, this.settings);

    this.splatAreas.splice(0, 0, new SplatArea(this.getCurrentBrushArea(), this.frameNumber));
    const simulationArea = this.getSimulationArea();

    this.cleanGl();

    this.runSplatProgram(simulationArea, splatSettings.splatRadius, splatSettings.color, splatSettings.zThreshold);

    this.runSplatVelocityProgram(
      simulationArea,
      splatSettings.splatRadius,
      splatSettings.splatVelocityScale,
      splatSettings.zThreshold
    );

    return simulationArea;
  }

  runSplatProgram(simulationArea, splatRadius, splatColor, zThreshold) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.useProgram(this.splatProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    assertRectangle(simulationArea);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.BLEND);

    if (this.properties.subtraction) {
      this.gl.blendEquation(this.gl.FUNC_REVERSE_SUBTRACT);
      this.gl.blendFuncSeparate(
        this.gl.ONE_MINUS_SRC_COLOR,
        this.gl.ONE_MINUS_SRC_ALPHA,
        this.gl.ZERO,
        this.gl.ONE_MINUS_SRC_ALPHA
      );
    } else {
      this.gl.blendEquation(this.gl.FUNC_ADD);
      this.gl.blendFuncSeparate(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA, this.gl.ONE, this.gl.ONE);
    }

    this.gl.uniform2f(this.splatProgram.u_paintingDimensions, simulationArea.width, simulationArea.height);
    this.gl.uniform2f(this.splatProgram.u_paintingPosition, simulationArea.left, simulationArea.bottom);
    this.gl.uniform1f(this.splatProgram.u_splatRadius, splatRadius);
    this.gl.uniform4fv(this.splatProgram.u_splatColor, splatColor);
    this.gl.uniform1f(this.splatProgram.u_zThreshold, zThreshold);

    this.gl.uniform1i(this.splatProgram.u_removeBrushByPencil, this.properties.removeBrushByPencil ? 1 : 0);
    this.gl.uniform1i(this.splatProgram.u_sampleToGetMedium, this.properties.sampleToGetMedium ? 1 : 0);
    this.gl.uniform1i(this.splatProgram.u_samplePencilTexture, this.properties.samplePencilTexture ? 1 : 0);
    this.gl.uniform2f(this.splatProgram.u_paintingResolution, this.textureManager.width, this.textureManager.height);

    // Activate textures
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPositionsTexture);
    this.gl.uniform1i(this.splatProgram.u_positionsTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPreviousPositionsTexture);
    this.gl.uniform1i(this.splatProgram.u_previousPositionsTexture, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatBrushPaintTempTexture);
    this.gl.uniform1i(this.splatProgram.u_backgroundTexture, 2);

    if (this.properties.samplePencilTexture) {
      this.gl.activeTexture(this.gl.TEXTURE3);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.samplePencilTexture);
      this.gl.uniform1i(this.splatProgram.u_pencilTexture, 3);
    }

    // buffers
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.brush.splatCoordinatesBuffer);
    this.gl.vertexAttribPointer(this.splatProgram.a_splatCoordinates, 4, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.splatProgram.a_splatCoordinates);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.brush.splatIndexBuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[this.properties.textures.paintTexture],
      0
    );

    this.gl.drawElements(this.gl.TRIANGLES, this.brush.splatIndexCount, this.gl.UNSIGNED_SHORT, 0);

    this.cleanGl();
  }

  runSplatVelocityProgram(simulationArea, splatRadius, velocityScale, zThreshold) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    assertRectangle(simulationArea);

    this.gl.useProgram(this.velocitySplatProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.BLEND);
    this.gl.blendEquation(this.gl.FUNC_ADD);
    this.gl.blendFuncSeparate(this.gl.ONE, this.gl.ONE, this.gl.ZERO, this.gl.ZERO);

    this.gl.uniform2f(this.velocitySplatProgram.u_paintingDimensions, simulationArea.width, simulationArea.height);
    this.gl.uniform2f(this.velocitySplatProgram.u_paintingPosition, simulationArea.left, simulationArea.bottom);
    this.gl.uniform1f(this.velocitySplatProgram.u_splatRadius, splatRadius);
    this.gl.uniform1f(this.velocitySplatProgram.u_zThreshold, zThreshold);
    this.gl.uniform1f(this.velocitySplatProgram.u_velocityScale, velocityScale);

    // Activate textures
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPositionsTexture);
    this.gl.uniform1i(this.velocitySplatProgram.u_positionsTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPreviousPositionsTexture);
    this.gl.uniform1i(this.velocitySplatProgram.u_previousPositionsTexture, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushVelocitiesTexture);
    this.gl.uniform1i(this.velocitySplatProgram.u_velocitiesTexture, 2);

    this.gl.activeTexture(this.gl.TEXTURE3);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPreviousVelocitiesTexture);
    this.gl.uniform1i(this.velocitySplatProgram.u_previousVelocitiesTexture, 3);

    // buffers
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.brush.splatCoordinatesBuffer);
    this.gl.vertexAttribPointer(this.velocitySplatProgram.a_splatCoordinates, 4, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.velocitySplatProgram.a_splatCoordinates);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.brush.splatIndexBuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[this.properties.textures.velocityTexture],
      0
    );

    this.gl.drawElements(this.gl.TRIANGLES, this.brush.splatIndexCount, this.gl.UNSIGNED_SHORT, 0);

    this.cleanGl();
  }

  runDivergenceProgram(simulationArea) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    assertRectangle(simulationArea);

    this.gl.useProgram(this.divergenceProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.uniform2f(this.divergenceProgram.u_resolution, this.textureManager.width, this.textureManager.height);

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[this.properties.textures.velocityTexture]);
    this.gl.uniform1i(this.divergenceProgram.u_velocityTexture, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.divergenceProgram.a_position);
    this.gl.vertexAttribPointer(this.divergenceProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[this.properties.textures.divergenceTexture],
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.cleanGl();
  }

  runJacobiProgram(simulationArea) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    assertRectangle(simulationArea);

    this.gl.useProgram(this.jacobiProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.uniform2f(this.jacobiProgram.u_resolution, this.textureManager.width, this.textureManager.height);

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[this.properties.textures.divergenceTexture]);
    this.gl.uniform1i(this.jacobiProgram.u_divergenceTexture, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.jacobiProgram.a_position);
    this.gl.vertexAttribPointer(this.jacobiProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[this.properties.textures.pressureTexture],
      0
    );

    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    for (let i = 0; i < this.settings.pressureJacobiIterations; ++i) {
      this.gl.framebufferTexture2D(
        this.gl.FRAMEBUFFER,
        this.gl.COLOR_ATTACHMENT0,
        this.gl.TEXTURE_2D,
        this.textureManager[this.properties.textures.pressureTempTexture],
        0
      );

      // Activate texture
      this.gl.activeTexture(this.gl.TEXTURE1);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[this.properties.textures.pressureTexture]);
      this.gl.uniform1i(this.jacobiProgram.u_pressureTexture, 1);

      this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

      this.textureManager.swap(this.properties.textures.pressureTexture, this.properties.textures.pressureTempTexture);
    }

    this.cleanGl();
  }

  runSubtractProgram(simulationArea) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    assertRectangle(simulationArea);

    this.gl.useProgram(this.subtractProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.uniform2f(this.subtractProgram.u_resolution, this.textureManager.width, this.textureManager.height);

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[this.properties.textures.pressureTexture]);
    this.gl.uniform1i(this.subtractProgram.u_pressureTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[this.properties.textures.velocityTexture]);
    this.gl.uniform1i(this.subtractProgram.u_velocityTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.subtractProgram.a_position);
    this.gl.vertexAttribPointer(this.subtractProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[this.properties.textures.velocityTempTexture],
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.textureManager.swap(this.properties.textures.velocityTexture, this.properties.textures.velocityTempTexture);

    this.cleanGl();
  }

  addVectors(velocityTexture, dataTexture, targetTexture, deltaTime, dissipation, simulationArea) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    assertRectangle(simulationArea);

    this.gl.useProgram(this.addVectorsProgram);
    this.gl.viewport(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(simulationArea.left, simulationArea.bottom, simulationArea.width, simulationArea.height);

    this.gl.uniform1f(this.addVectorsProgram.u_dissipation, dissipation);
    this.gl.uniform1f(this.addVectorsProgram.u_deltaTime, deltaTime);
    this.gl.uniform2f(this.addVectorsProgram.u_resolution, this.textureManager.width, this.textureManager.height);
    this.gl.uniform2f(this.addVectorsProgram.u_min, simulationArea.left, simulationArea.bottom);
    this.gl.uniform2f(this.addVectorsProgram.u_max, simulationArea.getRight(), simulationArea.getTop());

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, velocityTexture);
    this.gl.uniform1i(this.addVectorsProgram.u_velocityTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, dataTexture);
    this.gl.uniform1i(this.addVectorsProgram.u_inputTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.addVectorsProgram.a_position);
    this.gl.vertexAttribPointer(this.addVectorsProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, targetTexture, 0);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.cleanGl();
  }

  simulate() {
    if (this.splatAreas.length === 0) {
      return undefined;
    }

    const simulationArea = this.getSimulationArea();

    this.runDivergenceProgram(simulationArea);
    this.runJacobiProgram(simulationArea);
    this.runSubtractProgram(simulationArea);

    const simulate = !(this.settings.fluidityIsOn === 0 || this.settings.useAsRag);

    if (simulate) {
      // advect paint
      this.addVectors(
        this.textureManager[this.properties.textures.velocityTexture],
        this.textureManager[this.properties.textures.paintTexture],
        this.textureManager[this.properties.textures.paintTempTexture],
        this.properties.deltaTime,
        1.0,
        simulationArea
      );

      this.textureManager.swap(this.properties.textures.paintTexture, this.properties.textures.paintTempTexture);
    } else {
      this.textureManager.copy(this.properties.textures.paintTexture, this.properties.textures.paintTempTexture);
    }

    // advect velocity
    this.addVectors(
      this.textureManager[this.properties.textures.velocityTexture],
      this.textureManager[this.properties.textures.velocityTexture],
      this.textureManager[this.properties.textures.velocityTempTexture],
      this.properties.deltaTime,
      this.properties.fluidity,
      simulationArea
    );

    this.textureManager.swap(this.properties.textures.velocityTexture, this.properties.textures.velocityTempTexture);

    this.updateAreas();

    if (this.splatAreas.length === 0) {
      // if we finished simulating on this step
      console.log('clear');

      this.textureManager.clearByNames([
        this.properties.textures.velocityTexture,
        this.properties.textures.velocityTempTexture,
      ]); // clear all velocity textures

      // this.frameNumber = 0;
    }

    return simulationArea;
  }

  updateAreas() {
    // remove all of the splat areas we no longer need to simulate
    let i = this.splatAreas.length;

    this.frameNumber += 1;

    while (i--) {
      if (this.frameNumber - this.splatAreas[i].frameNumber > this.settings.framesToSimulate) {
        this.splatAreas.splice(i, 1);
      }
    }
  }
}

export default Splat;
