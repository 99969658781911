import { calculateHex } from '../../../theory';
import { setMedium } from '../actions';
import settings from '../settings';
import Gradient from './Gradient';
import styles from './Medium.module.scss';
import Preview from './Preview';
import Range from './Range';
import MediumStretch from './Stretch';

class Medium {
  value = 0;

  constructor(state, parent) {
    this.state = state;
    this.parent = parent;

    this.createDOM();

    this.previewValue = this.previewValue.bind(this);
    this.confirmValue = this.confirmValue.bind(this);

    // preview only
    this.preview = new Preview(this.handler);
    this.gradient = new Gradient(state, this.container);

    // active
    this.mediumStretch = new MediumStretch(this.parent, this.handler, this.previewValue, this.confirmValue);
    this.range = new Range(this.container, this.previewValue, this.confirmValue);

    this.previewValue(this.state.painting.medium, true);

    this.preview.updateMainColor(state);
  }

  createDOM() {
    const size = `${settings.colorSize}px`;

    this.container = document.createElement('div');
    this.container.className = styles.medium;
    this.container.style.width = size;
    this.container.style.height = size;

    this.handler = document.createElement('div');
    this.handler.className = styles.handler;
    this.handler.style.width = size;
    this.handler.style.height = size;
    this.container.appendChild(this.handler);

    this.parent.appendChild(this.container);
  }

  updateByColor(value, forced = false) {
    const colorModel =
      this.state.measures.colors[this.state.controlling.buckets[this.state.controlling.selectedBucket]];

    const hexColor = calculateHex(
      colorModel.absorption.impasto,
      colorModel.absorption.glaze,
      this.state.measures.medium.absorption.nominal,
      colorModel.modelLimit,
      1 - value
    );

    this.preview.takeColor(hexColor);
    this.mediumStretch.takeColor(hexColor, forced);
    this.mediumStretch.setTargetColor(colorModel.color);
  }

  setPosition(position) {
    const [x, y] = position;
    const radius = settings.colorSize / 2;

    const centerX = x + radius + settings.mediumLeftPadding;
    const centerY = y + settings.wrapperHeight / 2;

    const containerX = x + settings.mediumLeftPadding;
    const containerY = y + settings.wrapperHeight / 2 - radius;

    this.range.setCenterOfParent([centerX, centerY]);
    this.mediumStretch.setPosition(containerX, containerY);
    this.mediumStretch.setCenter(centerX, centerY);
  }

  update(state) {
    this.gradient.update(state);

    this.mediumStretch.setCurrentValue(state.painting.medium);

    const shouldUpdatePreview =
      state.painting.medium !== this.state.painting.medium ||
      state.controlling.buckets !== this.state.controlling.buckets ||
      state.controlling.selectedBucket !== this.state.controlling.selectedBucket;

    this.state = state;

    if (shouldUpdatePreview) {
      this.previewValue(state.painting.medium, true);

      this.preview.updateMainColor(state);
    }
  }

  confirmValue() {
    setMedium(this.value);
  }

  previewValue(value, forced = false) {
    if ((value >= 0 && value !== this.value) || forced) {
      this.range.takeValue(value);

      this.updateByColor(value, forced);

      this.value = value;
    }
  }
}

export default Medium;
