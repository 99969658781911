import Context from './Context';
import ImageLoader from './ImageLoader';
import Painting from './Painting';
import Saver from './Saver';
import TextureManager from './TextureManager';
import Viewport from './Viewport';
import { errorCannotLoadData, loadingNextStage } from './actions';
import calculateTextureSizes from './helpers/calculateTextureSizes';
import cutData from './helpers/cutData';
import fillArray from './helpers/fillArray';
import urlForImage from './helpers/urlForImage';

const MIN_TIME_TO_SHOW_LOADING = 400;

const MAX_INDEX_OF_REFLECTANCE_TEXTURE = 14;

class Engine {
  initialized = false;

  startedInitialization = false;

  constructor(shaders, measures) {
    this.shaders = shaders;
    this.data = cutData(measures);

    this.imageLoader = new ImageLoader();

    this.now = Date.now();

    this.createCanvas();
    this.bindMethods();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvas.classList.add('glaze-canvas');
    document.body.appendChild(this.canvas);
  }

  bindMethods() {
    // this.clearWetScreen = this.clearWetScreen.bind(this);
    // this.dry = this.dry.bind(this);
    // this.fitToNaturalScale = this.fitToNaturalScale.bind(this);
    // this.makeSnapshot = this.makeSnapshot.bind(this);
    this.fitToScreen = this.fitToScreen.bind(this);
    this.save = this.save.bind(this);
    this.share = this.share.bind(this);
  }

  update(state) {
    this.state = state;

    const paintIndex = state.controlling.buckets[state.controlling.selectedBucket];

    if (this.initialized) {
      if (paintIndex !== this.paintIndex) {
        this.dry();
      }

      this.painting.update(this.state);
    } else {
      if (!this.startedInitialization) {
        this.initialize();
      }
    }

    this.paintIndex = paintIndex;
  }

  initialize() {
    this.startedInitialization = true;

    this.loadPaintingImages();
  }

  setError() {
    setTimeout(() => {
      errorCannotLoadData();
    }, 2000);
  }

  loadPaintingImages() {
    const id = this.state.details.id;
    const type = this.state.details.canvasType ? 'desk' : 'canvas';
    const isCompany = this.state.details.isCompany;

    const surfaceImage = ['surface', urlForImage(id, 1, undefined, false, isCompany)];
    const pencilSplatImage = ['pencilSplat', urlForImage(id, 15, undefined, false, isCompany)];

    this.imageLoader.load(
      [surfaceImage, pencilSplatImage],
      () => {
        loadingNextStage();

        const images = fillArray(2, MAX_INDEX_OF_REFLECTANCE_TEXTURE).map((index) => [
          `reflectance${index - 2}`,
          urlForImage(id, index, undefined, false, isCompany),
        ]);

        this.imageLoader.load(
          images,
          () => {
            loadingNextStage();

            this.loadEffectImages();
          },
          () => {
            this.setError();
          },
          false
        );
      },
      () => {
        const surfaceImageForNewPainting = ['surface', urlForImage(undefined, 1, type, true, isCompany)];
        const pencilSplatImageForNewPainting = ['pencilSplat', urlForImage(undefined, 15, type, true, isCompany)];

        this.imageLoader.load(
          [surfaceImageForNewPainting, pencilSplatImageForNewPainting],
          () => {
            loadingNextStage();

            const imagesForNewPainting = fillArray(2, MAX_INDEX_OF_REFLECTANCE_TEXTURE).map((index) => [
              `reflectance${index - 2}`,
              urlForImage(undefined, index, type, true, isCompany),
            ]);

            this.imageLoader.load(
              imagesForNewPainting,
              () => {
                loadingNextStage();

                this.loadEffectImages();
              },
              () => {
                this.setError();
              },
              true
            );
          },
          () => {
            this.setError();
          },
          true
        );
      },
      false
    );
  }

  loadEffectImages() {
    const effectImages = [['pencil', urlForImage(undefined, 1, 'noise', true, false)]];

    this.imageLoader.load(
      effectImages,
      () => {
        loadingNextStage();

        this.loaded();
      },
      () => {
        this.setError();
      },
      true
    );
  }

  loaded() {
    setTimeout(() => {
      this.createApp();
    }, 50);
  }

  createApp() {
    const sizes = calculateTextureSizes(this.state.details.canvasWidth, this.state.details.canvasHeight);

    this.context = new Context(this.canvas, sizes[0], sizes[1]);
    this.viewport = new Viewport(this.canvas, sizes[0], sizes[1]);

    const gl = this.context.get();

    this.textureManager = new TextureManager(gl, this.shaders, this.imageLoader, sizes[0], sizes[1]);

    this.painting = new Painting(gl, this.shaders, this.viewport, this.textureManager, this.data);

    this.saver = new Saver(this.state.details, this.textureManager);

    this.markAsLoaded();

    this.initialized = true;
  }

  markAsLoaded() {
    const now = Date.now();
    const delta = now - this.now;

    let nextStep = 50;

    if (delta < MIN_TIME_TO_SHOW_LOADING) {
      nextStep = MIN_TIME_TO_SHOW_LOADING - delta;
    }

    setTimeout(() => {
      loadingNextStage();
    }, nextStep);
  }

  fitToScreen() {
    this.viewport.fitToWindow();
  }

  async save() {
    this.dry();

    this.painting.blocked = true;

    await this.saver.save();

    this.painting.blocked = false;
  }

  async share() {
    this.painting.blocked = true;

    await this.saver.share();

    this.painting.blocked = false;
  }

  dry() {
    this.painting.blocked = true;

    this.painting.dry();

    this.textureManager.swap('surfaceTexture', 'surfaceTempTexture');

    this.textureManager.copy('splatPencilPaintTexture', 'splatPencilPaintTempTexture');

    // clear
    this.textureManager.clearByNames([
      'splatBrushPaintTexture',
      'splatBrushPaintTempTexture',

      'splatPencilHelperTexture',
    ]);

    this.painting.blocked = false;

    this.painting.clear();
  }
}

export default Engine;
