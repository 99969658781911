import { adminInstance, apiInstance, appInstance } from './instances';

export const config = {
  method: 'get', // default
  responseType: 'blob',
  headers: {
    Authorization: undefined,
  },
};

export const setToken = (token) => {
  [adminInstance, apiInstance].forEach((instance) => {
    instance.interceptors.request.use(function (requestConfig) {
      requestConfig.headers = {
        ...requestConfig.headers,
        Authorization: `Bearer ${token}`,
      };

      return requestConfig;
    });
  });

  config.headers.Authorization = `Bearer ${token}`;
};

export const getDetails = (id) => {
  return adminInstance.get(`/painting/${id}`);
};

export const getPremiumStatus = () => {
  return adminInstance.get('Users/premiumStatus');
};

export const getTrialEndDate = () => {
  return adminInstance.get('Users/trialEndDate');
};

export const getSketchDetails = (id) => {
  return apiInstance.get(`sketches/${id}`);
};

export const getMeasures = (raw = true) => {
  return appInstance.get(`${raw ? 'raw' : 'enc'}/data/measures.json`);
};

export const getPresets = () => {
  return apiInstance.get(`/settings/list`);
};

export const createDescription = (description) => {
  return apiInstance.post('/api/painting/create', description);
};

export const saveTexture = (id, type, formData) => {
  return adminInstance.put(`/painting/${id}/${type}`, formData, {
    contentType: 'multipart/form-data',
  });
};

export const savePreview = (id, type, formData) => {
  return apiInstance.put(`/api/painting/${id}/${type}`, formData, {
    contentType: 'multipart/form-data',
  });
};
