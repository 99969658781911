import { DOMAIN, PREVIEW_URL } from '../../settings';
import styles from './Congratulations.module.scss';
import congratsSvg from './assets/congrats.svg';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Congratulations = ({ id }) => {
  const { companyId } = useSelector((s) => s.details);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <div className={styles.background}>
          <img src={congratsSvg} alt={t('Congratulations')} />
        </div>

        <div className={styles.content}>
          <h2>{t('Congratulations')}</h2>

          <div className={styles.text}>
            {t('You can see your work online.')}
            <br />
            {t('Scan QR code')}
          </div>

          <div className={styles.qrcode}>
            <QRCodeCanvas value={`${PREVIEW_URL}/${id}`} bgColor="#262626" fgColor="#fff" />
          </div>

          <div className={styles.or}>{t('or copy link below')}</div>

          <div className={styles.link}>
            https:{PREVIEW_URL}/{id}
          </div>

          <div className={styles.back}>
            <a href={`//${companyId}.${DOMAIN}`}>{t('Back to home')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
