export const CONTROLLING_SET_DEFAULT_PRESETS = 'CONTROLLING_SET_DEFAULT_PRESETS';
export const controllingSetDefaultPresets = (presets) => ({ type: CONTROLLING_SET_DEFAULT_PRESETS, payload: presets });

export const CONTROLLING_SET_PRESET_TYPE = 'CONTROLLING_SET_PRESET_TYPE';
export const controllingSetPresetType = (name) => ({ type: CONTROLLING_SET_PRESET_TYPE, payload: name });

export const CONTROLLING_SET_OPEN_MENU = 'CONTROLLING_SET_OPEN_MENU';
export const controllingSetOpenMenu = (name) => ({ type: CONTROLLING_SET_OPEN_MENU, payload: name });

export const CONTROLLING_SET_OPEN_OPTIONS = 'CONTROLLING_SET_OPEN_OPTIONS';
export const controllingSetOpenOptions = (isOpen) => ({ type: CONTROLLING_SET_OPEN_OPTIONS, payload: isOpen });

export const CONTROLLING_SET_DEFAULT_PRESET = 'CONTROLLING_SET_DEFAULT_PRESET';
export const controllingSetDefaultPreset = (type, name) => ({
  type: CONTROLLING_SET_DEFAULT_PRESET,
  payload: {
    type,
    name,
  },
});

export const CONTROLLING_CHANGE_BUCKETS = 'CONTROLLING_CHANGE_BUCKETS';
export const controllingChangeBuckets = (index, position) => ({
  type: CONTROLLING_CHANGE_BUCKETS,
  payload: {
    index,
    position,
  },
});

export const CONTROLLING_SELECT_BUCKET = 'CONTROLLING_SELECT_BUCKET';
export const controllingSelectBucket = (index) => ({ type: CONTROLLING_SELECT_BUCKET, payload: index });

export const CONTROLLING_SET_HELPER_STATUS = 'CONTROLLING_SET_HELPER_STATUS';
export const controllingSetHelperStatus = (isOpen) => ({ type: CONTROLLING_SET_HELPER_STATUS, payload: isOpen });
