import Stretch from '../Stretch';
import StretchPreview from './StretchPreview';

class MediumStretch extends Stretch {
  selected = true;

  constructor(parent, handler, previewValue, confirmValue) {
    super(parent, handler);

    this.previewValue = previewValue;
    this.confirmValue = confirmValue;

    this.addToPreview();
    this.reset();
  }

  addToPreview() {
    this.stretchPreview = new StretchPreview(this.preview);
  }

  updateValue(value) {
    this.previewValue(value);
  }

  onStop() {
    this.confirmValue();
    this.stretchPreview.hideHistory();
  }

  onStart() {
    this.stretchPreview.showHistory();
  }

  takeColor(color, forced = false) {
    this.stretchPreview.takeColor(color, forced);
  }

  setTargetColor(color) {
    this.stretchPreview.setTargetColor(color);
  }
}

export default MediumStretch;
