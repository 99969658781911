import { APP_URL, IS_PRODUCTION } from '../../../settings';
import { errorCannotLoadData } from '../../../store/error/actions';
import { loadingNextStage } from '../../../store/loading/actions';
import shaderList from '../shaderList';
import obfuscate from '../utils/obfuscate';
import { useEffect } from 'preact/hooks';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

const t = Date.now();

const generateUrl = (name) => {
  return `${APP_URL}/${IS_PRODUCTION ? 'enc' : 'raw'}/shaders/${name}${IS_PRODUCTION ? '' : `?t=${t}`}`;
};

const useLoadShaders = (canLoadData) => {
  const dispatch = useDispatch();
  const shadersRef = useRef();

  useEffect(() => {
    const loadShaders = () => {
      Promise.all(shaderList.map((description) => fetch(generateUrl(description[1]))))
        .then((responses) => responses.map((r) => r.text()))
        .then((promises) => Promise.all(promises))
        .then((resources) => {
          shadersRef.current = shaderList.reduce((acc, curr, index) => {
            return {
              ...acc,
              [curr[0]]: obfuscate(resources[index]),
            };
          }, {});

          dispatch(loadingNextStage());
        })
        .catch(() => {
          dispatch(errorCannotLoadData());
        });
    };

    if (canLoadData) {
      loadShaders();
    }
  }, [dispatch, canLoadData]);

  return shadersRef.current;
};

export default useLoadShaders;
