export const VERSION = '0.2.1';
export const BUILD = '45e84c5';

export const IS_PRODUCTION = true;

export const USE_DEFAULT_LANGUAGE = false;
export const DEFAULT_LANGUAGE = 'en';

export const DOMAIN = 'wellofart.com';

export const MAIN_URL = `//${DOMAIN}`;
export const USER_URL = `//user.${DOMAIN}`;
export const PREVIEW_URL = `//preview.${DOMAIN}`;

//*
export const ADMIN_URL = `//admin.${DOMAIN}/api`;
export const API_URL = `//api.${DOMAIN}`;
export const APP_URL = `//app.${DOMAIN}`;
/*/
const LOCALHOST = '192.168.0.101';

export const ADMIN_URL = `//${LOCALHOST}:7788/api`;
export const API_URL = `//${LOCALHOST}:7788`;
export const APP_URL = `//${LOCALHOST}:3030`;
//*/

export const AUTH_TOKEN_NAME = 'authToken';
export const MAIN_COMPANY_NAME = 'glaze';

export const AVAILABLE_COMPANIES = [MAIN_COMPANY_NAME, 'wilanow'];

export const AUTO_HIDING_NAME = 'auto-hiding';

export const VERIFY_DEVICE = false;

export const CACHE_IMAGES = true;

export const DEFAULT_PREMIUM_STATUS_FOR_COMPANY = false;

export const HIDE_CONSOLE_LOGS = false;

export const HIDE_STATUS_JS = true;

export const LOG_ROCKET_KEY = 'tijsmg/glaze';
