import { Iluminant65, yBar, ones, xBar, zBar } from './theoreticalNumbers';
import { RgbColor, Spectre, Triplet } from './types';

const integral = (
  spectralPowerDistribution: Spectre,
  colorMatching: Spectre,
  spectralReflectance: Spectre,
  dLambda = 5
) => {
  const l = spectralPowerDistribution.length;
  let I = 0;

  for (let i = 0; i < l; i++) {
    I += spectralPowerDistribution[i] * colorMatching[i] * spectralReflectance[i];
  }

  return dLambda * I;
};

const K = 100 / integral(Iluminant65, yBar, ones);

// sRGB
const inverseM = [
  [3.2404542, -1.5371385, -0.4985314],
  [-0.969266, 1.8760108, 0.041556],
  [0.0556434, -0.2040259, 1.0572252],
];

const rgbToHex = (r: number, g: number, b: number): string =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

export const rgb = (x: number, y: number, z: number): RgbColor => {
  const max = 100;
  const normalizedX = x / max;
  const normalizedY = y / max;
  const normalizedZ = z / max;

  const r = inverseM[0][0] * normalizedX + inverseM[0][1] * normalizedY + inverseM[0][2] * normalizedZ;
  const g = inverseM[1][0] * normalizedX + inverseM[1][1] * normalizedY + inverseM[1][2] * normalizedZ;
  const b = inverseM[2][0] * normalizedX + inverseM[2][1] * normalizedY + inverseM[2][2] * normalizedZ;

  const small = [r, g, b];
  const big = [];

  for (let i = 0; i < 3; i++) {
    const v = small[i];
    let V;

    // sRGB Companding
    if (v < 0.0031308) {
      V = 12.92 * v;
    } else {
      V = 1.055 * v ** (1 / 2.4) - 0.055;
    }

    const color = Math.round(255 * V);

    if (color < 0) {
      big.push(0);
    } else if (color > 255) {
      big.push(255);
    } else {
      big.push(color);
    }
  }

  return big as RgbColor;
};

/*
 * Conversion to sRGB for D65.
 */
export const calculateXYZFromReflectance = (reflectance: Spectre): Triplet => {
  const X10 = K * integral(Iluminant65, xBar, reflectance);
  const Y10 = K * integral(Iluminant65, yBar, reflectance);
  const Z10 = K * integral(Iluminant65, zBar, reflectance);

  return [X10, Y10, Z10];
};

export const calculateRgbFromReflectance = (reflectance: Spectre): RgbColor => {
  const XYZ = calculateXYZFromReflectance(reflectance);

  return rgb(...XYZ);
};

export const calculateHexFromReflectance = (reflectance: Spectre): string =>
  rgbToHex(...calculateRgbFromReflectance(reflectance));

export const hexToRgb = (color: string): RgbColor => {
  const str = color.substr(1);

  const strRgbHex = str.match(/.{1,2}/g);
  let rgbColor: RgbColor;

  if (strRgbHex) {
    const r = Math.max(parseInt(strRgbHex[0], 16) / 256, 0.0000001);
    const g = Math.max(parseInt(strRgbHex[1], 16) / 256, 0.0000001);
    const b = Math.max(parseInt(strRgbHex[2], 16) / 256, 0.0000001);

    rgbColor = [r, g, b];
  } else {
    rgbColor = [0, 0, 0];
  }

  return rgbColor;
};

export const calculateHexFromAbsorption = (absorption: Spectre, d = 1) => {
  const reflectance = absorption.map((a) => Math.exp(-2 * a * d)) as Spectre;

  return calculateHexFromReflectance(reflectance);
};

export const calculateHex = (
  impastoAbsorption: Spectre,
  glazeAbsorption: Spectre,
  mediumAbsorption: Spectre,
  limit: number,
  medium: number
) => {
  const mediumHeight = 40.0;

  let newAbsorptionGlaze: Spectre = [] as unknown as Spectre;
  let newAbsorptionImpasto: Spectre = [] as unknown as Spectre;
  let reflectance: Spectre = [] as unknown as Spectre;

  let normalizedMedium;

  if (limit === 0) {
    normalizedMedium = limit;
  } else {
    normalizedMedium = medium / limit;
  }

  for (let i = 0; i < impastoAbsorption.length; i++) {
    newAbsorptionGlaze[i] = normalizedMedium * glazeAbsorption[i] + mediumAbsorption[i] * mediumHeight;
  }

  // only glaze
  if (medium <= limit) {
    reflectance = newAbsorptionGlaze.map((a) => Math.exp(-2 * a)) as Spectre;
  } else {
    const scale = (medium - limit) / (1.0 - limit);

    for (let i = 0; i < impastoAbsorption.length; i++) {
      newAbsorptionImpasto[i] = impastoAbsorption[i];
    }

    const reflectanceGlaze = newAbsorptionGlaze.map((a) => Math.exp(-2 * a)) as Spectre;
    const reflectanceImpasto = newAbsorptionImpasto.map((a) => Math.exp(-2 * a)) as Spectre;

    for (let i = 0; i < impastoAbsorption.length; i++) {
      reflectance[i] = reflectanceImpasto[i] * scale + (1.0 - scale) * reflectanceGlaze[i];
    }
  }

  return calculateHexFromReflectance(reflectance);
};
