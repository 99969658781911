import Medium from './Medium';
import Move from './Move';
import Paint from './Paint';
import styles from './Tool.module.scss';

class Tool {
  constructor(state) {
    this.state = state;

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add(styles.wrapper);
    this.wrapper.setAttribute('id', 'tool-box');
    document.body.appendChild(this.wrapper);

    this.updatePosition = this.updatePosition.bind(this);

    this.medium = new Medium(this.state, this.wrapper);
    this.move = new Move(this.wrapper, this.updatePosition);

    this.paints = [];

    for (let i = 0; i < 3; i++) {
      this.paints.push(new Paint(this.wrapper, i, this.move));
    }

    this.setState();
  }

  destructor() {
    document.body.removeChild(this.wrapper);
  }

  setState() {
    for (let i = 0; i < this.paints.length; i++) {
      this.paints[i].setState(this.state);
    }
  }

  updatePosition(position) {
    this.move.setPosition(position);
    this.medium.setPosition(position);
  }

  update(state) {
    if (state !== this.state) {
      for (let i = 0; i < this.paints.length; i++) {
        this.paints[i].update(state);
      }

      this.medium.update(state);
    }

    this.state = state;
  }

  goToBasicPosition() {
    this.move.inertiaSetTargetPosition(100, 200);
  }
}

export default Tool;
