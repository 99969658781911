const shaderList = [
  ['addVectorsFrag', `addVectors.frag`],
  ['bendingConstraintFrag', `bendingConstraint.frag`],
  ['brdfFrag', `brdf.frag`],
  ['brushViewerFrag', `brushViewer.frag`],
  ['brushViewerVert', `brushViewer.vert`],
  ['calculateColorFrag', `calculateColor.frag`],
  ['calculateColorFromReflectanceFrag', `calculateColorFromReflectance.frag`],
  ['distanceConstraintFrag', `distanceConstraint.frag`],
  ['divergenceFrag', `divergence.frag`],
  ['fullscreenVert', `fullscreen.vert`],
  ['jacobiFrag', `jacobi.frag`],
  ['manageAlphaFrag', `manageAlpha.frag`],
  ['outputFrag', `output.frag`],
  ['planeConstraintFrag', `planeConstraint.frag`],
  ['projectFrag', `project.frag`],
  ['setBristlesFrag', `setBristles.frag`],
  ['setOperationFrag', `setOperation.frag`],
  ['splatFrag', `splat.frag`],
  ['splatVert', `splat.vert`],
  ['subtractFrag', `subtract.frag`],
  ['surfaceFrag', `surface.frag`],
  ['updateReflectanceFrag', `updateReflectance.frag`],
  ['updateVelocityFrag', `updateVelocity.frag`],
];

export default shaderList;
