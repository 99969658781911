import { DEFAULT_LANGUAGE } from './settings';
import pl from './translations/pl';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {},
  },
  pl: {
    translation: pl,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANGUAGE,
  });

export default i18n;
