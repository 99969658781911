import styles from './Upgrade.module.scss';
import { useTranslation } from 'react-i18next';

const UpgradeCompany = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.upgrade}>
        <div className={styles.limited}>
          {t('Your tools are')} <span>{t('limited')}</span>
        </div>
        <div className={styles.thanks}>
          <div>
            {t('Thank you for trying out the')} <strong>Glaze!</strong> {t('You are on a')}{' '}
            <span className={styles.basic}>{t('Basic')}</span> {t('plan.')}
            <br />
            <br />
            {t('To see the full experience, please visit')} <u>wellofart.com</u>, {t('sign up and subscribe.')}
            <br />
            <br />
            {t('We hope to see you soon :)')}
            <br />
            <br />
            <span className={styles.team}>{t('Glaze Team')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeCompany;
