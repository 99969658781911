/* eslint-disable  react-hooks/exhaustive-deps */
import { getDetails, getPremiumStatus, getSketchDetails, getTrialEndDate, setToken } from '../../api';
import {
  AUTH_TOKEN_NAME,
  AVAILABLE_COMPANIES,
  DEFAULT_LANGUAGE,
  DEFAULT_PREMIUM_STATUS_FOR_COMPANY,
  MAIN_COMPANY_NAME,
  USE_DEFAULT_LANGUAGE,
  VERIFY_DEVICE,
} from '../../settings';
import { detailsSet } from '../../store/details/actions';
import { errorNotFound, errorUnauthorized, errorUnsupportedDevice } from '../../store/error/actions';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import { differenceInHours, parseISO } from 'date-fns';
import i18n from 'i18next';
import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import { isExpired, decodeToken } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

const Initializer = () => {
  const { company, id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReadyForVerifyingDevice, setIsReadyForVerifyingDevice] = useState(false);
  const [isReadyForLoadingData, setIsReadyForLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const token = searchParams.get('token');

  const errorDetails = {
    backTo: company ? (company === MAIN_COMPANY_NAME ? 'user' : 'company') : 'main',
    companyName: company,
  };

  // Get token from url and save in LS. Remove from url.
  useEffect(() => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN_NAME, token);

      setSearchParams({});
    }

    setIsReadyForVerifyingDevice(true);
  }, []);

  // Verify device
  useEffect(() => {
    if (isReadyForVerifyingDevice) {
      const isIpad = navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

      if (isIpad || !VERIFY_DEVICE) {
        setIsReadyForLoadingData(true);
      } else {
        dispatch(errorUnsupportedDevice());
      }
    }
  }, [isReadyForVerifyingDevice]);

  // Verify token, get details, premium status and trial date. Put details into redux.
  useEffect(() => {
    const loadDetails = async (decodedToken) => {
      const isMain = company === MAIN_COMPANY_NAME;

      try {
        if (isMain) {
          const detailsResponse = await getDetails(id);
          const premiumResponse = await getPremiumStatus();
          let isPremium = false;

          if (premiumResponse.data) {
            isPremium = true;
          } else {
            const trialResponse = await getTrialEndDate();

            if (trialResponse.data) {
              isPremium = isPremium || differenceInHours(new Date(), parseISO(trialResponse.data)) < 0;
            }
          }

          dispatch(
            detailsSet({
              ...detailsResponse.data,
              isPremium,
              isCompany: false,
            })
          );
        } else {
          const detailsResponse = await getSketchDetails(id);

          dispatch(
            detailsSet({
              ...detailsResponse.data,
              isPremium: DEFAULT_PREMIUM_STATUS_FOR_COMPANY,
              isCompany: true,
              companyId: decodedToken?.companyId,
              unitName: decodedToken?.unitName,
            })
          );
        }
      } catch (e) {
        const status = e?.response?.status || 404;

        dispatch(status === 404 ? errorNotFound(errorDetails) : errorUnauthorized(errorDetails));
      }

      setTimeout(() => {
        setLoading(false);
      }, 100);
    };

    if (isReadyForLoadingData) {
      if (AVAILABLE_COMPANIES.includes(company)) {
        const rememberedToken = localStorage.getItem(AUTH_TOKEN_NAME);

        const decoded = decodeToken(rememberedToken);
        const expired = isExpired(rememberedToken);

        if (!USE_DEFAULT_LANGUAGE) {
          const lang = decoded?.language || DEFAULT_LANGUAGE;
          i18n.changeLanguage(lang);
        }

        if (decoded) {
          LogRocket.identify(decoded.email || decoded.unitName);
        }

        if (decoded && !expired) {
          setToken(rememberedToken);

          setLoading(true);

          loadDetails(decoded);
        } else {
          dispatch(errorUnauthorized(errorDetails));
        }
      } else {
        dispatch(errorNotFound(errorDetails));
      }
    }
  }, [isReadyForLoadingData]);

  if (loading) {
    return <LoadingCircle />;
  }

  return null;
};

export default Initializer;
