import UpgradeCompany from './UpgradeCompany';
import UpgradeUser from './UpgradeUser';
import { useSelector } from 'react-redux';

const Upgrade = () => {
  const { isCompany } = useSelector((s) => s.details);

  if (isCompany) {
    return <UpgradeCompany />;
  }

  return <UpgradeUser />;
};

export default Upgrade;
