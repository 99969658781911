import preventDefault from '../../helpers/preventDefault';
import { DOMAIN, MAIN_URL, USER_URL } from '../../settings';
import styles from './NoResource.module.scss';
import logoUrl from './assets/logo.svg';
import { useTranslation } from 'react-i18next';

const NoResource = ({ backToMainPage, backToUserPage, backToCompanyPage, companyName }) => {
  const { t } = useTranslation();

  const url = backToMainPage
    ? MAIN_URL
    : backToUserPage
    ? USER_URL
    : backToCompanyPage
    ? `//${companyName}.${DOMAIN}`
    : MAIN_URL;

  return (
    <div className={styles.page}>
      <div className={styles.center}>
        <div onClick={preventDefault}>
          <img src={logoUrl} alt="Glaze" />
          <h1>{t('Access to app is available only with valid credentials.')}</h1>
          <h2>{t('Go to dashboard and click directly on link to start painting.')}</h2>
        </div>

        <div>
          <a href={url}>{t('Back to dashboard')}</a>
        </div>
      </div>
    </div>
  );
};

export default NoResource;
