import Confirm from '../Confirm';
import Congratulations from '../Congratulations';
import styles from './Share.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Share = ({ engine }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { sharedAs } = useSelector((s) => s.details);
  const { t } = useTranslation();

  return (
    <>
      <button
        className={styles.ready}
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        {t('Ready!')}
      </button>

      {showConfirm && (
        <Confirm
          handleYes={() => {
            setShowConfirm(false);

            engine.share();
          }}
          handleNo={() => {
            setShowConfirm(false);
          }}
          text={t('Are you sure?')}
        />
      )}

      {sharedAs && <Congratulations id={sharedAs} />}
    </>
  );
};

export default Share;
