const quad = [-1.0, -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0];

const createQuadVertexBuffer = (gl) => {
  const quadVertexBuffer = gl.createBuffer();

  gl.bindBuffer(gl.ARRAY_BUFFER, quadVertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(quad), gl.STATIC_DRAW);

  gl.bindBuffer(gl.ARRAY_BUFFER, null);

  return quadVertexBuffer;
};

export default createQuadVertexBuffer;
