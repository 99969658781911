import Brdf from './Brdf';
import Brush from './Brush';
import BrushViewer from './BrushViewer';
import Coloring from './Coloring';
import Output from './Output';
import Rectangle from './Rectangle';
import Splat from './Splat';
import SplatPencil from './SplatPencil';
import stats from './Stats';
import Surface from './Surface';
import TextureMixer from './TextureMixer';
import calculateSettings from './calculations/calculateSettings';
import isMobileDevice from './helpers/isMobileDevice';

const SHOW_BRUSH = !isMobileDevice();

class Painting {
  painting = false;

  needsRedraw = true;

  viewportUpdated = true;

  blocked = false;

  testingState = {};

  constructor(gl, shaders, viewport, textureManager, data) {
    this.gl = gl;
    this.shaders = shaders;
    this.viewport = viewport;
    this.textureManager = textureManager;
    this.data = data;

    this.output = new Output(this.gl, this.shaders, this.viewport);
    this.brush = new Brush(this.gl, this.shaders, this.textureManager);
    this.brushViewer = new BrushViewer(this.gl, this.shaders, this.viewport, this.textureManager, this.brush);
    this.splatBrush = new Splat(this.gl, this.shaders, this.textureManager, this.brush);
    this.splatPencil = new SplatPencil(this.gl, this.shaders, this.textureManager, this.brush);
    this.coloring = new Coloring(this.gl, this.shaders, this.textureManager, this.data);
    this.brdf = new Brdf(this.gl, this.shaders, this.textureManager);
    this.surface = new Surface(this.gl, this.shaders, this.textureManager);

    this.textureMixer = new TextureMixer(this.gl, this.shaders, this.textureManager);

    this.initializeTool();

    this.loop = this.loop.bind(this);
    this.loop();
  }

  loop() {
    stats.begin();

    if (this.initialized && !this.blocked) {
      this.frame();
    }

    stats.end();

    window.requestAnimationFrame(this.loop);
  }

  frame() {
    if (this.painting) {
      this.brush.setPosition(this.currentX, this.currentY, this.currentPressure);

      const brushArea = this.splatBrush.splat(this.currentPressure);
      this.splatPencil.splat(this.currentPressure, brushArea);
    }

    let area = this.splatBrush.simulate();
    // this.splatPencil.simulate();

    if (area) {
      this.needsRedraw = true;
    }

    if (this.needsRedraw) {
      if (area === undefined || this.viewportUpdated) {
        area = new Rectangle(0, 0, this.textureManager.width, this.textureManager.height);
      }

      // this.coloring.updateReflectances(area);
      // this.coloring.calculateColorFromReflectance(area, true);

      this.coloring.calculateColor(area);

      this.surface.connect(area);
      this.brdf.render(area);
    }

    if (this.needsRedraw || this.viewportUpdated) {
      if (this.testingState.testedTexture) {
        this.output.print(this.textureManager[this.testingState.testedTexture]);
      } else {
        this.output.print(this.textureManager.brdfTexture);
      }

      if (SHOW_BRUSH && this.painting) {
        this.brushViewer.print();
      }

      this.viewportUpdated = false;
      this.needsRedraw = false;
    }
  }

  initializeTool() {
    this.start = this.start.bind(this);
    this.move = this.move.bind(this);
    this.stop = this.stop.bind(this);
    this.changeViewport = this.changeViewport.bind(this);

    this.viewport.setHandlers(this.start, this.move, this.stop, this.changeViewport);
  }

  start(coords, pressure) {
    this.setCurrentPointerValues(coords, pressure);

    this.brush.initialize(coords[0], coords[1], pressure);

    this.painting = true;
  }

  move(coords, pressure) {
    this.setCurrentPointerValues(coords, pressure);
  }

  stop(/* coords, pressure */) {
    this.painting = false;
  }

  setCurrentPointerValues(coords, pressure) {
    this.currentX = coords[0];
    this.currentY = coords[1];
    this.currentPressure = pressure;
  }

  changeViewport() {
    this.viewportUpdated = true;
  }

  update(state) {
    if (state !== this.state) {
      const settings = calculateSettings(state);

      this.brush.update(settings);
      this.splatBrush.update(settings);
      this.splatPencil.update(settings);
      this.brdf.update(settings);
      this.surface.update(settings);
      this.coloring.update(settings);
    }

    this.state = state;

    this.initialized = true;

    this.needsRedraw = true;
  }

  clear() {
    this.needsRedraw = true;
    this.viewportUpdated = true;
  }

  dry() {
    this.textureMixer.intersection('splatPencilPaintTexture', 'splatBrushPaintTexture', 'splatPencilHelperTexture');
    this.textureManager.swap('splatPencilPaintTexture', 'splatPencilHelperTexture');

    // render tmp
    const area = new Rectangle(0, 0, this.textureManager.width, this.textureManager.height);

    this.coloring.updateReflectances(area);

    // this.coloring.calculateColorFromReflectance(area, true);
    this.surface.connect(area);

    this.textureManager.swap('splatPencilPaintTexture', 'splatPencilHelperTexture');

    this.textureMixer.difference('splatPencilPaintTexture', 'splatBrushPaintTexture', 'splatPencilHelperTexture');
    this.textureManager.swap('splatPencilPaintTexture', 'splatPencilHelperTexture');
  }

  setTestingState(state) {
    this.testingState = state;
  }
}

export default Painting;
