import { setMedium } from '../actions';
import settings from '../settings';
import styles from './Medium.module.scss';

const PI_HALF = Math.PI / 2;

const MIN_X_IN_GRADIENT = -8;
const MIN_RADIUS = 37;
const MAX_RADIUS = 52;

class Gradient {
  gradients = [];

  constructor(state, parent) {
    this.state = state;
    this.parent = parent;

    this.createDOM();

    this.addEventListeners();

    this.currentTranslationIndex = this.getTranslationIndex(state);

    this.addGradient();
  }

  createDOM() {
    const halfSize = settings.colorSize / 2;
    const size = 2 * settings.colorSize;

    this.wrapper = document.createElement('div');
    this.wrapper.className = styles.gradient;
    this.wrapper.style.position = 'absolute';
    this.wrapper.style.top = `${halfSize}px`;
    this.wrapper.style.left = `${halfSize}px`;
    this.wrapper.style.width = `${size}px`;
    this.wrapper.style.height = `${size}px`;

    this.arc = document.createElement('div');
    this.arc.className = styles.gradientArc;
    this.wrapper.appendChild(this.arc);

    this.handler = document.createElement('div');
    this.handler.className = styles.gradientHandler;
    this.wrapper.appendChild(this.handler);

    this.parent.appendChild(this.wrapper);
  }

  addEventListeners() {
    this.setValueByClick = this.setValueByClick.bind(this);

    this.handler.addEventListener('click', this.setValueByClick, false);
  }

  setValueByClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const mouse = [e.clientX, e.clientY];

    const rect = this.wrapper.getBoundingClientRect();

    const center = [rect.left + rect.width / 2, rect.top + rect.height / 2];

    const v = [mouse[0] - center[0], mouse[1] - center[1]];

    if (v[0] < MIN_X_IN_GRADIENT) {
      return;
    }

    const radius = Math.sqrt((mouse[0] - center[0]) ** 2 + (mouse[1] - center[1]) ** 2);

    if (radius > MIN_RADIUS && radius < MAX_RADIUS) {
      const value = 0.5 + Math.min(Math.max(Math.atan2(v[1], v[0]), -PI_HALF), PI_HALF) / Math.PI;

      setMedium(value);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getTranslationIndex(state) {
    return state.controlling.buckets[state.controlling.selectedBucket];
  }

  addGradient() {
    const size = 2 * settings.colorSize;

    const gradient = document.createElement('div');
    gradient.style.backgroundPositionY = `-${this.currentTranslationIndex * size}px`;
    gradient.className = styles.fadeIn;

    this.gradients.push(gradient);

    this.arc.appendChild(gradient);

    this.addFadeOutClasses();

    setTimeout(() => {
      this.removeOldGradients();
    }, 200);
  }

  addFadeOutClasses() {
    const times = this.gradients.length - 1;

    for (let i = 0; i < times; i++) {
      this.gradients[i].classList.remove(styles.fadeIn);
      this.gradients[i].classList.add(styles.fadeOut);
    }
  }

  removeOldGradients() {
    const times = this.gradients.length - 1;

    for (let i = 0; i < times; i++) {
      this.arc.removeChild(this.gradients[0]);
      this.gradients.shift();
    }
  }

  update(state) {
    const newTranslationIndex = this.getTranslationIndex(state);

    if (newTranslationIndex !== this.currentTranslationIndex) {
      this.currentTranslationIndex = newTranslationIndex;

      this.addGradient();
    }
  }
}

export default Gradient;
