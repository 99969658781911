import controllingReducer from './controlling/reducer';
import detailsReducer from './details/reducer';
import errorReducer from './error/reducer';
import loadingReducer from './loading/reducer';
import measuresReducer from './measures/reducer';
import paintingReducer from './painting/reducer';
import presetsReducer from './presets/reducer';
import propertiesReducer from './properties/reducer';
import reactions from './reactions';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
  controlling: controllingReducer,
  details: detailsReducer,
  error: errorReducer,
  loading: loadingReducer,
  measures: measuresReducer,
  painting: paintingReducer,
  presets: presetsReducer,
  properties: propertiesReducer,
});

const reaction = (store) => (next) => (action) => {
  const result = next(action);

  if (reactions[action.type]) {
    reactions[action.type].forEach((fn) => {
      fn(action, next, store.getState);
    });
  }

  return result;
};

const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(reaction)));

export default store;
