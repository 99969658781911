export const makePerspectiveMatrix = (out, fovy, aspect, near, far) => {
  const f = 1.0 / Math.tan(fovy / 2);
  const nf = 1 / (near - far);

  out[0] = f / aspect;
  out[1] = 0;
  out[2] = 0;
  out[3] = 0;
  out[4] = 0;
  out[5] = f;
  out[6] = 0;
  out[7] = 0;
  out[8] = 0;
  out[9] = 0;
  out[10] = (far + near) * nf;
  out[11] = -1;
  out[12] = 0;
  out[13] = 0;
  out[14] = 2 * far * near * nf;
  out[15] = 0;

  return out;
};

export const makeIdentityMatrix = (matrix) => {
  matrix[0] = 1.0;
  matrix[1] = 0.0;
  matrix[2] = 0.0;
  matrix[3] = 0.0;
  matrix[4] = 0.0;
  matrix[5] = 1.0;
  matrix[6] = 0.0;
  matrix[7] = 0.0;
  matrix[8] = 0.0;
  matrix[9] = 0.0;
  matrix[10] = 1.0;
  matrix[11] = 0.0;
  matrix[12] = 0.0;
  matrix[13] = 0.0;
  matrix[14] = 0.0;
  matrix[15] = 1.0;

  return matrix;
};

export const premultiplyMatrix = (out, matrixA, matrixB) => {
  //out = matrixB * matrixA
  let aX = matrixA[0],
    aY = matrixA[1],
    aZ = matrixA[2],
    aW = matrixA[3],
    b0 = matrixB[0],
    b1 = matrixB[1],
    b2 = matrixB[2],
    b3 = matrixB[3],
    b4 = matrixB[4],
    b5 = matrixB[5],
    b6 = matrixB[6],
    b7 = matrixB[7],
    b8 = matrixB[8],
    b9 = matrixB[9],
    b10 = matrixB[10],
    b11 = matrixB[11],
    b12 = matrixB[12],
    b13 = matrixB[13],
    b14 = matrixB[14],
    b15 = matrixB[15];

  out[0] = b0 * aX + b4 * aY + b8 * aZ + b12 * aW;
  out[1] = b1 * aX + b5 * aY + b9 * aZ + b13 * aW;
  out[2] = b2 * aX + b6 * aY + b10 * aZ + b14 * aW;
  out[3] = b3 * aX + b7 * aY + b11 * aZ + b15 * aW;

  aX = matrixA[4];
  aY = matrixA[5];
  aZ = matrixA[6];
  aW = matrixA[7];

  out[4] = b0 * aX + b4 * aY + b8 * aZ + b12 * aW;
  out[5] = b1 * aX + b5 * aY + b9 * aZ + b13 * aW;
  out[6] = b2 * aX + b6 * aY + b10 * aZ + b14 * aW;
  out[7] = b3 * aX + b7 * aY + b11 * aZ + b15 * aW;

  aX = matrixA[8];
  aY = matrixA[9];
  aZ = matrixA[10];
  aW = matrixA[11];

  out[8] = b0 * aX + b4 * aY + b8 * aZ + b12 * aW;
  out[9] = b1 * aX + b5 * aY + b9 * aZ + b13 * aW;
  out[10] = b2 * aX + b6 * aY + b10 * aZ + b14 * aW;
  out[11] = b3 * aX + b7 * aY + b11 * aZ + b15 * aW;

  aX = matrixA[12];
  aY = matrixA[13];
  aZ = matrixA[14];
  aW = matrixA[15];

  out[12] = b0 * aX + b4 * aY + b8 * aZ + b12 * aW;
  out[13] = b1 * aX + b5 * aY + b9 * aZ + b13 * aW;
  out[14] = b2 * aX + b6 * aY + b10 * aZ + b14 * aW;
  out[15] = b3 * aX + b7 * aY + b11 * aZ + b15 * aW;

  return out;
};

export const makeXRotationMatrix = (matrix, angle) => {
  matrix[0] = 1.0;
  matrix[1] = 0.0;
  matrix[2] = 0.0;
  matrix[3] = 0.0;
  matrix[4] = 0.0;
  matrix[5] = Math.cos(angle);
  matrix[6] = Math.sin(angle);
  matrix[7] = 0.0;
  matrix[8] = 0.0;
  matrix[9] = -Math.sin(angle);
  matrix[10] = Math.cos(angle);
  matrix[11] = 0.0;
  matrix[12] = 0.0;
  matrix[13] = 0.0;
  matrix[14] = 0.0;
  matrix[15] = 1.0;

  return matrix;
};

export const makeYRotationMatrix = (matrix, angle) => {
  matrix[0] = Math.cos(angle);
  matrix[1] = 0.0;
  matrix[2] = -Math.sin(angle);
  matrix[3] = 0.0;
  matrix[4] = 0.0;
  matrix[5] = 1.0;
  matrix[6] = 0.0;
  matrix[7] = 0.0;
  matrix[8] = Math.sin(angle);
  matrix[9] = 0.0;
  matrix[10] = Math.cos(angle);
  matrix[11] = 0.0;
  matrix[12] = 0.0;
  matrix[13] = 0.0;
  matrix[14] = 0.0;
  matrix[15] = 1.0;

  return matrix;
};

export const makeOrthographicMatrix = (matrix, left, right, bottom, top, near, far) => {
  matrix[0] = 2 / (right - left);
  matrix[1] = 0;
  matrix[2] = 0;
  matrix[3] = 0;
  matrix[4] = 0;
  matrix[5] = 2 / (top - bottom);
  matrix[6] = 0;
  matrix[7] = 0;
  matrix[8] = 0;
  matrix[9] = 0;
  matrix[10] = -2 / (far - near);
  matrix[11] = 0;
  matrix[12] = -(right + left) / (right - left);
  matrix[13] = -(top + bottom) / (top - bottom);
  matrix[14] = -(far + near) / (far - near);
  matrix[15] = 1;

  return matrix;
};
