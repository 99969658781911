import { Spectre } from './types';

/*
 * A standard illuminant is a theoretical source of visible light with a profile (its spectral power distribution)
 * which is published. Standard illuminants provide a basis for comparing images or colors recorded
 * under different lighting.
 */
export const Iluminant65 = [
  [380, 49.9755],
  [385, 52.3118],
  [390, 54.6482],
  [395, 68.7015],
  [400, 82.7549],
  [405, 87.1204],
  [410, 91.486],
  [415, 92.4589],
  [420, 93.4318],
  [425, 90.057],
  [430, 86.6823],
  [435, 95.7736],
  [440, 104.865],
  [445, 110.936],
  [450, 117.008],
  [455, 117.41],
  [460, 117.812],
  [465, 116.336],
  [470, 114.861],
  [475, 115.392],
  [480, 115.923],
  [485, 112.367],
  [490, 108.811],
  [495, 109.082],
  [500, 109.354],
  [505, 108.578],
  [510, 107.802],
  [515, 106.296],
  [520, 104.79],
  [525, 106.239],
  [530, 107.689],
  [535, 106.047],
  [540, 104.405],
  [545, 104.225],
  [550, 104.046],
  [555, 102.023],
  [560, 100],
  [565, 98.1671],
  [570, 96.3342],
  [575, 96.0611],
  [580, 95.788],
  [585, 92.2368],
  [590, 88.6856],
  [595, 89.3459],
  [600, 90.0062],
  [605, 89.8026],
  [610, 89.5991],
  [615, 88.6489],
  [620, 87.6987],
  [625, 85.4936],
  [630, 83.2886],
  [635, 83.4939],
  [640, 83.6992],
  [645, 81.863],
  [650, 80.0268],
  [655, 80.1207],
  [660, 80.2146],
  [665, 81.2462],
  [670, 82.2778],
  [675, 80.281],
  [680, 78.2842],
  [685, 74.0027],
  [690, 69.7213],
  [695, 70.6652],
  [700, 71.6091],
  [705, 72.979],
  [710, 74.349],
  [715, 67.9765],
  [720, 61.604],
  [725, 65.7448],
  [730, 69.8856],
  [735, 72.4863],
  [740, 75.087],
  [745, 69.3398],
  [750, 63.5927],
  [755, 55.0054],
  [760, 46.4182],
  [765, 56.6118],
  [770, 66.8054],
  [775, 65.0941],
  [780, 63.3828],
].map((r) => r[1]) as Spectre;

export const ones: Spectre = [] as unknown as Spectre;

for (let i = 0; i < Iluminant65.length; ++i) {
  ones.push(1);
}

/*
 * Color matching functions
 */
export const xBar: Spectre = [
  0.00016, 0.000662, 0.002362, 0.007242, 0.01911, 0.0434, 0.084736, 0.140638, 0.204492, 0.264737, 0.314679, 0.357719,
  0.383734, 0.386726, 0.370702, 0.342957, 0.302273, 0.254085, 0.195618, 0.132349, 0.080507, 0.041072, 0.016172,
  0.005132, 0.003816, 0.015444, 0.037465, 0.071358, 0.117749, 0.172953, 0.236491, 0.304213, 0.376772, 0.451584,
  0.529826, 0.616053, 0.705224, 0.793832, 0.878655, 0.951162, 1.01416, 1.0743, 1.11852, 1.1343, 1.12399, 1.0891,
  1.03048, 0.95074, 0.856297, 0.75493, 0.647467, 0.53511, 0.431567, 0.34369, 0.268329, 0.2043, 0.152568, 0.11221,
  0.081261, 0.05793, 0.040851, 0.028623, 0.019941, 0.013842, 0.009577, 0.006605, 0.004553, 0.003145, 0.002175, 0.001506,
  0.001045, 0.000727, 0.000508, 0.000356, 0.000251, 0.000178, 0.000126, 0.00009, 0.000065, 0.000046, 0.000033,
];

export const yBar: Spectre = [
  0.000017, 0.000072, 0.000253, 0.000769, 0.002004, 0.004509, 0.008756, 0.014456, 0.021391, 0.029497, 0.038676,
  0.049602, 0.062077, 0.074704, 0.089456, 0.106256, 0.128201, 0.152761, 0.18519, 0.21994, 0.253589, 0.297665, 0.339133,
  0.395379, 0.460777, 0.53136, 0.606741, 0.68566, 0.761757, 0.82333, 0.875211, 0.92381, 0.961988, 0.9822, 0.991761,
  0.99911, 0.99734, 0.98238, 0.955552, 0.915175, 0.868934, 0.825623, 0.777405, 0.720353, 0.658341, 0.593878, 0.527963,
  0.461834, 0.398057, 0.339554, 0.283493, 0.228254, 0.179828, 0.140211, 0.107633, 0.081187, 0.060281, 0.044096, 0.0318,
  0.022602, 0.015905, 0.01113, 0.007749, 0.005375, 0.003718, 0.002565, 0.001768, 0.001222, 0.000846, 0.000586, 0.000407,
  0.000284, 0.000199, 0.00014, 0.000098, 0.00007, 0.00005, 0.000036, 0.000025, 0.000018, 0.000013,
];

export const zBar: Spectre = [
  0.000705, 0.002928, 0.010482, 0.032344, 0.086011, 0.19712, 0.389366, 0.65676, 0.972542, 1.2825, 1.55348, 1.7985,
  1.96728, 2.0273, 1.9948, 1.9007, 1.74537, 1.5549, 1.31756, 1.0302, 0.772125, 0.57006, 0.415254, 0.302356, 0.218502,
  0.159249, 0.112044, 0.082248, 0.060709, 0.04305, 0.030451, 0.020584, 0.013676, 0.007918, 0.003988, 0.001091, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0,
];
