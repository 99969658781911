import mix from '../helpers/mix';

const calculateSplatForPencil = (_pressure, startingCoefficient, properties, settings) => {
  const pressure = _pressure;

  // const alphaT = 1.0;

  const minAlpha = mix(settings.thinMinAlpha, settings.thickMinAlpha, pressure);
  const maxAlpha = mix(settings.thinMaxAlpha, settings.thickMaxAlpha, pressure);
  const alpha = mix(minAlpha, maxAlpha, pressure);

  const enhancedSplatRadius = properties.splatRadius * pressure;

  const startingAlpha = alpha * startingCoefficient;

  const color = [...properties.color];
  color[3] = startingAlpha;

  if (settings.useAsPencil) {
    color[2] = 1.0;
  }

  if (!(settings.useAsPencil || settings.useAsRag)) {
    // use as brush
    color[2] = 0.0;
  }

  const splatVelocityScale = settings.splatVelocityScale * startingAlpha;

  return {
    splatRadius: enhancedSplatRadius,
    color,
    zThreshold: properties.zThreshold,
    splatVelocityScale,
  };
};

export default calculateSplatForPencil;
