import { IS_PRODUCTION, USER_URL } from '../../settings';
import { controllingSetOpenOptions } from '../../store/controlling/actions';
import Confirm from '../Confirm';
import styles from './Options.module.scss';
import clearSvg from './assets/clear.svg';
import exitSvg from './assets/exit.svg';
import fitToScreenSvg from './assets/fitToScreen.svg';
import oneToOneSvg from './assets/oneToOne.svg';
import saveSvg from './assets/save.svg';
import shareSvg from './assets/share.svg';
import snapshotSvg from './assets/snapshot.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const Options = ({ engine = {} }) => {
  const dispatch = useDispatch();
  const open = useSelector((s) => s.controlling.openOptions);
  const { t } = useTranslation();
  const [confirmExit, setConfirmExit] = useState(false);

  return (
    <>
      {open && (
        <div
          className={styles.container}
          onClick={() => {
            dispatch(controllingSetOpenOptions(false));
          }}
        >
          <div className={styles.menu}>
            <div className={styles.item} onClick={engine.save}>
              <div className={styles.icon}>
                <img src={saveSvg} className={styles.save} alt={t('Save')} />
              </div>
              <div className={styles.description}>{t('Save')}</div>
            </div>

            <div className={styles.item} onClick={engine.fitToScreen}>
              <div className={styles.icon}>
                <img src={fitToScreenSvg} alt={t('Fit to screen')} />
              </div>
              <div className={styles.description}>{t('Fit to screen')}</div>
            </div>

            <div
              className={styles.item}
              onClick={() => {
                setConfirmExit(true);
              }}
            >
              <div className={styles.icon}>
                <img src={exitSvg} className={styles.exit} alt={t('Exit')} />
              </div>
              <div className={styles.description}>{t('Exit')}</div>
            </div>

            {!IS_PRODUCTION && (
              <>
                <div className={styles.item} onClick={engine.makeSnapshot}>
                  <div className={styles.icon}>
                    <img src={snapshotSvg} alt="Make snapshot" />
                  </div>
                  <div className={styles.description}>Make snapshot</div>
                </div>

                <div className={styles.item} onClick={engine.share}>
                  <div className={styles.icon}>
                    <img src={shareSvg} alt="Share" />
                  </div>
                  <div className={styles.description}>Share</div>
                </div>

                <div className={styles.item} onClick={engine.fitToNaturalScale}>
                  <div className={styles.icon}>
                    <img src={oneToOneSvg} alt="1:1 scale" />
                  </div>
                  <div className={styles.description}>1:1 scale</div>
                </div>

                <div className={styles.item} onClick={engine.clearWetScreen}>
                  <div className={styles.icon}>
                    <img src={clearSvg} className={styles.clear} alt="Clean wet layers" />
                  </div>
                  <div className={styles.description}>Clean wet layers</div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {confirmExit && (
        <Confirm
          text={t('Do you want to leave app?')}
          handleNo={() => {
            setConfirmExit(false);
          }}
          handleYes={() => {
            window.location.href = USER_URL;
          }}
        />
      )}
    </>
  );
};

export default Options;
