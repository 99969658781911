import { savePreview, createDescription, saveTexture } from '../api';
import PreviewImage from './PreviewImage';
import { detailsSetSharedAs, loadingCompleted, loadingInitialize, loadingNextStage, showToast } from './actions';
import createFormDataFromCanvas from './helpers/createFormDataFromCanvas';
import flipYAxis from './helpers/flipYAxis';
import LogRocket from 'logrocket';

class Saver {
  constructor(details, textureManager) {
    this.details = details;
    this.textureManager = textureManager;
  }

  async save() {
    const names = [
      ['brdfTexture', 'thumbnail'],
      ['surfaceTexture', 1],
      ['reflectance0Texture', 2],
      ['reflectance1Texture', 3],
      ['reflectance2Texture', 4],
      ['reflectance3Texture', 5],
      ['reflectance4Texture', 6],
      ['reflectance5Texture', 7],
      ['reflectance6Texture', 8],
      ['reflectance7Texture', 9],
      ['reflectance8Texture', 10],
      ['reflectance9Texture', 11],
      ['reflectance10Texture', 12],
      ['reflectance11Texture', 13],
      ['reflectance12Texture', 14],
      ['reflectanceTempTexture', 15],
    ];

    const id = this.details.id;

    loadingInitialize(names.length);

    this.textureManager.removeAlpha('splatPencilPaintTexture', 'reflectanceTempTexture');

    this.createCanvas();

    this.saveContext = this.canvas.getContext('2d');

    try {
      await this.saveTexture(id, names[0]);
      await this.saveTexture(id, names[1]);
      await this.saveTexture(id, names[2]);
      await this.saveTexture(id, names[3]);
      await this.saveTexture(id, names[4]);
      await this.saveTexture(id, names[5]);
      await this.saveTexture(id, names[6]);
      await this.saveTexture(id, names[7]);
      await this.saveTexture(id, names[8]);
      await this.saveTexture(id, names[9]);
      await this.saveTexture(id, names[10]);
      await this.saveTexture(id, names[11]);
      await this.saveTexture(id, names[12]);
      await this.saveTexture(id, names[13]);
      await this.saveTexture(id, names[14]);
      await this.saveTexture(id, names[15]);

      showToast('Glaze has been saved');

      LogRocket.log('Saved successfully');

      LogRocket.track('SavedSuccessfully', {
        id,
      });
    } catch (e) {
      showToast('Some error during saving, try again.');

      LogRocket.log('SaveWithError');

      LogRocket.track('SaveWithError');

      loadingCompleted();
    }

    this.textureManager.clearByNames(['reflectanceTempTexture']);

    this.removeCanvas();
  }

  async share() {
    loadingInitialize(2);

    let author = 'Glaze';

    const description = {
      material: this.details.canvasType,
      width: this.details.canvasWidth,
      height: this.details.canvasHeight,
      author,
      preview: true,
    };

    try {
      const response = await createDescription(description);

      const id = response.data.name;

      const names = [['brdfTexture', 'thumbnail']];

      this.createCanvas();
      this.saveContext = this.canvas.getContext('2d');

      await this.saveTexture(id, names[0], true);

      const preview = new PreviewImage(this.textureManager);
      await preview.save(id);

      detailsSetSharedAs(id);

      loadingNextStage();

      LogRocket.log('Shared successfully');

      LogRocket.track('SharedSuccessfully', {
        id,
      });
    } catch (e) {
      showToast('Some error during saving, try again.');

      LogRocket.log('ShareWithError');

      LogRocket.track('ShareWithError');

      loadingCompleted();
    }

    this.removeCanvas();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');

    this.canvas.width = this.textureManager.width;
    this.canvas.height = this.textureManager.height;

    // this.canvas.style.position = 'fixed';
    // this.canvas.style.top = '-999999px';
    // this.canvas.style.left = '-999999px';
    // this.canvas.style.pointerEvents = 'none';
    //
    // document.body.appendChild(this.canvas);
  }

  removeCanvas() {
    this.canvas = null;

    //document.body.removeChild(this.canvas);
  }

  saveTexture(id, info, isPreview = false) {
    return new Promise((resolve, reject) => {
      const savePixels = this.textureManager.readPixels(info[0]);
      const imageData = flipYAxis(this.saveContext, savePixels, this.textureManager.width, this.textureManager.height);
      this.saveContext.putImageData(imageData, 0, 0);

      createFormDataFromCanvas(this.canvas)
        .then((formData) => (isPreview ? savePreview(id, info[1], formData) : saveTexture(id, info[1], formData)))
        .then((response) => {
          if (response.status === 200) {
            loadingNextStage();

            resolve();
          } else {
            reject();
          }
        })
        .catch((e) => {
          LogRocket.captureException(e, {
            tags: {
              action: 'Saving',
            },
            extra: {
              id,
              name: info[0],
              url: info[1],
            },
          });

          reject();
        });
    });
  }
}

export default Saver;
