import chalk1Svg from './assets/chalk-1.svg';
import chalk2Svg from './assets/chalk-2.svg';
import hog1Svg from './assets/hog-1.svg';
import hog2Svg from './assets/hog-2.svg';
import hog3Svg from './assets/hog-3.svg';
import rag1Svg from './assets/rag-1.svg';
import rag2Svg from './assets/rag-2.svg';
import sobol1Svg from './assets/sobol-1.svg';
import sobol2Svg from './assets/sobol-2.svg';
import sobol3Svg from './assets/sobol-3.svg';

const icons = {
  'chalk-1.json': chalk1Svg,
  'chalk-2.json': chalk2Svg,
  'hog-1.json': hog1Svg,
  'hog-2.json': hog2Svg,
  'hog-3.json': hog3Svg,
  'rag-1.json': rag1Svg,
  'rag-2.json': rag2Svg,
  'sobol-1.json': sobol1Svg,
  'sobol-2.json': sobol2Svg,
  'sobol-3.json': sobol3Svg,
};

export default icons;
