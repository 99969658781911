/* eslint-disable react-hooks/exhaustive-deps */
import Engine from '../../app/Engine';
import { loadingInitialize } from '../../store/loading/actions';
import Company from '../Company';
import Helper from '../Helper';
import Menu from '../Menu';
import ToolBox from '../ToolBox';
import useLoadMeasures from './hooks/useLoadMeasures';
import useLoadPresets from './hooks/useLoadPresets';
import useLoadShaders from './hooks/useLoadShaders';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// measures + presets + shaders + engine stages
const HOW_MANY_STAGES_IN_ENGINE = 4;
const HOW_MANY_STAGES = 1 + 1 + 1 + HOW_MANY_STAGES_IN_ENGINE;

const App = () => {
  const state = useSelector((s) => s);
  const { details, presets, measures } = state;
  const dispatch = useDispatch();
  const [canLoadData, setCanLoadData] = useState(false);
  const [ready, setReady] = useState(false);
  const engine = useRef();

  useLoadMeasures(canLoadData);
  const shaders = useLoadShaders(canLoadData);
  useLoadPresets(canLoadData);

  useEffect(() => {
    if (details?.id) {
      dispatch(loadingInitialize(HOW_MANY_STAGES));

      setCanLoadData(true);
    }
  }, [details?.id, dispatch]);

  useEffect(() => {
    if (shaders && presets && measures) {
      setReady(true);
    }
  }, [shaders, presets, measures]);

  useEffect(() => {
    if (ready) {
      if (!engine.current) {
        engine.current = new Engine(shaders, measures);
      }

      if (engine.current) {
        engine.current.update(state);
      }
    }
  }, [ready, state]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Menu engine={engine.current} />
      <ToolBox />
      <Helper />

      {details.isCompany && <Company engine={engine.current} />}
    </>
  );
};

export default App;
