import { controllingSetHelperStatus } from '../../store/controlling/actions';
import styles from './Helper.module.scss';
import questionSvg from './assets/question.svg';
import translations from './translations';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const keysTool = Object.keys(translations.en.descriptionTool);
const keysMenu = Object.keys(translations.en.descriptionMenu);

const Helpers = () => {
  const dispatch = useDispatch();
  const { openHelper } = useSelector((s) => s.controlling);
  const { isCompany } = useSelector((s) => s.details);
  const { t, i18n } = useTranslation();

  const lang = i18n.language || 'en';

  const descriptionTool = translations[lang].descriptionTool;
  const descriptionMenu = translations[lang].descriptionMenu;

  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          dispatch(controllingSetHelperStatus(true));
        }}
      >
        <img src={questionSvg} alt="Helper" />
      </button>

      {openHelper && (
        <div
          className={styles.overlay}
          onClick={() => {
            dispatch(controllingSetHelperStatus(false));
          }}
        >
          {keysTool.map((key) => (
            <div className={cn(styles.info, styles[key])}>
              <div className={styles.title}>{descriptionTool[key][0]}</div>
              <div className={styles.description}>{descriptionTool[key][1]}</div>
            </div>
          ))}

          <div className={cn(styles.menuTool, isCompany && styles.company)}>
            {keysMenu.map((key) => (
              <div className={cn(styles.info, styles[key])}>
                <div className={styles.title}>{descriptionMenu[key][0]}</div>
                <div className={styles.description}>{descriptionMenu[key][1]}</div>
              </div>
            ))}
          </div>

          <button className={styles.close}>{t('Close')}</button>
        </div>
      )}
    </>
  );
};

export default Helpers;
