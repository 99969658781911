const MAX_TEXTURE_SIZE = 2048;

const calculateTextureSizes = (givenW, givenH) => {
  const w = 1;
  const h = (w * givenH) / givenW;
  const p = Math.max(w, h);

  const scaleW = w / p;
  const scaleH = h / p;

  const width = Math.floor(scaleW * MAX_TEXTURE_SIZE);
  const height = Math.floor(scaleH * MAX_TEXTURE_SIZE);

  return [width, height];
};

export default calculateTextureSizes;
