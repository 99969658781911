import assertRectangle from './helpers/assertRectangle';
import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

class Surface {
  constructor(gl, shaders, textureManager) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.surfaceProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.surfaceFrag,
      ['a_position'],
      ['u_sourceTexture', 'u_brushTexture', 'u_paintingResolution', 'u_normalScale']
    );
  }

  calculateProperties() {
    this.properties = {};

    this.properties.normalScale = this.settings.minimalNormalScale;

    console.log('Surface properties calculated');
  }

  connect(rectangle) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.surfaceTempTexture,
      0
    );

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.enable(this.gl.BLEND);
    this.gl.blendEquation(this.gl.FUNC_ADD);
    this.gl.blendFuncSeparate(this.gl.ONE, this.gl.ZERO, this.gl.ONE, this.gl.ZERO);

    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    assertRectangle(rectangle);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

    this.gl.useProgram(this.surfaceProgram);

    this.gl.uniform2f(this.surfaceProgram.u_paintingResolution, this.textureManager.width, this.textureManager.height);
    this.gl.uniform1f(this.surfaceProgram.u_normalScale, this.properties.normalScale);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.surfaceTexture);
    this.gl.uniform1i(this.surfaceProgram.u_sourceTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatBrushPaintTexture);
    this.gl.uniform1i(this.surfaceProgram.u_brushTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.surfaceProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.surfaceProgram.a_position);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  update(settings) {
    this.settings = settings;

    this.calculateProperties();
  }
}

export default Surface;
