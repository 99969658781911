import styles from './Medium.module.scss';

class Preview {
  constructor(parent) {
    this.parent = parent;

    const container = document.createElement('div');
    container.className = styles.preview;

    const mediumColor = document.createElement('div');
    mediumColor.className = styles.mediumColor;
    container.appendChild(mediumColor);

    this.impastoColor = document.createElement('div');
    this.impastoColor.className = styles.impastoColor;
    container.appendChild(this.impastoColor);

    this.currentColorContainer = document.createElement('div');
    this.currentColorContainer.className = styles.currentColor;
    container.appendChild(this.currentColorContainer);

    this.parent.appendChild(container);
  }

  takeColor(color) {
    this.currentColorContainer.style.background = color;
  }

  updateMainColor(state) {
    const colorModel = state.measures.colors[state.controlling.buckets[state.controlling.selectedBucket]];

    this.impastoColor.style.background = colorModel.color;
  }
}

export default Preview;
