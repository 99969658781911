import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

const MINIMAL_SIZE_SCALE = 0.01;

const calculateStartingCoefficient = (pressure, isSobol) => {
  if (isSobol) {
    return MINIMAL_SIZE_SCALE + (1.0 - MINIMAL_SIZE_SCALE) * pressure;
  }

  return 1.0;
};

class Brush {
  startingCoefficient = undefined;

  constructor(gl, shaders, textureManager) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;

    this.createBuffers();
    this.createPrograms();
  }

  update(settings) {
    this.settings = settings;

    console.log('Brush settings updated');
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.projectProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.projectFrag,
      ['a_position'],
      [
        'u_gravity',
        'u_damping',
        'u_verticesPerBristle',
        'u_resolution',
        'u_positionsTexture',
        'u_velocitiesTexture',
        'u_randomsTexture',
      ]
    );

    this.distanceConstraintProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.distanceConstraintFrag,
      ['a_position'],
      ['u_pointCount', 'u_targetDistance', 'u_pass', 'u_resolution', 'u_positionsTexture']
    );

    this.planeConstraintProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.planeConstraintFrag,
      ['a_position'],
      ['u_positionsTexture', 'u_resolution']
    );

    this.bendingConstraintProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.bendingConstraintFrag,
      ['a_position'],
      ['u_pointCount', 'u_stiffnessVariation', 'u_pass', 'u_resolution', 'u_positionsTexture', 'u_randomsTexture']
    );

    this.setBristlesProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.setBristlesFrag,
      ['a_position'],
      [
        'u_brushPosition',
        'u_brushScale',
        'u_bristleCount',
        'u_bristleLength',
        'u_verticesPerBristle',
        'u_jitter',
        'u_resolution',
        'u_randomsTexture',
      ]
    );

    this.updateVelocityProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.updateVelocityFrag,
      ['a_position'],
      ['u_resolution', 'u_positionsTexture', 'u_projectedPositionsTexture']
    );
  }

  createBrushTextureCoordinatesBuffer() {
    const brushTextureCoordinates = [];

    for (let bristle = 0; bristle < this.settings.bristleCount; ++bristle) {
      for (let vertex = 0; vertex < this.settings.verticesPerBristle; ++vertex) {
        const textureX = (bristle + 0.5) / this.settings.bristleCount;
        const textureY = (vertex + 0.5) / this.settings.verticesPerBristle;

        brushTextureCoordinates.push(textureX);
        brushTextureCoordinates.push(textureY);
      }
    }

    if (this.brushTextureCoordinatesBuffer) {
      this.gl.deleteBuffer(this.brushTextureCoordinatesBuffer);
    }

    this.brushTextureCoordinatesBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.brushTextureCoordinatesBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(brushTextureCoordinates), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  createSplatBuffers() {
    const splatCoordinates = [];
    const splatIndices = [];

    let splatIndex = 0;

    const splatsPerSegment = Math.floor(this.settings.splatsPerSegment);

    for (let bristle = 0; bristle < this.settings.bristleCount; ++bristle) {
      for (let vertex = 0; vertex < this.settings.verticesPerBristle - 1; ++vertex) {
        // we create a quad for each bristle vertex

        for (let i = 0; i < splatsPerSegment; ++i) {
          const t = (i + 0.5) / splatsPerSegment;

          const textureX = (bristle + 0.5) / this.settings.bristleCount;
          const textureY = (vertex + 0.5 + t) / this.settings.verticesPerBristle;

          //bottom left
          splatCoordinates.push(textureX);
          splatCoordinates.push(textureY);
          splatCoordinates.push(-1);
          splatCoordinates.push(-1);

          //bottom right
          splatCoordinates.push(textureX);
          splatCoordinates.push(textureY);
          splatCoordinates.push(1);
          splatCoordinates.push(-1);

          //top right
          splatCoordinates.push(textureX);
          splatCoordinates.push(textureY);
          splatCoordinates.push(1);
          splatCoordinates.push(1);

          //top left
          splatCoordinates.push(textureX);
          splatCoordinates.push(textureY);
          splatCoordinates.push(-1);
          splatCoordinates.push(1);

          splatIndices.push(splatIndex + 0);
          splatIndices.push(splatIndex + 1);
          splatIndices.push(splatIndex + 2);

          splatIndices.push(splatIndex + 2);
          splatIndices.push(splatIndex + 3);
          splatIndices.push(splatIndex + 0);

          splatIndex += 4;
        }
      }
    }

    if (this.splatCoordinatesBuffer) {
      this.gl.deleteBuffer(this.splatCoordinatesBuffer);
    }

    this.splatCoordinatesBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.splatCoordinatesBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(splatCoordinates), this.gl.STATIC_DRAW);

    if (this.splatIndexBuffer) {
      this.gl.deleteBuffer(this.splatIndexBuffer);
    }

    this.splatIndexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.splatIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(splatIndices), this.gl.STATIC_DRAW);

    this.splatIndexCount = splatIndices.length;
  }

  createBrushIndexBuffer() {
    const brushIndices = [];
    this.indexCount = 0;

    for (let bristle = 0; bristle < this.settings.bristleCount; ++bristle) {
      for (let vertex = 0; vertex < this.settings.verticesPerBristle - 1; ++vertex) {
        const left = bristle * this.settings.verticesPerBristle + vertex;
        const right = left + 1;

        brushIndices.push(left);
        brushIndices.push(right);

        this.indexCount += 2;
      }
    }

    if (this.brushIndexBuffer) {
      this.gl.deleteBuffer(this.brushIndexBuffer);
    }

    this.brushIndexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.brushIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(brushIndices), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, null);
  }

  createSpeedArray() {
    this.speeds = []; // most recent speed is stored at the highest index

    for (let i = 0; i < this.settings.nPreviousSpeeds; ++i) {
      this.speeds.push(0);
    }
  }

  getFilteredSpeed() {
    return this.speeds.reduce(function (a, b) {
      return Math.max(a, b);
    });
  }

  runProjectProgram() {
    this.gl.useProgram(this.projectProgram);
    this.gl.viewport(0, 0, this.settings.bristleCount, this.settings.verticesPerBristle);

    this.gl.uniform1f(this.projectProgram.u_gravity, this.settings.brushGravity);
    this.gl.uniform1f(this.projectProgram.u_damping, this.settings.brushDamping);
    this.gl.uniform1f(this.projectProgram.u_verticesPerBristle, this.settings.verticesPerBristle);
    this.gl.uniform2f(this.projectProgram.u_resolution, this.settings.bristleCount, this.settings.verticesPerBristle);

    // Activate textures
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPositionsTexture);
    this.gl.uniform1i(this.projectProgram.u_positionsTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushVelocitiesTexture);
    this.gl.uniform1i(this.projectProgram.u_velocitiesTexture, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushRandomsTexture);
    this.gl.uniform1i(this.projectProgram.u_randomsTexture, 2);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.projectProgram.a_position, 2, this.gl.FLOAT, false, 0, 0); // feed it
    this.gl.enableVertexAttribArray(this.projectProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brushProjectedPositionsTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  runSetBristlesProgram(width, height, texture) {
    this.gl.useProgram(this.setBristlesProgram);
    this.gl.viewport(0, 0, width, height);

    const brushScale = this.settings.brushScale * this.startingCoefficient;

    this.gl.uniform3f(this.setBristlesProgram.u_brushPosition, this.positionX, this.positionY, this.positionZ);
    this.gl.uniform1f(this.setBristlesProgram.u_brushScale, brushScale);
    this.gl.uniform1f(this.setBristlesProgram.u_bristleCount, this.settings.bristleCount);
    this.gl.uniform1f(this.setBristlesProgram.u_bristleLength, this.settings.bristleLength);
    this.gl.uniform1f(this.setBristlesProgram.u_jitter, this.settings.bristleJitter);
    this.gl.uniform1f(this.setBristlesProgram.u_verticesPerBristle, this.settings.verticesPerBristle);
    this.gl.uniform2f(
      this.setBristlesProgram.u_resolution,
      this.settings.bristleCount,
      this.settings.verticesPerBristle
    );

    // Activate texture
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushRandomsTexture);
    this.gl.uniform1i(this.setBristlesProgram.u_randomsTexture, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.setBristlesProgram.a_position, 2, this.gl.FLOAT, false, 0, 0); // feed it
    this.gl.enableVertexAttribArray(this.setBristlesProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, texture, 0);

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  runDistanceConstraintProgram(pass) {
    this.gl.useProgram(this.distanceConstraintProgram);
    this.gl.viewport(0, 0, this.settings.bristleCount, this.settings.verticesPerBristle);

    const brushScale = this.settings.brushScale * this.startingCoefficient;
    const targetDistance = (brushScale * this.settings.bristleLength) / (this.settings.verticesPerBristle - 1);

    this.gl.uniform1f(this.distanceConstraintProgram.u_pointCount, this.settings.verticesPerBristle);
    this.gl.uniform1f(this.distanceConstraintProgram.u_targetDistance, targetDistance);
    this.gl.uniform1i(this.distanceConstraintProgram.u_pass, pass);
    this.gl.uniform2f(
      this.distanceConstraintProgram.u_resolution,
      this.settings.bristleCount,
      this.settings.verticesPerBristle
    );

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushProjectedPositionsTexture);
    this.gl.uniform1i(this.distanceConstraintProgram.u_positionsTexture, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.distanceConstraintProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.distanceConstraintProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brushProjectedPositionsTempTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  runBendingConstraintProgram(pass) {
    this.gl.useProgram(this.bendingConstraintProgram);
    this.gl.viewport(0, 0, this.settings.bristleCount, this.settings.verticesPerBristle);

    this.gl.uniform1f(this.bendingConstraintProgram.u_pointCount, this.settings.verticesPerBristle);
    this.gl.uniform1f(this.bendingConstraintProgram.u_stiffnessVariation, this.settings.stiffnessVariation);
    this.gl.uniform1i(this.bendingConstraintProgram.u_pass, pass);
    this.gl.uniform2f(
      this.bendingConstraintProgram.u_resolution,
      this.settings.bristleCount,
      this.settings.verticesPerBristle
    );

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushProjectedPositionsTexture);
    this.gl.uniform1i(this.bendingConstraintProgram.u_positionsTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushRandomsTexture);
    this.gl.uniform1i(this.bendingConstraintProgram.u_randomsTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.bendingConstraintProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.bendingConstraintProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brushProjectedPositionsTempTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  runPlaneConstraintProgram() {
    this.gl.useProgram(this.planeConstraintProgram);
    this.gl.viewport(0, 0, this.settings.bristleCount, this.settings.verticesPerBristle);

    this.gl.uniform2f(
      this.planeConstraintProgram.u_resolution,
      this.settings.bristleCount,
      this.settings.verticesPerBristle
    );

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushProjectedPositionsTexture);
    this.gl.uniform1i(this.planeConstraintProgram.u_positionsTexture, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.planeConstraintProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.planeConstraintProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brushProjectedPositionsTempTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  runUpdateVelocityProgram() {
    this.gl.useProgram(this.updateVelocityProgram);
    this.gl.viewport(0, 0, this.settings.bristleCount, this.settings.verticesPerBristle);

    this.gl.uniform2f(
      this.updateVelocityProgram.u_resolution,
      this.settings.bristleCount,
      this.settings.verticesPerBristle
    );

    // Activate textures
    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushPositionsTexture);
    this.gl.uniform1i(this.updateVelocityProgram.u_positionsTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.brushProjectedPositionsTexture);
    this.gl.uniform1i(this.updateVelocityProgram.u_projectedPositionsTexture, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.updateVelocityProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.updateVelocityProgram.a_position);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.brushPreviousVelocitiesTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
  }

  initialize(x, y) {
    if (!this.settings) {
      console.log('no settings in Brush');

      this.startingCoefficient = 0.0;
      this.positionZ = 0.0;
    } else {
      this.startingCoefficient = this.settings.controlSizeByPressure ? 0.0 : 1.0;

      const brushScale = this.settings.brushScale * this.startingCoefficient;

      this.positionZ = this.settings.brushHeight * brushScale;
    }

    this.positionX = x;
    this.positionY = y;

    this.reset();
  }

  setPosition(x, y, pressure) {
    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    // what about pressure?
    const dx = x - this.positionX;
    const dy = y - this.positionY;
    // const dz = z - this.positionZ;

    const speed = Math.sqrt(dx * dx + dy * dy);

    this.speeds.shift();
    this.speeds.push(speed);

    this.startingCoefficient = calculateStartingCoefficient(pressure, this.settings.controlSizeByPressure);

    const brushScale = this.settings.brushScale * this.startingCoefficient;

    this.positionX = x;
    this.positionY = y;
    this.positionZ = this.settings.brushHeight * brushScale;

    this.runProjectProgram();

    this.runSetBristlesProgram(this.settings.bristleCount, 1, this.textureManager.brushProjectedPositionsTexture);

    for (let i = 0; i < this.settings.brushIterations; ++i) {
      this.runSetBristlesProgram(this.settings.bristleCount, 1, this.textureManager.brushProjectedPositionsTexture);

      for (let pass = 0; pass < 2; ++pass) {
        this.runDistanceConstraintProgram(pass);

        this.textureManager.swap('brushProjectedPositionsTexture', 'brushProjectedPositionsTempTexture');
      }

      for (let pass = 0; pass < 3; ++pass) {
        this.runBendingConstraintProgram(pass);

        this.textureManager.swap('brushProjectedPositionsTexture', 'brushProjectedPositionsTempTexture');
      }

      this.runPlaneConstraintProgram();

      this.textureManager.swap('brushProjectedPositionsTexture', 'brushProjectedPositionsTempTexture');
    }

    this.runUpdateVelocityProgram();

    this.textureManager.swap('brushVelocitiesTexture', 'brushPreviousVelocitiesTexture');

    this.textureManager.swap('brushPreviousPositionsTexture', 'brushPositionsTexture');
    this.textureManager.swap('brushPositionsTexture', 'brushProjectedPositionsTexture');
  }

  reset() {
    this.textureManager.createBrushTextures(this.settings.bristleCount, this.settings.verticesPerBristle);
    this.textureManager.createRandomTexture(
      this.settings.bristleCount,
      this.settings.verticesPerBristle,
      'brushRandomsTexture'
    );

    this.createBrushTextureCoordinatesBuffer();
    this.createSplatBuffers();
    this.createBrushIndexBuffer();
    this.createSpeedArray();

    this.runSetBristlesProgram(
      this.settings.bristleCount,
      this.settings.verticesPerBristle,
      this.textureManager.brushPositionsTexture
    );
  }
}

export default Brush;
