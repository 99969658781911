import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

const operations = {
  INTERSECTION: 0,
  DIFFERENCE: 1,
};

class TextureMixer {
  constructor(gl, shaders, textureManager) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.setOperationProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.setOperationFrag,
      ['a_position'],
      ['u_source1', 'u_source2', 'u_operation']
    );
  }

  operation(s1Name, s2Name, targetName, operation) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.useProgram(this.setOperationProgram);

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);
    this.gl.disable(this.gl.SCISSOR_TEST);

    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    this.gl.uniform1i(this.setOperationProgram.u_operation, operation);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[s1Name]);
    this.gl.uniform1i(this.setOperationProgram.u_source1, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[s2Name]);
    this.gl.uniform1i(this.setOperationProgram.u_source2, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.enableVertexAttribArray(this.setOperationProgram.a_position);
    this.gl.vertexAttribPointer(this.setOperationProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager[targetName],
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  intersection(s1Name, s2Name, targetName) {
    this.operation(s1Name, s2Name, targetName, operations.INTERSECTION);
  }

  difference(s1Name, s2Name, targetName) {
    this.operation(s1Name, s2Name, targetName, operations.DIFFERENCE);
  }
}

export default TextureMixer;
