import { getMeasures } from '../../../api';
import { IS_PRODUCTION } from '../../../settings';
import { errorCannotLoadData } from '../../../store/error/actions';
import { loadingNextStage } from '../../../store/loading/actions';
import { measuresSet } from '../../../store/measures/actions';
import cutColors from '../helpers/cutColors';
import obfuscate from '../utils/obfuscate';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useLoadMeasures = (canLoadData) => {
  const dispatch = useDispatch();

  // load measures data
  useEffect(() => {
    const get = async () => {
      try {
        const response = await getMeasures(!IS_PRODUCTION);
        const data = obfuscate(response.data);

        const measures = IS_PRODUCTION ? JSON.parse(data) : data;

        dispatch(
          measuresSet({
            ...measures,
            colors: cutColors(measures.colors),
          })
        );
        dispatch(loadingNextStage());
      } catch {
        dispatch(errorCannotLoadData());
      }
    };

    if (canLoadData) {
      get();
    }
  }, [dispatch, canLoadData]);
};

export default useLoadMeasures;
