/* eslint-disable @typescript-eslint/default-param-last */
import { DETAILS_SET, DETAILS_SET_PREMIUM, DETAILS_SET_SHARED_AS } from './actions';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DETAILS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case DETAILS_SET_PREMIUM:
      return {
        ...state,
        isPremium: action.payload,
      };

    case DETAILS_SET_SHARED_AS:
      return {
        ...state,
        sharedAs: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
