import {
  ERROR_NOT_FOUND,
  ERROR_UNAUTHORIZED,
  ERROR_UNSUPPORTED_DEVICE,
  ERROR_CANNOT_LOAD_DATA,
} from '../../store/error/actions';
import CannotLoadData from '../CannotLoadData';
import NoResource from '../NoResource';
import UnsupportedDevice from '../UnsupportedDevice';
import { useSelector } from 'react-redux';

const Error = () => {
  const { type, details } = useSelector((s) => s.error);
  const { backTo, companyName } = details || {};

  if (type === ERROR_UNSUPPORTED_DEVICE) {
    return <UnsupportedDevice />;
  }

  if (type === ERROR_CANNOT_LOAD_DATA) {
    return <CannotLoadData />;
  }

  if (type === ERROR_UNAUTHORIZED || type === ERROR_NOT_FOUND) {
    return (
      <NoResource
        companyName={companyName}
        backToCompanyPage={backTo === 'company'}
        backToUserPage={backTo === 'user'}
        backToMainPage={backTo === 'main'}
      />
    );
  }

  return null;
};

export default Error;
