import { ADMIN_URL, API_URL, CACHE_IMAGES } from '../../settings';

const urlForImage = (id, item, type, fromLocalDomain = false, isCompany = false) => {
  if (!type && isCompany) {
    return `${API_URL}/sketches/${id || 0}/${item}.png${CACHE_IMAGES ? '' : `?t=${Date.now()}`}`;
  } else if (!fromLocalDomain) {
    return `${ADMIN_URL}/painting/${id}/${item}${CACHE_IMAGES ? '' : `?t=${Date.now()}`}`;
  } else {
    return `${API_URL}/sketches/source-${type}/${item}.png${CACHE_IMAGES ? '' : `?t=${Date.now()}`}`;
  }
};

export default urlForImage;
