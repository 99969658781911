import {
  controllingSetOpenMenu,
  controllingSetOpenOptions,
  controllingSetPresetType,
} from '../../store/controlling/actions';
import About from '../About';
import Drawer from '../Drawer';
import Options from '../Options';
import Palette from '../Palette';
import Tools from '../Tools';
import Upgrade from '../Upgrade';
import styles from './Menu.module.scss';
import logoSvg from './assets/logo.svg';
import menuSvg from './assets/menu.svg';
import paletteSvg from './assets/palette.svg';
import timeSvg from './assets/time.svg';
import icons from './icons';
import cn from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const types = ['chalk', 'hog', 'sobol', 'rag']; // sorted

const freeAccess = ['chalk', 'hog', 'sobol'];

const Menu = ({ engine }) => {
  const { defaultPresets, presetType, openMenu } = useSelector((s) => s.controlling);
  const { isPremium, isCompany } = useSelector((s) => s.details);
  const dispatch = useDispatch();
  const [about, setAbout] = useState(false);
  const [rotations, setRotations] = useState(0);

  const selectOrOpen = (type) => {
    return () => {
      if (openMenu === type) {
        dispatch(controllingSetOpenMenu(undefined));
      } else {
        if (presetType === type) {
          if (openMenu) {
            dispatch(controllingSetOpenMenu(undefined));
          } else {
            dispatch(controllingSetOpenMenu(type));
          }
        } else {
          if (isPremium || freeAccess.includes(type)) {
            dispatch(controllingSetPresetType(type));
            dispatch(controllingSetOpenMenu(undefined));
          } else {
            dispatch(controllingSetOpenMenu(type));
          }
        }
      }
    };
  };

  const justOpen = () => {
    if (openMenu === 'palette') {
      dispatch(controllingSetOpenMenu(undefined));
    } else {
      dispatch(controllingSetOpenMenu('palette'));
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.menu}>
          <div
            className={styles.logo}
            onClick={() => {
              setAbout(true);
            }}
          >
            <img src={logoSvg} alt="Glaze" />
          </div>

          <ul>
            {types.map((type) => {
              return (
                <li
                  className={cn(type === presetType && styles.activeTool, openMenu === type && styles.open)}
                  onClick={selectOrOpen(type)}
                >
                  <div className={styles.icon}>
                    <img src={icons[defaultPresets[type]]} alt={type} className={styles[`${type}Img`]} />
                  </div>
                </li>
              );
            })}

            <div className={styles.separator} />

            <li className={cn(openMenu === 'palette' && styles.open)} onClick={justOpen}>
              <div className={cn(styles.icon, styles.palette)}>
                <img src={paletteSvg} alt="palette" />
              </div>
            </li>

            <li
              onClick={() => {
                engine.dry();

                setRotations((r) => r + 1);
              }}
            >
              <div className={cn(styles.icon, styles.time)}>
                <img src={timeSvg} alt="dry" />

                <div
                  style={{
                    transform: `rotate(${rotations * 360}deg)`,
                  }}
                  className={styles.indicator}
                />
              </div>
            </li>
          </ul>

          {!isCompany && (
            <div className={styles.optionsButton}>
              <div
                className={styles.button}
                onClick={() => {
                  dispatch(controllingSetOpenOptions(true));
                }}
              >
                <img src={menuSvg} alt="Menu" />
              </div>
            </div>
          )}
        </div>
      </div>

      {about && (
        <About
          handleClose={() => {
            setAbout(false);
          }}
        />
      )}

      <Options engine={engine} />

      {openMenu === 'palette' && <Palette />}
      {openMenu === 'chalk' && <Tools type="chalk" />}
      {openMenu === 'sobol' && <Tools type="sobol" />}
      {openMenu === 'hog' && <Tools type="hog" />}
      {openMenu === 'rag' && isPremium && <Tools type="rag" />}
      {openMenu === 'rag' && !isPremium && (
        <Drawer>
          <Upgrade />
        </Drawer>
      )}
    </>
  );
};

export default Menu;
