import mix from '../helpers/mix';

function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

const calculateSplatForBrush = (_pressure, startingCoefficient, properties, settings) => {
  let pressure;

  if (settings.useAsPencil) {
    pressure = Math.pow(_pressure, 1.5);
  } else {
    pressure = easeOutCubic(_pressure);
  }

  const alphaT = 1.0;

  const minAlpha = mix(settings.thinMinAlpha, settings.thickMinAlpha, pressure);
  const maxAlpha = mix(settings.thinMaxAlpha, settings.thickMaxAlpha, pressure);
  const alpha = mix(minAlpha, maxAlpha, alphaT);

  const enhancedSplatRadius = properties.splatRadius * pressure;

  const startingAlpha = alpha * startingCoefficient;

  const color = [...properties.color];
  color[3] = startingAlpha;

  const splatVelocityScale = settings.splatVelocityScale * startingAlpha;

  return {
    splatRadius: enhancedSplatRadius,
    color,
    zThreshold: properties.zThreshold,
    splatVelocityScale,
  };
};

export default calculateSplatForBrush;
