const texts = {
  david:
    'Born August 30, 1748 in Paris, died December 29, 1825 in Brussels - French painter, the main representative of Classicism, court painter of Napoleon Bonaparte. Considered a pillar of the art of the Enlightenment, the official artist of the French Revolution created a clear and carrying revolutionary symbol - the Oath of Horatians.',
  kauffmann:
    'Usually known in English as Angelica Kauffman (30 October 1741 – 5 November 1807), was a Swiss Neoclassical painter who had a successful career in London and Rome. Remembered primarily as a history painter, Kauffmann was a skilled portraitist, landscape and decoration painter. She was, along with Mary Moser, one of two female painters among the founding members of the Royal Academy in London in 1768.',
  lievens:
    "Born 24 October 1607 – died 4 June 1674. Was a Dutch Golden Age painter who was associated with his close contemporary Rembrandt, a year older, in the early parts of their careers. They shared a birthplace in Leiden, training with Pieter Lastman in Amsterdam, where they shared a studio for about five years until 1631. Like Rembrandt he painted both portraits and history paintings, but unlike him Lievens' career took him away from Amsterdam to London, Antwerp, The Hague and Berlin. ",
  rubens:
    "Born 28 June 1577 – died 30 May 1640. Was a Flemish artist and diplomat from the Duchy of Brabant in the Southern Netherlands (modern-day Belgium).He is considered the most influential artist of the Flemish Baroque tradition. Rubens's highly charged compositions reference erudite aspects of classical and Christian history. His unique and immensely popular Baroque style emphasized movement, colour, and sensuality, which followed the immediate, dramatic artistic style promoted in the Counter-Reformation.",
  batoni:
    'Italian painter (25 January 1708 – 4 February 1787) who displayed a solid technical knowledge in his portrait work and in his numerous allegorical and mythological pictures. The high number of foreign visitors travelling throughout Italy and reaching Rome during their "Grand Tour" led the artist to specialize in portraits. Batoni won international fame largely thanks to his customers, mostly British of noble origin, whom he portrayed, often with famous Italian landscapes in the background. Such Grand Tour portraits by Batoni were in British private collections, thus ensuring the genre\'s popularity in Great Britain.',
  potocki:
    "The Portrait of Count Stanislas Potocki is a 1781 equestrian portrait of Polish patron, politician and writer Stanisław Kostka Potocki by the French painter Jacques-Louis David. It was painted in Rome when the artist and subject met during David's stay at the Villa Medici after winning the first prize for painting in the Prix de Rome, and chronologically after his Saint Roch interceding with the Virgin for the Plague-Stricken and before Belisarius begging for alms. Its equestrian format is owed to influences from Rubens.",
  lastJudgment:
    'The Last Judgment is a triptych attributed to Flemish painter Hans Memling and painted between 1467 and 1471. It is now in the National Museum in Gdańsk in Poland. It was commissioned by Angelo Tani, an agent of the Medici at Bruges, but was captured at sea by Paul Beneke, a privateer from Danzig. A lengthy lawsuit against the Hanseatic League demanded its return to Italy. It was placed in the Basilica of the Assumption but in the 20th century it was moved to its present location.',
  ladyErmine:
    'The Lady with an Ermine is a portrait painting widely attributed to the Italian Renaissance artist Leonardo da Vinci. Dated to c. 1489–1491, the work is painted in oils on a panel of walnut wood. Its subject is Cecilia Gallerani, a mistress of Ludovico Sforza ("Il Moro"), Duke of Milan; Leonardo was painter to the Sforza court in Milan at the time of its execution. It is the second of only four surviving portraits of women painted by Leonardo, the others being Ginevra de\' Benci, La Belle Ferronnière and the Mona Lisa.',
  gameChess:
    'Sofonisba Anguissola is an outstanding Italian Renaissance painter, one of the first, few modern artists to gain great popularity and success during her lifetime. Giorgio Vasari himself admired her "The Game of Chess", painted in 1555, being greatly impressed by her talent and artistic craftsmanship. The painting shows four women: the artist\'s three sisters standing at the chessboard and their elderly guardian emerging from the right side of the canvas. In the 19th century, the painting was purchased in Berlin by Atanazy Raczyński for his collection. Currently, the canvas is in the collection of the Raczyński family Foundation and is on display in the main building of the National Museum in Poznań.',
  pourville:
    'Beach in Pourville (title in French: La plage à Pourville, soleil couchant) is a painting by French artist Claude Monet. It is one of an 1882 series of oil-on-canvas works by Monet in the small seaside resort of Pourville-sur-Mer (now part of the commune of Hautot-sur-Mer), near Dieppe in northern France. Monet is considered one of the most important members of the group of painters identified as the Impressionists. The painting was bought by the National Museum, Poznań, Poland in 1906 and exhibited in the display of their collection.',
};

export default texts;
