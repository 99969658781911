const pl = {
  Save: 'Zapisz',
  'Fit to screen': 'Dopasuj do ekranu',
  Exit: 'Wyjdź',
  Version: 'Wersja',
  Build: 'Kompilacja',
  'Created in Poland': 'Stworzone w Polsce',
  'All rights reserved.': 'Wszelkie prawa zastrzeżone.',
  'Back to home': 'Powrót',
  "We couldn't load required data.": 'Nie mogliśmy załadować danych.',
  'Check your Internet connection and reload app.': 'Sprawdź połączenie z Internetem i sporóbuj ponownie.',
  Yes: 'Tak',
  No: 'Nie',
  'Close automatically when loaded': 'Zamykaj po załadowaniu',
  Continue: 'Dalej',
  'Access to app is available only with valid credentials.': 'Dostęp do aplikacji jest zastrzeżony.',
  'Go to dashboard and click directly on link to start painting.': 'Przejdź do aplikacji używając linka w panelu.',
  'Back to dashboard': 'Przejdź do panelu',
  'Are you sure?': 'Czy na pewno?',
  'Your device is not supported.': 'Twoje urządzenie nie jest wspierane.',
  'Keep an eye on our site for updates.': 'Sledź naszą stronę, by dowiedzieć się o aktualizacjach.',
  'Your tools are': 'Twoje narzędzia są',
  limited: 'ograniczone',
  'Thank you for trying out the': 'Dziękujemy za skorzystanie z',
  'You are on a': 'Twój plan to',
  Basic: 'Plan Podstawowy.',
  'plan.': '',
  'To see the full experience, please visit': 'Aby uzyskać dostęp do pełnej wersji aplikacji, odwiedź',
  'sign up and subscribe.': 'zarejestruj się i subskrybuj.',
  'We hope to see you soon :)': 'Do zobaczenia wkrótce :)',
  'Glaze Team': 'Zespół Glaze',
  'Ready!': 'Gotowe!',
  'Do you want to leave app?': 'Czy chcesz opuścić aplikację?',
  Congratulations: 'Gratulacje',
  'You can see your work online.': 'Twoja praca jest dostępna online',
  'Scan QR code': 'Zeskanuj kod QR',
  'or copy link below': 'badż skopiuj poniższy link',
  chalk: 'kreda',
  rag: 'szmatka',
  Close: 'Zamknij',
  'Please start 14 days trial version or change the plan to': 'Rozpocznij 14 dniowy Trial lub kup plan',
  ' to get full access.': ', aby mieć wszystkie narzędzia.',
  'Start 14 days trial': '14 dniowy Trial',
  'Change plan to pro': 'Kup plan PRO',
  verification: '- weryfikacja',
  'Please click the button below': 'Proszę kliknąć na przycisk poniżej,',
  'to verify your': 'aby zweryfikować czy',
  subscription: 'subskrypcja',
  'is activated.': 'została aktywowana.',
  Verify: 'Weryfikuj',
  'Verifying...': 'Weryfikowanie...',
  'No Pro Plan': 'Brak planu PRO',
  'Some error during saving, try again.': 'Wystąpił błąd podczas zapisu, spróbuj ponownie.',
  'Glaze has been saved': 'Obraz został zapisany',
};

export default pl;
