const canvasToBlob = async (canvas) =>
  new Promise((resolve) =>
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        }
      },
      'image/png',
      1.0
    )
  );

const createFormDataFromCanvas = async (canvas) => {
  const formData = new FormData();

  const blob = await canvasToBlob(canvas);

  formData.append('file', blob, 'snapshot.png');

  return formData;
};

export default createFormDataFromCanvas;
