import App from './components/App';
import Error from './components/Error';
import Initializer from './components/Initializer';
import LoadingBox from './components/LoadingBox';
import ThrowError from './components/ThrowError';
import { globalDebug } from './helpers/globalDebug';
import './i18n';
import './reset';
import { HIDE_CONSOLE_LOGS, LOG_ROCKET_KEY, VERSION } from './settings';
import store from './store/store';
import './theme/index.scss';
import LogRocket from 'logrocket';
import { render } from 'preact';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

LogRocket.init(LOG_ROCKET_KEY, {
  release: VERSION,
});

const Root = () => {
  useEffect(() => {
    if (HIDE_CONSOLE_LOGS) {
      globalDebug(false, true);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/">
              <Route index element={<ThrowError />} />
              <Route path=":company/:id" element={<Initializer />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>

          <App />

          <LoadingBox />
          <Error />
        </Provider>
      </BrowserRouter>

      <Toaster
        position="top-center"
        toastOptions={{
          duration: 1500,
        }}
      />
    </>
  );
};

const start = () => {
  render(<Root />, document.getElementById('root'));
};

start();
