const fillArray = (min, max) => {
  const arr = [];

  for (let i = min; i <= max; i++) {
    arr.push(i);
  }

  return arr;
};

export default fillArray;
