/* eslint-disable @typescript-eslint/default-param-last */
import {
  CONTROLLING_SET_DEFAULT_PRESETS,
  CONTROLLING_SET_OPEN_MENU,
  CONTROLLING_SET_OPEN_OPTIONS,
  CONTROLLING_SET_PRESET_TYPE,
  CONTROLLING_SET_DEFAULT_PRESET,
  CONTROLLING_CHANGE_BUCKETS,
  CONTROLLING_SELECT_BUCKET,
  CONTROLLING_SET_HELPER_STATUS,
} from './actions';

const initialState = {
  defaultPresets: undefined,
  presetType: undefined,
  openMenu: undefined,
  openOptions: false,
  openHelper: false,
  selectedBucket: 0,
  buckets: [0, 1, 2],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTROLLING_SET_DEFAULT_PRESETS:
      return {
        ...state,
        defaultPresets: action.payload,
      };

    case CONTROLLING_SET_DEFAULT_PRESET:
      return {
        ...state,
        defaultPresets: {
          ...state.defaultPresets,
          [action.payload.type]: action.payload.name,
        },
        presetType: action.payload.type,
      };

    case CONTROLLING_SET_PRESET_TYPE:
      return {
        ...state,
        presetType: action.payload,
      };

    case CONTROLLING_SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload,
      };

    case CONTROLLING_SET_OPEN_OPTIONS:
      return {
        ...state,
        openOptions: action.payload,
      };

    case CONTROLLING_CHANGE_BUCKETS:
      const newArr = [...state.buckets];
      newArr[action.payload.position] = action.payload.index;

      return {
        ...state,
        buckets: newArr,
      };

    case CONTROLLING_SELECT_BUCKET:
      return {
        ...state,
        selectedBucket: action.payload,
      };

    case CONTROLLING_SET_HELPER_STATUS:
      return {
        ...state,
        openHelper: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
