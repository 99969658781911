import { DOMAIN } from '../../settings';
import Confirm from '../Confirm';
import styles from './BackToHome.module.scss';
import backSvg from './assets/back.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BackToHome = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { companyId } = useSelector((s) => s.details);
  const { t } = useTranslation();

  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        <div className={styles.circle}>
          <img src={backSvg} alt={t('Back to home')} />
        </div>
        <div className={styles.text}>{t('Back to home')}</div>
      </button>

      {showConfirm && (
        <Confirm
          text={t('Do you want to leave app?')}
          handleNo={() => {
            setShowConfirm(false);
          }}
          handleYes={() => {
            window.location.href = `//${companyId}.${DOMAIN}`;
          }}
        />
      )}
    </>
  );
};

export default BackToHome;
