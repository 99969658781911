import { ADMIN_URL, API_URL, APP_URL } from '../settings';
import axios from 'axios';

export const adminInstance = axios.create({
  baseURL: ADMIN_URL,
  timeout: 180000,
});

export const apiInstance = axios.create({
  baseURL: API_URL,
  timeout: 180000,
});

export const appInstance = axios.create({
  baseURL: APP_URL,
  timeout: 180000,
});
