import AngelicaKauffmann from '../assets/AngelicaKauffmann.jpg';
import Chess from '../assets/Chess.jpg';
import David from '../assets/David.jpg';
import JanLievens from '../assets/JanLievens.jpg';
import LadyWithAnErmine from '../assets/LadyWithAnErmine.jpg';
import PeterPaulRubens from '../assets/PeterPaulRubens.jpg';
import PompeoBatoni from '../assets/PompeoBatoni.jpg';
import Potocki from '../assets/Potocki.jpg';
import Pourville from '../assets/Pourville.jpg';
import TheLastJudgment from '../assets/TheLastJudgment.jpg';
import en from './en';
import pl from './pl';

const listPl = [
  {
    title: 'Jacques-Louis David',
    description: pl.david,
    image: David,
  },
  {
    title: 'Sir Peter Paul Rubens',
    description: pl.rubens,
    image: PeterPaulRubens,
  },
  {
    title: 'Jan Lievens',
    description: pl.lievens,
    image: JanLievens,
  },
  {
    title: 'Maria Anna Angelika Kauffmann',
    description: pl.kauffmann,
    image: AngelicaKauffmann,
  },
  {
    title: 'Pompeo Batoni',
    description: pl.batoni,
    image: PompeoBatoni,
  },
  {
    title: 'Portret konny Stanisława Kostki Potockiego',
    description: pl.potocki,
    image: Potocki,
  },
  {
    title: 'Sąd Ostateczny',
    description: pl.lastJudgment,
    image: TheLastJudgment,
  },
  {
    title: 'Dama z gronostajem',
    description: pl.ladyErmine,
    image: LadyWithAnErmine,
  },
  {
    title: 'Gra w Szachy',
    description: pl.gameChess,
    image: Chess,
  },
  {
    title: 'Plaża w Pourville',
    description: pl.pourville,
    image: Pourville,
  },
];

const listEn = [
  {
    title: 'Jacques-Louis David',
    description: en.david,
    image: David,
  },
  {
    title: 'Sir Peter Paul Rubens',
    description: en.rubens,
    image: PeterPaulRubens,
  },
  {
    title: 'Jan Lievens',
    description: en.lievens,
    image: JanLievens,
  },
  {
    title: 'Maria Anna Angelika Kauffmann',
    description: en.kauffmann,
    image: AngelicaKauffmann,
  },
  {
    title: 'Pompeo Batoni',
    description: en.batoni,
    image: PompeoBatoni,
  },
  {
    title: 'Portrait of Count Stanislas Potocki',
    description: en.potocki,
    image: Potocki,
  },
  {
    title: 'The Last Judgment',
    description: en.lastJudgment,
    image: TheLastJudgment,
  },
  {
    title: 'Lady with an Ermine',
    description: en.ladyErmine,
    image: LadyWithAnErmine,
  },
  {
    title: 'The Game of Chess',
    description: en.gameChess,
    image: Chess,
  },
  {
    title: 'Beach in Pourville',
    description: en.pourville,
    image: Pourville,
  },
];

const list = {
  en: listEn,
  pl: listPl,
};

export default list;
