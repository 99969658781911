import DragDrop from './DragDrop';
import styles from './DraggableMenu.module.scss';

const colorSize = 48;

class DraggableMenu {
  constructor(state, parent, colors, handleChange) {
    this.state = state;
    this.parent = parent;
    this.colors = colors;
    this.handleChange = handleChange;

    this.targets = [];
    this.elements = [];

    this.createContent();

    this.createDragDrop();
  }

  destructor() {
    // empty
  }

  createContent() {
    const targetColumn = document.createElement('div');
    targetColumn.className = styles.targets;
    this.parent.appendChild(targetColumn);

    const targetContainer = document.createElement('div');
    targetContainer.className = styles.targetList;
    targetColumn.appendChild(targetContainer);

    const elemsContainer = document.createElement('ul');
    this.list = elemsContainer;
    elemsContainer.className = `${styles.elements} drag-drop-elements`;
    this.parent.appendChild(elemsContainer);

    const indices = this.state.buckets;

    const howManyTargets = indices.length;

    for (let index = 0; index < howManyTargets; index++) {
      const target = document.createElement('div');
      target.className = `${styles.dragDropTarget} drag-drop-target`;

      const img = document.createElement('div');

      img.style.backgroundPositionY = `-${[indices[index]] * colorSize}px`;
      target.appendChild(img);

      this.targets.push(target);

      targetContainer.appendChild(target);
    }

    this.repositionTargets();

    this.createItems(elemsContainer);
  }

  /*
   * This is needed for smooth animation. Using non-integer coordinates causes jumping between animation and target.
   */
  repositionTargets() {
    const rect = this.targets[0].getBoundingClientRect();
    const deltaY = rect.top - Math.floor(rect.top);
    const deltaX = rect.left - Math.floor(rect.left);

    if (deltaX !== 0 || deltaY !== 0) {
      this.targets.forEach((target) => {
        // eslint-disable-next-line no-param-reassign
        target.style.top = `${-deltaY}px`;

        // eslint-disable-next-line no-param-reassign
        target.style.left = `${-deltaX}px`;
      });
    }
  }

  createItems(parent) {
    const menuItems = this.colors;

    menuItems.forEach((elem, indexInArray) => {
      const container = document.createElement('div');
      container.className = styles.element;

      const iconContainer = document.createElement('div');
      iconContainer.className = styles.elemIcon;

      const iconBorder = document.createElement('div');
      iconBorder.className = styles.iconBorder;
      iconContainer.appendChild(iconBorder);

      const item = document.createElement('div');

      item.classList.add(styles.itemImage);
      item.setAttribute('data-index', String(indexInArray));
      item.style.backgroundPositionY = `-${indexInArray * colorSize}px`;
      iconContainer.appendChild(item);

      this.elements.push(item);

      container.appendChild(iconContainer);

      const nameContainer = document.createElement('div');
      nameContainer.innerText = elem.name;
      nameContainer.className = styles.name;

      container.appendChild(nameContainer);

      parent.appendChild(container);
    });
  }

  createDragDrop() {
    const indices = this.state.buckets;

    this.dragDrop = new DragDrop(this.list, this.elements, this.targets, indices, this.handleChange);
  }

  update(state) {
    if (this.state.buckets !== state.buckets) {
      this.dragDrop.updateSelected(state.buckets);
    }

    this.state = state;
  }
}

export default DraggableMenu;
