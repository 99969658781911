const pointsOnLine = (from, to, space, drawPoint, minus = 0) => {
  const DELTA = space;

  const dX = to[0] - from[0];
  const dY = to[1] - from[1];
  const d = Math.sqrt(dX * dX + dY * dY);

  const [vx, vy] = [dX / d, dY / d]; // e

  const dots = Math.round(d / DELTA) - minus;

  for (let i = 0; i < dots; i++) {
    const dd = DELTA * i;
    const x = from[0] + vx * dd;
    const y = from[1] + vy * dd;

    drawPoint(x, y, i);
  }
};

const enhancedPointsOnLine = (from, to, space, drawPoint, minus = 0) => {
  pointsOnLine(
    from.map((e) => 2 * e),
    to.map((e) => 2 * e),
    space * 2,
    drawPoint,
    minus
  );
};

export default enhancedPointsOnLine;
