/* eslint-disable @typescript-eslint/default-param-last */
const initialState = {
  paint: {
    F0: 0.13,
    roughness: 0.03,
    specularScale: 0.001,
  },
  fluidity: {
    fluidityIsOn: 1,
    deltaTimeDivider: 60,
    fluidity: 0.008,
    framesToSimulate: 60,
    pressureJacobiIterations: 2,
    splatVelocityScale: 0.14,
  },
  light: {
    diffuseScale: 0.3,
    eyeDirectionX: 0,
    eyeDirectionY: 0,
    eyeDirectionZ: 1,
    lightDirectionX: 0.57,
    lightDirectionY: 0.57,
    lightDirectionZ: 0.76,
    minimalNormalScale: 5.0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
