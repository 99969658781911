import { controllingSelectBucket as controllingSelectBucketPureAction } from '../../store/controlling/actions';
import { paintingSetMedium as paintingSetMediumPureAction } from '../../store/painting/actions';
import store from '../../store/store';

function bind(actionCreator, dispatch) {
  return function (...args) {
    return dispatch(actionCreator(...args));
  };
}

export const setMedium = bind(paintingSetMediumPureAction, store.dispatch);

export const selectBucket = bind(controllingSelectBucketPureAction, store.dispatch);
