import preventDefault from '../../helpers/preventDefault';
import styles from './LoadingCircle.module.scss';

const LoadingCircle = ({ small = false }) => {
  return (
    <div className={small ? styles.loadingSmall : styles.loading} onClick={preventDefault}>
      <div className={styles.loader} />
    </div>
  );
};

export default LoadingCircle;
