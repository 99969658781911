import LoadingCircle from '../LoadingCircle/LoadingCircle';
import styles from './LoadingBox.module.scss';
import { useEffect, useState } from 'react';

const Box = ({ data, name }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setLoaded(true);
    };

    img.src = data.image;
  }, [data.image]);

  return (
    <div className={styles[name]}>
      <div className={styles.image}>
        {loaded && <img src={data.image} />}
        {!loaded && <LoadingCircle small={true} />}
      </div>

      <div className={styles.header}>{data.title}</div>

      <div className={styles.description}>{data.description}</div>
    </div>
  );
};

export default Box;
