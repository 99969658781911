import styles from './CannotLoadData.module.scss';
import { useTranslation } from 'react-i18next';

const CannotLoadData = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        {t("We couldn't load required data.")}
        <br />
        {t('Check your Internet connection and reload app.')}
      </div>
    </div>
  );
};

export default CannotLoadData;
