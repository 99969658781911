const settings = {
  inertia: 0.2,
  colorSize: 60,
  colorZIndexActive: 200,
  colorZIndexDefault: 6,
  wrapperHeight: 126,
  mediumLeftPadding: 50,
};

export default settings;
