import { getPresets } from '../../../api';
import { errorCannotLoadData } from '../../../store/error/actions';
import { loadingNextStage } from '../../../store/loading/actions';
import { presetsSet } from '../../../store/presets/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useLoadPresets = (canLoadData) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getPresetsAsync = async () => {
      try {
        const response = await getPresets();

        dispatch(presetsSet(response.data));
        dispatch(loadingNextStage());
      } catch {
        dispatch(errorCannotLoadData());
      }
    };

    if (canLoadData) {
      getPresetsAsync();
    }
  }, [dispatch, canLoadData]);
};

export default useLoadPresets;
