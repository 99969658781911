const cutTo39 = (tab) => {
  const tab40 = tab.filter((_, b) => b % 2 === 1);

  return tab40.slice(0, 39);
};

export const cutColors = (colors) => {
  return colors.map((color) => ({
    ...color,
    absorption: {
      glaze: cutTo39(color.absorption.glaze),
      impasto: cutTo39(color.absorption.impasto),
    },
  }));
};

export const cutMedium = (medium) => {
  return {
    ...medium,
    absorption: {
      nominal: cutTo39(medium.absorption.nominal),
    },
  };
};

export const cutBackgrounds = (backgrounds) => {
  return backgrounds.map((background) => ({
    ...background,
    reflectance: cutTo39(background.reflectance),
  }));
};

const cutData = (data) => ({
  colors: cutColors(data.colors),
  medium: cutMedium(data.medium),
  backgrounds: cutBackgrounds(data.backgrounds),
});

export default cutData;
