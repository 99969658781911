import assertRectangle from './helpers/assertRectangle';
import createProgram from './helpers/createProgram';
import createQuadVertexBuffer from './helpers/createQuadVertexBuffer';

const PENCIL_COLOR_INDEX = 7;

const HOW_MANY_REFLECTANCE_TEXTURES = 13;
const VECTOR_SIZE = 3;

class Coloring {
  constructor(gl, shaders, textureManager, data) {
    this.gl = gl;
    this.shaders = shaders;
    this.textureManager = textureManager;
    this.data = data;

    this.dataMedium = this.data.medium.absorption.nominal;
    this.dataPencilImpasto = this.data.colors[PENCIL_COLOR_INDEX].absorption.impasto.map((k) => k * 0.5);
    this.dataPencilGlaze = this.data.colors[PENCIL_COLOR_INDEX].absorption.glaze.map((k) => k * 0.3);

    this.vectorImpasto = [0, 0, 0];
    this.vectorGlaze = [0, 0, 0];
    this.vectorMedium = [0, 0, 0];
    this.vectorPencilImpasto = [0, 0, 0];
    this.vectorPencilGlaze = [0, 0, 0];

    this.createBuffers();
    this.createPrograms();
  }

  createBuffers() {
    this.simulationFramebuffer = this.gl.createFramebuffer();
    this.quadVertexBuffer = createQuadVertexBuffer(this.gl);
  }

  createPrograms() {
    this.updateReflectanceProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.updateReflectanceFrag,
      ['a_position'],
      [
        'uAbsorptionGlaze',
        'uAbsorptionImpasto',
        'uAbsorptionMedium',
        'uAbsorptionPencilImpasto',
        'uAbsorptionPencilGlaze',

        'uBrushTexture',
        'uDestinationTexture',
        'uPencilTexture',
        'uSurfaceTexture',

        'uLimitGlaze',
      ]
    );

    this.calculateColorFromReflectanceProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.calculateColorFromReflectanceFrag,
      ['a_position'],
      [
        'u_input_0',
        'u_input_1',
        'u_input_2',
        'u_input_3',
        'u_input_4',
        'u_input_5',
        'u_input_6',
        'u_input_7',
        'u_input_8',
        'u_input_9',
        'u_input_10',
        'u_input_11',
        'u_input_12',
      ]
    );

    this.calculateColorProgram = createProgram(
      this.gl,
      this.shaders.fullscreenVert,
      this.shaders.calculateColorFrag,
      ['a_position'],
      [
        'u_input_0',
        'u_input_1',
        'u_input_2',
        'u_input_3',
        'u_input_4',
        'u_input_5',
        'u_input_6',
        'u_input_7',
        'u_input_8',
        'u_input_9',
        'u_input_10',
        'u_input_11',
        'u_input_12',

        'uAbsorptionGlaze_0',
        'uAbsorptionGlaze_1',
        'uAbsorptionGlaze_2',
        'uAbsorptionGlaze_3',
        'uAbsorptionGlaze_4',
        'uAbsorptionGlaze_5',
        'uAbsorptionGlaze_6',
        'uAbsorptionGlaze_7',
        'uAbsorptionGlaze_8',
        'uAbsorptionGlaze_9',
        'uAbsorptionGlaze_10',
        'uAbsorptionGlaze_11',
        'uAbsorptionGlaze_12',

        'uAbsorptionImpasto_0',
        'uAbsorptionImpasto_1',
        'uAbsorptionImpasto_2',
        'uAbsorptionImpasto_3',
        'uAbsorptionImpasto_4',
        'uAbsorptionImpasto_5',
        'uAbsorptionImpasto_6',
        'uAbsorptionImpasto_7',
        'uAbsorptionImpasto_8',
        'uAbsorptionImpasto_9',
        'uAbsorptionImpasto_10',
        'uAbsorptionImpasto_11',
        'uAbsorptionImpasto_12',

        'uBrushTexture',
        'uPencilTexture',
        'uSurfaceTexture',

        'uLimitGlaze',
      ]
    );
  }

  update(state) {
    let paintIndex = state.paintIndex;

    this.dataGlaze = this.data.colors[paintIndex].absorption.glaze;
    this.dataImpasto = this.data.colors[paintIndex].absorption.impasto;
    this.modelLimit = this.data.colors[paintIndex].modelLimit;

    this.calculateVectors(state);
  }

  calculateVectors(state) {
    let paintIndex = state.paintIndex;

    const glaze = this.data.colors[paintIndex].absorption.glaze;
    const impasto = this.data.colors[paintIndex].absorption.impasto;

    this.vectorsImpasto = [];
    this.vectorsGlaze = [];

    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; ++i) {
      const vectorImpasto = [];
      const vectorGlaze = [];

      for (let j = 0; j < VECTOR_SIZE; ++j) {
        const k = i * VECTOR_SIZE + j;

        vectorImpasto[j] = impasto[k];
        vectorGlaze[j] = glaze[k];
      }

      this.vectorsImpasto.push(vectorImpasto);
      this.vectorsGlaze.push(vectorGlaze);
    }
  }

  updateReflectances(rectangle) {
    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; ++i) {
      for (let j = 0; j < VECTOR_SIZE; ++j) {
        const k = i * VECTOR_SIZE + j;

        this.vectorImpasto[j] = this.dataImpasto[k];
        this.vectorGlaze[j] = this.dataGlaze[k];
        this.vectorMedium[j] = this.dataMedium[k];
        this.vectorPencilImpasto[j] = this.dataPencilImpasto[k];
        this.vectorPencilGlaze[j] = this.dataPencilGlaze[k];
      }

      this.updateReflectance(rectangle, i);

      this.textureManager.swap(`reflectance${i}Texture`, 'reflectanceTempTexture');
    }
  }

  updateReflectance(rectangle, i) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    // this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);
    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    assertRectangle(rectangle);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

    this.gl.enable(this.gl.DEPTH_TEST);
    this.gl.enable(this.gl.BLEND);
    this.gl.blendEquation(this.gl.FUNC_ADD);
    this.gl.blendFunc(this.gl.ONE, this.gl.ZERO);

    // use program
    this.gl.useProgram(this.updateReflectanceProgram);

    this.gl.uniform1f(this.updateReflectanceProgram.uLimitGlaze, this.modelLimit);

    this.gl.uniform3fv(this.updateReflectanceProgram.uAbsorptionImpasto, this.vectorImpasto);
    this.gl.uniform3fv(this.updateReflectanceProgram.uAbsorptionGlaze, this.vectorGlaze);
    this.gl.uniform3fv(this.updateReflectanceProgram.uAbsorptionMedium, this.vectorMedium);
    this.gl.uniform3fv(this.updateReflectanceProgram.uAbsorptionPencilImpasto, this.vectorPencilImpasto);
    this.gl.uniform3fv(this.updateReflectanceProgram.uAbsorptionPencilGlaze, this.vectorPencilGlaze);

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[`reflectance${i}Texture`]);
    this.gl.uniform1i(this.updateReflectanceProgram.uDestinationTexture, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatBrushPaintTexture);
    this.gl.uniform1i(this.updateReflectanceProgram.uBrushTexture, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatPencilPaintTexture);
    this.gl.uniform1i(this.updateReflectanceProgram.uPencilTexture, 2);

    this.gl.activeTexture(this.gl.TEXTURE3);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.surfaceTexture);
    this.gl.uniform1i(this.updateReflectanceProgram.uSurfaceTexture, 3);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.updateReflectanceProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.updateReflectanceProgram.a_position);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.reflectanceTempTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  calculateColorFromReflectance(rectangle, buildFromTemp = false) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);

    this.gl.useProgram(this.calculateColorFromReflectanceProgram);

    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    assertRectangle(rectangle);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

    const textureType = buildFromTemp ? 'TempTexture' : 'Texture';

    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; ++i) {
      const name = `reflectance${i}${textureType}`;

      this.gl.activeTexture(this.gl[`TEXTURE${i}`]);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[name]);
      this.gl.uniform1i(this.calculateColorFromReflectanceProgram[`u_input_${i}`], i);
    }

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.calculateColorFromReflectanceProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.calculateColorFromReflectanceProgram.a_position);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.colorTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }

  calculateColor(rectangle) {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.simulationFramebuffer);

    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.BLEND);

    this.gl.useProgram(this.calculateColorProgram);

    this.gl.viewport(0, 0, this.textureManager.width, this.textureManager.height);

    assertRectangle(rectangle);

    this.gl.enable(this.gl.SCISSOR_TEST);
    this.gl.scissor(rectangle.left, rectangle.bottom, rectangle.width, rectangle.height);

    this.gl.uniform1f(this.calculateColorProgram.uLimitGlaze, this.modelLimit);

    for (let i = 0; i < HOW_MANY_REFLECTANCE_TEXTURES; ++i) {
      const name = `reflectance${i}Texture`;

      this.gl.activeTexture(this.gl[`TEXTURE${i}`]);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager[name]);
      this.gl.uniform1i(this.calculateColorProgram[`u_input_${i}`], i);

      this.gl.uniform3fv(this.calculateColorProgram[`uAbsorptionImpasto_${i}`], this.vectorsImpasto[i]);
      this.gl.uniform3fv(this.calculateColorProgram[`uAbsorptionGlaze_${i}`], this.vectorsGlaze[i]);
    }

    this.gl.activeTexture(this.gl.TEXTURE13);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatBrushPaintTexture);
    this.gl.uniform1i(this.calculateColorProgram.uBrushTexture, 13);

    this.gl.activeTexture(this.gl.TEXTURE14);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.splatPencilPaintTexture);
    this.gl.uniform1i(this.calculateColorProgram.uPencilTexture, 14);

    this.gl.activeTexture(this.gl.TEXTURE15);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureManager.surfaceTexture);
    this.gl.uniform1i(this.calculateColorProgram.uSurfaceTexture, 15);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.quadVertexBuffer);
    this.gl.vertexAttribPointer(this.calculateColorProgram.a_position, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(this.calculateColorProgram.a_position);

    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      this.textureManager.colorTexture,
      0
    );

    this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
  }
}

export default Coloring;
