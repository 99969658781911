import { paintingSetMedium } from '../painting/actions';
import { controllingSetDefaultPresets, controllingSetPresetType } from './actions';

const AVAILABLE_TYPES_OF_PRESETS = ['hog', 'sobol', 'rag', 'chalk'];

export const controllingDoDefaultTools = (action, dispatch, getState) => {
  const { presets } = getState();

  const defaultPresets = {};

  const keys = Object.keys(presets);

  keys.forEach((key) => {
    const type = key.substr(0, key.indexOf('-'));

    if (AVAILABLE_TYPES_OF_PRESETS.includes(type)) {
      defaultPresets[type] = `${type}-1.json`;
    }
  });

  dispatch(controllingSetDefaultPresets(defaultPresets));
};

export const controllingDoDefaultPreset = (action, dispatch) => {
  dispatch(controllingSetPresetType('chalk'));
};

export const controllingChangeMediumWhenBucketSelected = (action, dispatch) => {
  dispatch(paintingSetMedium(0));
};

export const controllingChangeMediumWhenBucketsChanged = (action, dispatch, getState) => {
  const { controlling } = getState();

  if (action.payload.position === controlling.selectedBucket) {
    dispatch(paintingSetMedium(0));
  }
};
