import { controllingSetOpenMenu } from '../../store/controlling/actions';
import styles from './Drawer.module.scss';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

const Drawer = ({ children }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={styles.container}
        onTouchStart={() => {
          dispatch(controllingSetOpenMenu(undefined));
        }}
        onClick={() => {
          dispatch(controllingSetOpenMenu(undefined));
        }}
      />

      <div className={cn(styles.container, styles.noClick)}>
        <div className={styles.drawer}>{children}</div>
      </div>
    </>
  );
};

export default Drawer;
