const texts = {
  david:
    'Urodzony 30 sierpnia 1748 w Paryżu, zmarł 29 grudnia 1825 w Brukseli – francuski malarz, główny reprezentant klasycyzmu, nadworny malarz Napoleona Bonapartego. Uważany za filar sztuki oświecenia, oficjalny artysta rewolucji francuskiej, stworzył czytelny i nośny symbol rewolucyjny – Przysięgę Horacjuszy.',
  kauffmann:
    'Znana jako Angelica Kauffman (30 października 1741 – 5 listopada 1807) była szwajcarską malarką neoklasyczną, która odnosiła sukcesy w Londynie i Rzymie. Pamiętana przede wszystkim jako malarka historii, Kauffmann była zręczną portrecistką, malarką pejzaży i dekoracji. Była, wraz z Mary Moser, jedną z dwóch malarek wśród członków założycieli Royal Academy w Londynie w 1768 roku.',
  lievens:
    'Urodzony 24 października 1607 – zmarł 4 czerwca 1674. Był holenderskim malarzem Złotego Wieku, blisko związany ze starszym o rok Rembrandtem. Dzielili miejsce urodzenia w Lejdzie, trenowali z Pieterem Lastmanem w Amsterdamie, gdzie dzielili pracownię przez około pięć lat, aż do 1631 roku. Podobnie jak Rembrandt malował zarówno portrety, jak i obrazy historyczne, ale w przeciwieństwie do niego kariera Lievensa zabrała go z Amsterdamu do Londynu, Antwerpii, Hagi i Berlina.',
  rubens:
    'Urodzony 28 czerwca 1577 – zmarł 30 maja 1640. Był flamandzkim artystą i dyplomatą z Księstwa Brabancji w południowej Holandii (dzisiejsza Belgia). Uważany jest za najbardziej wpływowego artystę flamandzkiego baroku. Wysoce naładowane kompozycje Rubensa odwołują się do erudycyjnych aspektów historii klasycznej i chrześcijańskiej. Jego unikalny i niezwykle popularny styl barokowy kładł nacisk na ruch, kolor i zmysłowość, podążając za natychmiastowym, dramatycznym stylem artystycznym promowanym w czasie kontrreformacji.',
  batoni:
    'Malarz włoski (25.01.1708 – 4.02.1787), który wykazał się solidną wiedzą techniczną w swojej twórczości portretowej oraz w licznych obrazach alegorycznych i mitologicznych. Duża liczba zagranicznych gości podróżujących po Włoszech i docierających do Rzymu podczas „Grand Tour” sprawiła, że artysta wyspecjalizował się w portretach. Batoni zdobył międzynarodową sławę w dużej mierze dzięki swoim klientom, głównie Brytyjczykom szlacheckiego pochodzenia, których portretował, często ze słynnymi włoskimi pejzażami w tle. Takie portrety Batoniego z Grand Tour znalazły się w brytyjskich prywatnych kolekcjach, zapewniając tym samym popularność gatunku w Wielkiej Brytanii. ',
  potocki:
    'Portret hrabiego Stanisława Potockiego to portret konny patrona Polski, polityka i pisarza Stanisława Kostki Potockiego z 1781 roku, autorstwa francuskiego malarza Jacquesa-Louisa Davida. Został namalowany w Rzymie, gdy spotkali się podczas pobytu Dawida w Willi Medici po zdobyciu pierwszej nagrody za malarstwo w Prix de Rome, a chronologicznie po jego wstawiennictwie u św. Rocha. Swój format jeździecki zawdzięcza wpływom Rubensa.',
  lastJudgment:
    'Sąd Ostateczny to tryptyk przypisywany flamandzkiemu malarzowi Hansowi Memlingowi, namalowany w latach 1467-1471. Obecnie znajduje się w Muzeum Narodowym w Gdańsku. Został zamówiony przez Angelo Tani, agenta Medyceuszy w Brugii, ale został schwytany na morzu przez Paula Beneke, korsarza z Gdańska. Długi proces sądowy przeciwko Lidze Hanzeatyckiej zażądał jej powrotu do Włoch. Został umieszczony w Bazylice Wniebowzięcia NMP, ale w XX wieku został przeniesiony na obecne miejsce.',
  ladyErmine:
    "Dama z gronostajem to obraz portretowy powszechnie przypisywany włoskiemu artyście renesansu Leonardo da Vinci. Datowany na ok. 1489–1491, praca malowana olejami na desce z drewna orzechowego. Jej bohaterką jest Cecilia Gallerani, kochanka Ludovica Sforzy („Il Moro”), księcia Mediolanu; Leonardo był malarzem na dworze Sforza w Mediolanie w czasie jego realizacji. Jest to drugi z zaledwie czterech zachowanych portretów kobiet namalowanych przez Leonarda, obok Ginevra de'Benci, La Belle Ferronnière i Mona Lisa.",
  gameChess:
    'Sofonisba Anguissola to wybitna włoska malarka renesansowa, jedna z pierwszych, nielicznych artystek nowożytnych, która zdobyła dużą popularność i sukces już za życia. Jej „Grą w szachy” namalowaną w 1555 roku zachwycał się sam Giorgio Vasari, będąc pod wielkim wrażeniem jej talentu i kunsztu artystycznego. Obraz przedstawia cztery kobiety: trzy siostry artystki stojące przy szachownicy oraz ich starszą opiekunkę, wyłaniającą się z prawej strony płótna. Obraz w XIX wieku zakupił w Berlinie do swojej kolekcji Atanazy Raczyński. Obecnie płótno znajduje się w zbiorach rodzinnej Fundacji Raczyńskich i prezentowane jest w gmachu głównym Muzeum Narodowego w Poznaniu.',
  pourville:
    "Plaża w Pourville (tytuł w języku francuskim: La plage à Pourville, soleil couchant) to obraz francuskiego artysty Claude'a Moneta. Jest to jedna z serii obrazów olejnych na płótnie z 1882 roku autorstwa Moneta w małym nadmorskim kurorcie Pourville-sur-Mer (obecnie część gminy Hautot-sur-Mer), niedaleko Dieppe w północnej Francji. Monet jest uważany za jednego z najważniejszych członków grupy malarzy określanych jako impresjoniści. Obraz został zakupiony przez Muzeum Narodowe w Poznaniu w 1906 roku i wystawiony w ich zbiorach.",
};

export default texts;
