function isMobileDevice() {
  try {
    document.createEvent('TouchEvent');

    return true;
  } catch (e) {
    return false;
  }
}

export default isMobileDevice;
